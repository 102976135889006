import { isGlobalAdmin } from "@/helpers/permissions/users";
import Dashboard from "@/views/Dashboard.vue";
import Faq from "@/views/Faq.vue";
import Help from "@/views/Help.vue";
import Insurance from "@/views/Insurance.vue";
import Privacy from "@/views/Privacy.vue";

import store from "@/store";

export default [
  {
    path: "/",
    beforeEnter(to, from, next) {
      if (!store.state.user) {
        next("/login");
        return;
      }

      if (isGlobalAdmin(store.state.user)) {
        next("/admin/dashboard");
        return;
      }

      next("/app");
    },
  },
  {
    path: "/privacy",
    name: "privacy",
    component: Privacy,
    meta: {
      title: "titles.privacy",
    },
  },
  {
    path: "/conditions",
    name: "conditions",
    component: Privacy,
    meta: {
      title: "titles.privacy",
    },
  },
  {
    path: "/assurances-desjardins",
    name: "insurance",
    component: Insurance,
    meta: {
      title: "titles.insurance",
    },
  },
  {
    path: "/faq",
    name: "faq",
    component: Faq,
    meta: {
      title: "titles.faq",
    },
  },
  {
    path: "/app",
    name: "dashboard",
    component: Dashboard,
    meta: {
      auth: true,
      title: "titles.dashboard",
    },
  },
  {
    path: "/help",
    name: "help",
    component: Help,
    meta: {
      title: "Aide de LocoMotion",
    },
  },
];
