<script>
import locales from "@/locales";

export default {
  name: "AvailabilityStatus",
  props: {
    loanable: {
      type: Object,
      required: true,
    },
  },

  i18n: {
    messages: {
      en: {
        loanables: locales.en.loanables,
      },
      fr: {
        loanables: locales.fr.loanables,
      },
    },
  },
};
</script>

<template>
  <b-badge
    variant="warning"
    v-if="loanable.availability_status === 'no_approved_communities'"
    v-b-tooltip="`Le-a propriétaire n\'est approuvé-e dans aucune communauté.`"
  >
    Non partagé&nbsp;: aucune communauté approuvée
  </b-badge>
  <b-badge
    variant="danger"
    v-else-if="loanable.availability_status === 'wrong_type'"
    v-b-tooltip="
      `Le-a propriétaire n\'est pas approuvé-e dans une communauté permettant le partage de type
              \'${$t(
                `loanables.fields.types.${loanable.type}`
              )}\'. Ce véhicule ne sera donc pas affiché.`
    "
  >
    Type non partagé&nbsp;: {{ $t(`loanables.fields.types.${loanable.type}`) }}
  </b-badge>
  <b-badge
    variant="warning"
    v-else-if="loanable.availability_status === 'no_availabilities'"
    v-b-tooltip="`Ce véhicule n'a pas de disponibilités et ne sera donc pas affiché.`"
  >
    Aucune disponibilité
  </b-badge>
  <b-badge variant="secondary" v-else-if="loanable.availability_status === 'unpublished'">
    Non publié
  </b-badge>
  <b-badge variant="success" v-else-if="loanable.availability_status === 'has_availabilities'">
    Affiché
  </b-badge>
</template>
