<template>
  <div class="mb-1">
    <div v-if="exportFailed" class="text-danger">
      Erreur lors de l'export.
      <a target="_blank" href="https://framateam.org/locomotionapp/channels/bogues-et-demandes"
        >Avisez l'équipe technique si elle se reproduit.</a
      >
    </div>
    <div v-else-if="slowExport">
      Cet export demande un peu de temps, vous recevrez un courriel lorsqu'il sera complété.
      Entretemps vous pouvez voir son état
      <b-link :to="`/admin/exports?id=${exportData.id}`">ici</b-link>.
    </div>
    <div v-else-if="exportData && !exportCanceled">
      <div
        class="d-flex flex-fill align-items-center"
        v-if="exportData.status === 'in_process' && exportData.progress < 100"
      >
        Export en cours:
        <div class="flex-fill ml-2">
          <b-progress :value="exportData.progress" animated />
        </div>
      </div>
      <safe-file v-else :file="exportData.file" download />
    </div>
    <template v-else>
      <div v-if="exportCanceled" class="text-danger mb-1">L'export précédent a été annulé.</div>
      <icon-button
        class="generate-csv-button"
        variant="ghost-secondary"
        size="sm"
        v-b-modal="modalId"
        :loading="exportLoading"
      >
        Exporter {{ label }} (CSV)
      </icon-button>
      <b-modal :id="modalId" hide-footer hide-header centered>
        <b-input-group class="mb-2" append=".csv">
          <b-input
            v-model="exportName"
            placeholder="Nommer l'export, ou garder vide pour un nom par défaut."
          ></b-input>
        </b-input-group>

        <icon-button @click="exportCsv" variant="outline-success">
          Exporter {{ label }}
        </icon-button>
      </b-modal>
    </template>
  </div>
</template>

<script>
import IconButton from "@/components/shared/IconButton.vue";
import { get } from "@/requests/server";
import SafeFile from "@/components/shared/SafeFile.vue";

const EXPORT_POLL_DELAY_MS = 1000; // 1 second
const MAX_EXPORT_POLL_ATTEMPTS = 60; // 1 minute

let modalId = 0;

const getDefaultState = () => ({
  exportData: null,
  exportLoading: false,
  exportFailed: false,
  slowExport: false,
  exportName: "",
  modalIdNumber: modalId++,
});

export default {
  name: "ExportButton",
  components: { IconButton, SafeFile },
  data: getDefaultState,
  props: {
    params: {
      type: Object,
      default: () => ({}),
    },
    store: {
      type: String,
      required: false,
    },
    label: {
      type: String,
    },
    endpoint: {
      type: String,
    },
  },
  computed: {
    state() {
      return this.$store.state[this.store];
    },
    slug() {
      return this.state?.slug;
    },
    exportEndoint() {
      return this.endpoint || this.slug;
    },
    fields() {
      return this.state?.exportFields.join(",");
    },
    notFields() {
      return this.state?.exportNotFields.join(",");
    },
    modalId() {
      return `export-modal-${this.modalIdNumber}`;
    },
    exportCanceled() {
      return this.exportData?.status === "canceled";
    },
  },
  methods: {
    reset() {
      Object.assign(this.$data, getDefaultState());
    },
    handleFailedExport() {
      this.exportFailed = true;
      this.exportLoading = false;
    },
    handleExportDataResponse(exportData, attempt = 0) {
      this.exportData = exportData;
      if (exportData.status !== "in_process") {
        this.exportLoading = false;
      }

      if (exportData.status === "failed") {
        this.handleFailedExport();
        return;
      }

      if (exportData.status === "in_process" && exportData.id) {
        if (attempt >= MAX_EXPORT_POLL_ATTEMPTS) {
          this.slowExport = true;
          return;
        }

        setTimeout(async () => {
          try {
            const { data } = await get(`/exports/${exportData.id}`, {
              requestOptions: { cancelId: `export-progress-${exportData.id}` },
            });
            this.handleExportDataResponse(data, attempt + 1);
          } catch (e) {
            this.handleFailedExport(e);
          }
        }, EXPORT_POLL_DELAY_MS);
      }
    },
    async exportCsv() {
      this.$root.$emit("bv::hide::modal", this.modalId);
      this.exportLoading = true;

      try {
        const { data } = await get(this.exportEndoint, {
          axiosRequestConfig: {
            params: {
              ...this.params,
              per_page: "*",
              page: 1,
              fields: this.fields,
              "!fields": this.notFields,
              export_filename: this.exportName,
            },
            headers: {
              Accept: "text/csv",
            },
          },
          notifications: { action: "export" },
        });

        this.handleExportDataResponse(data);
      } catch (e) {
        this.handleFailedExport();
      }
    },
  },
  watch: {
    params() {
      this.reset();
    },
  },
};
</script>
