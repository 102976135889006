<template>
  <div>
    <paginated-table
      id="admin-loans-table"
      :columns="columns"
      :extra-filters="extraFilters"
      :extra-data="[
        'incidents.status',
        'extensions.status',
        'community.deleted_at',
        'loanable.timezone',
      ]"
      endpoint="loans"
      :fetch-params="{ for: 'admin' }"
      :label="$tc('model_name', 2)"
      sync-filters-with-url
      :show-action-column="false"
      row-link
    >
      <template #cell(status)="{ item }">
        <loan-status :item="item"></loan-status>
      </template>
    </paginated-table>
  </div>
</template>

<script>
import LoanStatus from "@/components/Loan/Status.vue";
import { capitalize } from "@/helpers/filters";
import locales from "@/locales";
import PaginatedTable from "@/components/shared/PaginatedTable.vue";
import {
  loanBorrowerLink,
  loanLoanableLink,
  loanOwnerLink,
  loanCommunityLink,
} from "@/helpers/adminLinks";
import { canSeeDeletedUser } from "@/helpers/permissions/users";
import { Column, Filter } from "@/components/shared/PaginatedTableColumns";
import dayjs from "dayjs";

export default {
  name: "AdminLoans",
  components: {
    PaginatedTable,
    LoanStatus,
  },
  data() {
    const user = this.$store.state.user;
    return {
      columns: [
        new Column("id", "ID", "id"),
        new Column("departure_at", "Départ", "date", {
          formatter: (value, key, item) => {
            return dayjs.atTz(value, item.loanable.timezone).format("D MMMM YYYY HH:mm");
          },
        }),
        new Column("actual_return_at", "Retour", "date", {
          showByDefault: false,
          formatter: (value, key, item) =>
            dayjs.atTz(value, item.loanable.timezone).format("D MMMM YYYY HH:mm"),
        }),
        Column.withRelation(
          "borrower.user.full_name",
          "Emprunteur",
          {
            relation: "users",
            label: "full_name",
            field: "id",
            params: { for: "admin", with_deleted: canSeeDeletedUser(user) },
          },
          "borrower.user.id",
          {
            urlFct: loanBorrowerLink,
          }
        ),
        Column.withRelation(
          "loanable.owner.user.full_name",
          "Propriétaire",
          {
            relation: "users",
            label: "full_name",
            field: "id",
            params: { for: "admin", with_deleted: canSeeDeletedUser(user) },
          },
          "loanable.owner.user.id",
          {
            urlFct: loanOwnerLink,
          }
        ),
        Column.withRelation(
          "community.name",
          "Communauté",
          { relation: "communities", label: "name", field: "id", params: { for: "admin" } },
          "community_id",
          {
            urlFct: loanCommunityLink,
          }
        ),
        Column.withRelation(
          "loanable.name",
          "Véhicule",
          {
            relation: "loanables",
            label: "name",
            field: "id",
            params: { for: "admin", with_deleted: true },
          },
          "loanable.id",
          {
            urlFct: loanLoanableLink,
          }
        ),
        Column.withSelect("status", "Statut", [
          { value: null, label: "Tous" },
          { value: "in_process", label: "En cours" },
          { value: "completed", label: "Complété" },
          { value: "canceled", label: "Annulé" },
        ]),
        new Column("actual_duration_in_minutes", "Durée (minutes)", "number", {
          showByDefault: false,
        }),
        new Column("calendar_days", "Nombre de jours", "number", { showByDefault: false }),
        Column.withSelect(
          "loanable.type",
          "Type de véhicule",
          [
            { value: null, label: "Tous" },
            { value: "car", label: "auto" },
            { value: "trailer", label: "Remorque" },
            { value: "bike", label: "vélo" },
          ],
          { showByDefault: false }
        ),

        Column.withSelect(
          "is_self_service",
          capitalize(this.$t("self_service.is_self_service")),
          [
            { value: null, label: capitalize(this.$t("self_service.all")) },
            { value: true, label: capitalize(this.$t("self_service.is_self_service")) },
            { value: false, label: capitalize(this.$t("self_service.on_demand")) },
          ],
          { showByDefault: false }
        ),
        Column.withSelect(
          "intention.status",
          "Demande d'emprunt",
          [
            { value: null, label: "Tous" },
            { value: "in_process", label: "En cours", variant: "primary" },
            { value: "completed", label: "Acceptée", variant: "success" },
            { value: "canceled", label: "Refusée", variant: "danger" },
          ],
          { showByDefault: false }
        ),
        Column.withSelect(
          "pre_payment.status",
          "Prépaiement",
          [
            { value: null, label: "Tous" },
            { value: "in_process", label: "En cours", variant: "primary" },
            { value: "completed", label: "Complété", variant: "success" },
            { value: "canceled", label: "Annulé", variant: "danger" },
          ],
          { showByDefault: false }
        ),
        Column.withSelect(
          "takeover.status",
          "Prise de possession",
          [
            { value: null, label: "Tous" },
            { value: "in_process", label: "En cours", variant: "primary" },
            { value: "completed", label: "Complétée", variant: "success" },
            { value: "canceled", label: "Contestée", variant: "danger" },
          ],
          { showByDefault: false }
        ),
        Column.withSelect(
          "handover.status",
          "Retour du véhicule",
          [
            { value: null, label: "Tous" },
            { value: "in_process", label: "En cours", variant: "primary" },
            { value: "completed", label: "Complété", variant: "success" },
            { value: "canceled", label: "Contesté", variant: "danger" },
          ],
          { showByDefault: false }
        ),
        Column.withSelect(
          "payment.status",
          "Paiment",
          [
            { value: null, label: "Tous" },
            { value: "in_process", label: "En cours", variant: "primary" },
            { value: "completed", label: "Complété", variant: "success" },
            { value: "canceled", label: "Annulé", variant: "danger" },
          ],
          { showByDefault: false }
        ),
        Column.withSelect(
          "alternative_to",
          "Mode de transport remplacé",
          [
            { value: null, label: "Tous" },
            { value: "delivery", label: "Livraison", variant: "success" },
            { value: "car", label: "Auto", variant: "success" },
            { value: "public_transit", label: "Transport en commun", variant: "success" },
            { value: "bike", label: "Vélo", variant: "primary" },
            { value: "walking", label: "Marche", variant: "warning" },
            { value: "other", label: "Autre", variant: "secondary" },
          ],
          { showByDefault: false }
        ),
      ],
      extraFilters: [
        new Filter("incidents.status", "Incidents", "select", [
          { value: null, label: "Tous" },
          { value: "in_process", label: "En cours" },
          { value: "completed", label: "Résolu" },
          { value: "canceled", label: "Annulé" },
        ]),
        new Filter("extensions.status", "Retards", "select", [
          { value: null, label: "Tous" },
          { value: "in_process", label: "En cours" },
          { value: "completed", label: "Accepté" },
          { value: "canceled", label: "Annulé" },
        ]),
      ],
    };
  },
  i18n: {
    messages: {
      en: {
        ...locales.en.loans,
        ...locales.en.forms,
      },
      fr: {
        ...locales.fr.loans,
        ...locales.fr.forms,
      },
    },
  },
};
</script>

<style lang="scss"></style>
