<script>
import { loader } from "@/helpers/googleMaps";
import Vue from "vue";

export default Vue.extend({
  name: "PlaceAutocompleteInput",
  props: {
    value: {
      type: String,
    },
    options: {
      type: Object,
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  data: function () {
    return {
      searchBox: null,
    };
  },
  async mounted() {
    const { Autocomplete } = await loader.importLibrary("places");
    this.searchBox = new Autocomplete(this.$refs.input, this.options);
    this.searchBox.addListener("place_changed", () => {
      const place = this.searchBox.getPlace();
      if (!place || !place.formatted_address) {
        this.$emit("place-selected", null);
        return;
      }
      this.$emit("change", place.formatted_address);
      this.$emit("input", place.formatted_address);
      this.$emit("place-selected", place);
    });
  },
});
</script>

<template>
  <input
    type="text"
    ref="input"
    :value="value"
    :placeholder="placeholder"
    @input="$emit('input', $event.target.value)"
    @blur="$emit('blur')"
  />
</template>

<style scoped lang="scss"></style>
