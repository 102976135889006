<template>
  <div class="status-container">
    <b-badge
      v-b-tooltip.hover
      :title="$t(`statuses.${loanStatus.status}.description`)"
      class="loan-status-pill"
      :variant="loanStatus.variant"
    >
      {{ $t(`statuses.${loanStatus.status}.text`) }}
    </b-badge>
    <div v-if="item.is_self_service && showSelfService">
      <b-badge variant="primary">{{ $t("statuses.self_service") }}</b-badge>
    </div>
  </div>
</template>

<script>
import LoanStepsSequence from "@/mixins/LoanStepsSequence";
import UserMixin from "@/mixins/UserMixin";
import locales from "@/locales";
import { getZonedTimes } from "@/helpers/loanTimezones";

export default {
  name: "LoanStatus",
  mixins: [LoanStepsSequence, UserMixin],
  props: {
    // Item is a loan.
    item: {
      type: Object,
      required: true,
    },
    showSelfService: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    zonedTimes() {
      return getZonedTimes(this.item);
    },
    loanStatus() {
      if (!this.item.id || !this.item.intention) {
        return {
          status: "new",
          variant: "success",
        };
      }
      if (this.loanIsCanceled) {
        return {
          status: "canceled",
          variant: "danger",
        };
      }
      if (this.hasActiveIncidents) {
        return {
          status: "active_incident",
          variant: "danger",
        };
      }
      if (this.loanIsContested) {
        return {
          status: "contested",
          variant: "danger",
        };
      }
      if (this.loanIsCompleted) {
        return {
          status: "completed",
          variant: "success",
        };
      }
      if (this.item.intention.status === "in_process") {
        return {
          status: "waiting_for_approval",
          variant: "warning",
        };
      }
      if (this.item.pre_payment.status === "in_process") {
        if (this.item.is_free) {
          return {
            status: "waiting_for_contribution",
            variant: "warning",
          };
        }
        return {
          status: "waiting_for_prepayment",
          variant: "warning",
        };
      }
      if (this.item.takeover.status === "in_process") {
        if (this.zonedTimes.returnAt.isBefore(this.$second, "minute")) {
          return {
            status: "expired_reservation",
            variant: "warning",
          };
        }

        if (this.item.is_self_service) {
          if (this.zonedTimes.departureAt.isBefore(this.$second, "minute")) {
            return {
              status: "waiting_for_takeover_self_service",
              variant: "warning",
            };
          }
          return {
            status: "confirmed_reservation_self_service",
            variant: "success",
          };
        }

        if (this.zonedTimes.departureAt.isBefore(this.$second, "minute")) {
          return {
            status: "waiting_for_takeover",
            variant: "warning",
          };
        }
        return {
          status: "confirmed_reservation",
          variant: "success",
        };
      }
      if (
        this.item.extensions &&
        this.item.extensions.reduce((acc, e) => acc || e.status === "in_process", false)
      ) {
        return {
          status: "waiting_for_extension",
          variant: "warning",
        };
      }
      if (this.item.handover.status === "in_process") {
        if (this.zonedTimes.returnAt.isBefore(this.$second, "minute")) {
          return {
            status: "waiting_for_handover",
            variant: "warning",
          };
        }

        if (this.item.is_self_service) {
          if (this.zonedTimes.departureAt.isAfter(this.$second, "minute")) {
            return {
              status: "confirmed_reservation_self_service",
              variant: "success",
            };
          }
          return {
            status: "ongoing_reservation_self_service",
            variant: "success",
          };
        }

        if (this.zonedTimes.departureAt.isAfter(this.$second, "minute")) {
          return {
            variant: "success",
            status: "confirmed_reservation",
          };
        }
        return {
          status: "ongoing_reservation",
          variant: "success",
        };
      }
      if (this.item.payment.status === "in_process") {
        if (this.item.is_self_service) {
          return {
            status: "waiting_for_payment_self_service",
            variant: "success",
          };
        }
        if (
          this.item.needs_validation &&
          (!this.item.borrower_validated_at || !this.item.owner_validated_at)
        ) {
          return {
            status: "waiting_for_validation",
            variant: "warning",
          };
        }

        return {
          status: "waiting_for_payment",
          variant: "warning",
        };
      }
      return {};
    },
  },
  i18n: {
    messages: {
      en: {
        ...locales.en.loans,
      },
      fr: {
        ...locales.fr.loans,
      },
    },
  },
};
</script>

<style lang="scss">
.status-container {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}
</style>
