<script>
import IconButton from "@/components/shared/IconButton.vue";
import Vue from "vue";
import LayoutLoading from "@/components/Layout/Loading.vue";
import InvitationValidation from "@/components/Invitation/InvitationHeader.vue";
import { validateInvitation } from "@/requests/invitationRequests";
import { userIsRegistered } from "@/helpers/permissions/users";

// This page shows any errors with the invitation passed in the url query params. If no errors
// are found, the user is redirected depending on what next action is required to accept the invite
// e.g. login, register or immediately accepting the invite.
export default Vue.extend({
  name: "AcceptInvitation",
  components: { IconButton, InvitationValidation, LayoutLoading },
  data() {
    const { code, id } = this.$route.query;

    if (code && id) {
      this.$store.commit("invitation/setInvitation", { code, id });
    }

    return {
      accepting: false,
      validating: false,
      validationResult: {},
      invitation: this.$store.state.invitation,
    };
  },
  async mounted() {
    await this.validateInvitation();

    this.$store.commit("invitation/setInvitation", {
      code: this.invitation.code,
      id: this.invitation.id,
      inviterName: this.validationResult.inviterName,
      communityName: this.validationResult.communityName,
      accountExists: this.validationResult.accountExists,
    });

    if (!this.validationResult.valid) {
      return;
    }

    if (this.isLoggedIn) {
      if (!this.userIsRegistered) {
        // Complete registration before accepting invitation
        this.$router.replace("/register/2");
        return;
      }

      // Auto accept the invitation if user is valid
      await this.accept();
      return;
    }

    if (this.validationResult.accountExists) {
      this.$router.replace({ path: "/login" });
      return;
    }
    this.$router.replace("/register/1");
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    email() {
      return this.user ? this.user.email : null;
    },
    isLoggedIn() {
      return !!this.user;
    },
    userIsRegistered() {
      return this.isLoggedIn && userIsRegistered(this.user);
    },
  },
  methods: {
    async validateInvitation() {
      if (this.validating) {
        return;
      }

      this.validating = true;

      this.validationResult = await validateInvitation(
        this.invitation.id,
        this.invitation.code,
        this.email
      );

      this.validating = false;
    },
    async logout() {
      await this.$store.dispatch("logout", { keepInvitation: true });
      if (this.validationResult.accountExists) {
        // Clear email even if remember me is set, to avoid confusion
        this.$store.commit("login/email", "");
        this.$router.replace({ path: "/login", query: { logoout: 1 } });
        return;
      }
      this.$router.replace("/register/1");
    },
    async accept() {
      if (this.accepting) {
        return;
      }

      this.accepting = true;
      try {
        await this.$store.dispatch("invitation/accept");
        await this.$router.push("/app");
      } catch {
        this.accepting = false;
      }
    },
  },
});
</script>

<template>
  <layout-page name="invitation" bg-image centered box wide>
    <layout-loading v-if="validating || accepting" />
    <div class="mb-0" v-else-if="validationResult.valid === false">
      <h4>Erreur avec l'invitation</h4>
      <p>
        {{ validationResult.message }}
      </p>

      <template v-if="isLoggedIn">
        <icon-button
          v-if="
            !validationResult.notFound &&
            validationResult.isActive &&
            validationResult.codeValid &&
            !validationResult.emailValid
          "
          @click="logout"
          variant="primary"
        >
          Se déconnecter pour accepter l'invitation
        </icon-button>
        <b-link v-else to="/"> Retourner au tableau de bord </b-link>
      </template>
      <template v-else>
        <p>
          Vous pouvez tout de même créer un compte si vous n'en avez pas déjà un
          <span class="font-weight-bold">sans l'invitation</span> et vous serez ajouté à la
          communauté de votre quartier, si elle existe.
        </p>
        <icon-button variant="primary" to="/register">S'inscrire</icon-button>
      </template>
    </div>
  </layout-page>
</template>

<style lang="scss"></style>
