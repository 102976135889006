import prefixFields from "@/locales/prefixFields";

const i18n = {
  fr: {
    community: "communauté | communautés",
    create_community: "créer une communauté",
    selected_communities:
      "aucune communauté sélectionnée | {count} communauté sélectionnée | {count} communautés sélectionnées",
    placeholders: {
      chat_group_url: "ex.: https://groupe.communaute.com",
      starting_guide_url: "ex.: https://guide.communaute.com",
      contact_email: "ex.: mon.comite@gmail.com",
    },
    fields: {
      approver: "Approuvé par",
      chat_group_url: "URL du groupe de discussion",
      contact_email: "courriel pour joindre le comité",
      // When community is referred to from outisde of this context.
      community: {
        id: "ID communauté",
        name: "Communauté",
      },
      description: "description",
      id: "ID",
      long_description: "texte de bienvenue / présentation",
      starting_guide_url: "URL du guide de démarrage",
      name: "nom",
      residency_proof: "preuve d'adresse",
      identity_proof: "preuve d'identité",
      requires_identity_proof: "Preuve d'identité nécessaire",
      type: "type",
      types: {
        null: "n'importe quel type",
        borough: "quartier",
        private: "privée",
      },
      user: {
        id: "ID utilisateur\u2011rice",
        name: "Utilisateur\u2011rice",
        role: "Rôle",
        role_labels: {
          member: "Membre",
          admin: "Admin",
        },
        approved_at: "Approuvé\u2011e",
        suspended_at: "Suspendu\u2011e",
        proof: "Preuves",
        actions: "Actions",
        action_labels: {
          approve: "Approuver",
          suspend: "Suspendre",
          unsuspend: "Rétablir",
          remove: "Retirer",
        },
      },
      uses_noke: "Utilise les cadenas Noké",
      exempt_from_contributions: "Exemptée des contributions",
    },
    model_name: "communauté | communautés",
    user_list_empty_text: "Aucun membre à afficher.",
    user_proof_of_residence: "Preuve de résidence ({user_full_name})",
  },
  en: {
    community: "community | communities",
    create_community: "create a community",
    selected_communities:
      "no community selected | {count} community selected | {count} communities selected",
    fields: {
      // When community is referred to from outisde of this context.
      community: {
        id: "Community ID",
        _name: "Community",
      },
      description: "description",
      id: "ID",
      name: "name",
      type: "type",
      types: {
        null: "any type",
        borough: "borough",
        private: "private",
      },
      user: {
        id: "User ID",
        name: "User",
        role: "Role",
        role_labels: {
          member: "Member",
          admin: "Admin",
        },
        approved_at: "Approved",
        suspended_at: "Suspended",
        proof: "Proof",
        actions: "Actions",
        action_labels: {
          approve: "Approve",
          suspend: "Suspend",
          unsuspend: "Unsuspend",
          remove: "Remove",
        },
      },
    },
    user_list_empty_text: "No member to display.",
    user_proof_of_residence: "Proof of residence ({user_full_name})",
  },
};

export default i18n;
