<template>
  <layout-page name="register" wide bg-image centered box>
    <router-view />
  </layout-page>
</template>

<script>
const routeGuard = (to, from, next) => {
  if (to.name === "register") {
    return next("/register/1");
  }

  return next();
};

export default {
  name: "Register",
  beforeRouteEnter: routeGuard,
  beforeRouteUpdate: routeGuard,
};
</script>

<style lang="scss"></style>
