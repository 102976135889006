var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('paginated-table',{ref:"table",attrs:{"id":"admin-exports-table","endpoint":"exports","columns":_vm.columns,"extra-data":_vm.extraData,"selectable":"","label":"Exports","sync-filters-with-url":"","show-generate-csv":false},on:{"row-selected":function (exports) {
      _vm.selectedExports = exports;
      _vm.allExportsSelected = false;
    }},scopedSlots:_vm._u([{key:"head-buttons",fn:function(){return [_c('b-button-group',{staticClass:"ml-1",attrs:{"variant":"secondary"}},[_c('b-dropdown',{attrs:{"left":"","disabled":_vm.selectedExports.length === 0,"text":"Actions groupées"}},[_c('b-dropdown-item',{attrs:{"disabled":""}},[_vm._v(_vm._s(_vm.$tc("list.selected", _vm.selectedExportsCount, { count: _vm.selectedExportsCount }))+" ")]),(
            !_vm.allExportsSelected && _vm.$refs.table && _vm.$refs.table.total !== _vm.selectedExports.length
          )?_c('b-dropdown-item',{on:{"click":_vm.selectAll}},[_vm._v(" Sélectionner tous les exports correspondant aux filtres ("+_vm._s(_vm.$refs.table && _vm.$refs.table.total)+") ")]):_vm._e(),_c('b-dropdown-divider'),(_vm.canDeleteExport)?_c('b-dropdown-item',{on:{"click":_vm.deleteExports}},[_vm._v(" Supprimer définitivement ")]):_vm._e(),(_vm.canBatchCancelExports)?_c('b-dropdown-item',{attrs:{"disabled":!_vm.selectedCanceleable},on:{"click":_vm.cancelExports}},[_vm._v(" Annuler export(s) en cours ")]):_vm._e()],1)],1)]},proxy:true},{key:"cell(file.original_filename)",fn:function(ref){
          var item = ref.item;
return [(item.status !== 'completed')?_c('span',[_vm._v(" [Non complété] "),_c('br'),_vm._v(" "+_vm._s(item.file.original_filename)+" ")]):(item.file)?_c('safe-file',{attrs:{"file":item.file,"download":""}}):_vm._e()]}},{key:"cell(actions)",fn:function(ref){
          var item = ref.item;
return [_c('b-dropdown',{attrs:{"size":"sm","variant":"white-primary","no-caret":"","toggle-class":"mr-0"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b-icon',{attrs:{"icon":"three-dots-vertical"}}),_c('span',{staticClass:"sr-only"},[_vm._v("actions")])]},proxy:true}],null,true)},[(_vm.canCancelExport(item) && item.status === 'in_process')?_c('b-dropdown-item',{attrs:{"variant":"warning"},on:{"click":function () { return _vm.cancelExport(item); }}},[_c('b-icon',{attrs:{"icon":"stop-circle"}}),_vm._v(" Annuler ")],1):_vm._e(),(_vm.canDeleteExport)?_c('b-dropdown-item',{attrs:{"variant":"danger"},on:{"click":function () { return _vm.deleteExport(item); }}},[_c('b-icon',{attrs:{"icon":"trash"}}),_vm._v(" Supprimer ")],1):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }