import { get, put } from "@/requests/server";
import store from "@/store";

export async function acceptLoan(loan, intention) {
  try {
    const { data } = await get(`/loans/${loan.id}/isavailable`, {
      axiosRequestConfig: { params: { loanId: loan.id } },
      notifications: { action: "validation de la disponibilité du véhicule" },
    });

    if (!data.isAvailable) {
      store.commit("addNotification", {
        content: "Ce véhicule n'est pas disponible pour cette réservation.",
        title: "Véhicule non disponible",
        variant: "danger",
      });
      return false;
    }

    await put(`/loans/${loan.id}/intention/complete`, intention, {
      notifications: { action: "approbation de l'emprunt" },
    });

    return true;
  } catch (e) {
    return false;
  }
}

export async function cancelLoan(loanId) {
  await put(`/loans/${loanId}/cancel`, null, {
    notifications: { action: "annulation de l'emprunt", onSuccess: "emprunt annulé!" },
  });
}

export async function rejectLoan(loanId, data = null) {
  await put(`/loans/${loanId}/intention/cancel`, data, {
    notifications: { action: "refus de l'emprunt", onSuccess: "emprunt refusé!" },
  });
}
