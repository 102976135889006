<script>
import IconButton from "@/components/shared/IconButton.vue";
import Vue from "vue";

export default Vue.extend({
  name: "FormButtons",
  components: { IconButton },
  props: {
    changed: {
      type: Boolean,
      default: false,
    },
    saving: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      observer: null,
      unstuck: false,
    };
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.unobserve(this.$el);
      this.observer = null;
    }
  },
  mounted() {
    // With the root margin, we make sure the intersection is not complete (intersectionRatio === 1)
    // until we scroll past the button group
    this.observer = new IntersectionObserver(
      ([e]) => {
        // if the intesesction is complete, or the box is above the viewport
        this.unstuck = e.intersectionRatio === 1 || e.boundingClientRect.top <= 0;
      },
      {
        rootMargin: "0px 0px -1px 0px",
        threshold: [1],
      }
    );
    this.observer.observe(this.$el);
  },
});
</script>

<template>
  <div class="form__sticky_buttons" :class="{ unstuck: unstuck }">
    <slot>
      <div class="button-list">
        <icon-button
          role="save"
          type="submit"
          @click="$emit('submit')"
          :disabled="!changed || saving || loading"
          :loading="saving"
        >
          Enregistrer
        </icon-button>
        <icon-button
          role="reset"
          type="reset"
          :disabled="!changed || saving || loading"
          @click="$emit('reset')"
          :loading="loading"
        >
          Réinitialiser
        </icon-button>
      </div>
    </slot>
  </div>
</template>

<style scoped lang="scss">
.form__sticky_buttons {
  &.unstuck {
    box-shadow: none;
    border-top: 1px solid $grey;
    border-bottom: 1px solid $grey;
    margin: 0;
    padding: 1rem 0;
  }

  .button-list {
    display: flex;
    gap: 0.5rem;
  }
}
</style>
