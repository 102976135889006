var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vue-cal',{class:_vm.classList,attrs:{"hide-view-selector":true,"disable-views":['years', 'year'],"defaultView":_vm.defaultView,"snap-to-time":15,"editable-events":"","time-step":60,"time-cell-height":_vm.variant === 'small' ? 24 : 32,"events":_vm.vueCalEvents,"locale":"fr","start-week-on-sunday":"","selected-date":_vm.initialDate,"xsmall":_vm.variant === 'small'},on:{"ready":function($event){return _vm.$emit('ready', $event)},"view-change":function($event){return _vm.$emit('view-change', $event)},"event-change":function($event){return _vm.$emit('event-change', $event)}},scopedSlots:_vm._u([{key:"title",fn:function(ref){
var title = ref.title;
var view = ref.view;
return [(view.id === 'years')?_c('span',[_vm._v("Years")]):(view.id === 'year')?_c('span',[_vm._v(_vm._s(view.startDate.format("YYYY")))]):(view.id === 'month')?_c('span',[_vm._v(_vm._s(view.startDate.format("MMMM YYYY")))]):(view.id === 'week')?_c('span',[_vm._v(_vm._s(view.startDate.format("MMMM YYYY")))]):(view.id === 'day')?_c('span',[_vm._v(_vm._s(view.startDate.format("dddd D MMMM (YYYY)")))]):_vm._e()]}},{key:"time-cell",fn:function(ref){
var hours = ref.hours;
var minutes = ref.minutes;
return [_c('div',{class:{
        'loanable-calendar__time-step--hours': !minutes,
        'loanable-calendar__time-step--minutes': minutes,
      }},[_c('span',{staticClass:"line"}),(!minutes)?[_c('span',[_vm._v(_vm._s(hours < 10 ? "0" + hours : hours)+":"+_vm._s(minutes < 10 ? "0" + minutes : minutes))])]:_vm._e()],2)]}},{key:"cell-content",fn:function(ref){
      var cell = ref.cell;
      var view = ref.view;
      var events = ref.events;
return [(view.id === 'month')?_c('calendar-month-cell-content',{attrs:{"availability":_vm.getMonthDayAvailability(events, cell),"current":cell.today}},[_vm._v(" "+_vm._s(cell.content)+" ")]):_c('span',{staticClass:"vuecal__cell-date"},[_vm._v(" "+_vm._s(cell.content)+" ")])]}},{key:"event",fn:function(ref){
      var event = ref.event;
      var view = ref.view;
return [_c('div',{staticClass:"vuecal__event-title",domProps:{"innerHTML":_vm._s(event.title)}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }