<script>
import Vue from "vue";

export default Vue.extend({
  name: "IconButton",
  props: {
    // Icon to be used in the button.
    icon: {
      type: String,
      default: null,
    },
    // Color variant of the button (follows bootstrap variants)
    variant: {
      type: String,
      default: "",
    },
    // If specified, role sets a default icon and variant to be used for the button.
    // Valid roles: add, save, send, cancel, load, reset, delete, remove-item
    role: {
      type: String,
      default: "",
    },
    // Whether the button should show a loading spinner and be disabled.
    loading: {
      type: Boolean,
      default: false,
    },
    // Whether the button should be disabled.
    disabled: {
      type: Boolean,
      default: false,
    },
    // Function to call on click. If the function is async, while it runs the button will behave
    // as if the loading prop was set to true. This can replace the loading prop and the @click
    // event listener pair and should be used where possible.
    onclick: {
      type: Function,
      default: null,
    },
    // Should the icon spin when loading rather than showing a spinner
    spinIcon: {
      type: Boolean,
      default: false,
    },
    // Should the button take the whole width and have centered text
    block: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      clicking: false,
    };
  },
  computed: {
    internalVariant() {
      if (this.variant) {
        return this.variant;
      }
      let variant = null;
      switch (this.role) {
        case "add":
          return "outline-success";
        case "save":
        case "send": {
          variant = "success";
          break;
        }
        case "cancel":
        case "load":
        case "reset": {
          variant = "ghost-secondary";
          break;
        }
        case "delete":
          return "outline-danger";
        case "remove-item":
          return "ghost-danger";
      }
      return variant;
    },
    internalIcon() {
      if (this.icon) {
        return this.icon;
      }

      switch (this.role) {
        case "add":
          return "plus-circle";
        case "save":
          return "file-earmark-arrow-down";
        case "send":
          return "arrow-right-circle";
        case "cancel":
          return null;
        case "load":
          return "arrow-clockwise";
        case "reset":
          return "arrow-counterclockwise";
        case "delete":
          return "trash";
        case "remove-item":
          return "trash";
      }
      return null;
    },
    internalLoading() {
      return this.clicking || this.loading;
    },
  },
  methods: {
    async handleClick(e) {
      if (this.onclick) {
        this.clicking = true;
        try {
          await this.onclick();
        } finally {
          this.clicking = false;
        }
      } else {
        this.$emit("click", e);
      }
    },
  },
});
</script>

<template>
  <b-button
    class="icon-button"
    :class="{
      'icon-button-block': block,
    }"
    @click="handleClick"
    v-bind="$attrs"
    :variant="internalVariant"
    :disabled="disabled || internalLoading"
  >
    <b-spinner
      class="spinner"
      small
      v-if="internalLoading && !spinIcon"
      :class="{ 'mr-2': $slots.default }"
    />
    <b-icon
      v-else-if="internalIcon"
      class="button-icon"
      :icon="internalIcon"
      :class="{ 'mr-2': $slots.default }"
      :animation="internalLoading ? 'spin' : ''"
    />
    <slot></slot>
  </b-button>
</template>

<style lang="scss">
.btn.icon-button {
  display: inline-flex;
  align-items: center;
  // 20px * 1.2 line-height + 0.5rem padding + 2px border
  min-height: 34px;

  &.icon-button-block {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .spinner {
    width: 20px;
    height: 20px;
    border-width: 2px;
    display: block;
  }
  .b-icon.bi {
    font-size: 20px;
  }
  &.btn-sm {
    // 0.875rem line height * 16px * 1.5 line height + 0.5rem padding + 2px border
    min-height: 31px;
    .spinner {
      width: 18px;
      height: 18px;
    }

    .bi.b-icon {
      font-size: 18px;
    }
  }
}
</style>
