<script>
import Vue from "vue";
import vSelect from "vue-select";

const validEmailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[a-zA-Z]{2,}/;

export default Vue.extend({
  name: "MultipleEmailInput",
  components: { vSelect },
  props: {
    value: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      searchValidEmail: null,
    };
  },
  methods: {
    addEmails(newEmailsString) {
      const newEmails = newEmailsString.split(/[\s,]+/);
      const newInvalidEmails = [];
      const newValidEmails = [];

      let invalidEmailString = newEmailsString;

      for (const newEmail of newEmails) {
        if (validEmailRegex.test(newEmail)) {
          newValidEmails.push(newEmail);
          invalidEmailString = invalidEmailString.replaceAll(newEmail, "");
        } else {
          newInvalidEmails.push(newEmail);
        }
      }

      if (newValidEmails.length > 0) {
        this.emitEmails([...this.emailsAsList, ...newValidEmails]);
      }

      this.$refs.list.search = invalidEmailString;
    },
    // Called on <enter>, <backspace> or item is deleted
    onInput(emails) {
      // On <enter>, some text may be present in the search input, which vue-select
      // would add as a whole, but may actually be a list of emails
      if (emails.length > 0 && emails[emails.length - 1] === this.$refs.list.search) {
        this.addEmails(this.$refs.list.search);
        this.searchValidEmail = this.$refs.list.search.length === 0;
      } else {
        // On item deletion, simply emit the current list from vue-select.
        this.emitEmails(emails);
      }
    },
    onBlur() {
      // Add what has been typed so far
      this.addEmails(this.$refs.list.search);

      // On blur, we clear any  invalid text that remains
      this.$refs.list.search = "";
      this.searchValidEmail = null;
    },
    onEmailTyped(searchValue) {
      const endsInSeparator = /[\s,]+$/.test(searchValue);
      // Remove any whitespace and commas at the start or end
      const trimmedValue = searchValue.replaceAll(/^[\s,]+/g, "").replaceAll(/[\s,]+$/g, "");

      // If user has typed a separator at the end, we try to input what has been written so far.
      if (trimmedValue.length > 0 && endsInSeparator) {
        this.addEmails(trimmedValue);

        this.searchValidEmail = this.$refs.list.search.length === 0;
      } else {
        this.$refs.list.search = trimmedValue;
        if (this.searchValidEmail === false) {
          // Hide 'invalid' state as soon as email looks okay
          this.searchValidEmail = trimmedValue === "" || validEmailRegex.test(trimmedValue);
        }
      }
    },
    emitEmails(emailList) {
      const emailToCount = new Map();
      for (const email of emailList) {
        if (!emailToCount.has(email)) {
          emailToCount.set(email, 1);
        } else {
          emailToCount.set(email, emailToCount.get(email) + 1);
        }
      }

      const uniqueEmails = [...emailToCount.keys()];
      this.$emit("input", uniqueEmails.join(","));

      // Shake duplcated emails.
      const duplicateEmails = new Set(
        [...emailToCount].filter(([email, count]) => count > 1).map(([email, count]) => email)
      );
      this.$nextTick(() => {
        // find duplicate emails and vibrate them;
        const elements = [...this.$el.getElementsByClassName("vs__selected")].filter((el) =>
          duplicateEmails.has(el.innerText)
        );

        // We remove and re-add them to trigger the animation
        elements.forEach((el) => el.classList.remove("duplicate"));
        setTimeout(() => elements.forEach((el) => el.classList.add("duplicate")), 10);
      });
    },
    onEnterUp() {
      if (this.$refs.list.search.length === 0 && this.value.length !== 0) {
        this.$emit("submit");
      }
    },
  },
  computed: {
    emailsAsList() {
      return this.value && this.value !== "" ? this.value.split(",") : [];
    },
  },
});
</script>

<template>
  <v-select
    ref="list"
    :value="emailsAsList"
    taggable
    @input="onInput"
    multiple
    :placeholder="placeholder"
    no-drop
    :clearSearchOnSelect="false"
    @search="(searchValue) => onEmailTyped(searchValue)"
    @search:blur="onBlur"
    class="multiple-email-input"
    :class="{
      'search-invalid': searchValidEmail === false,
    }"
    @keydown.enter.native="onEnterUp"
  >
  </v-select>
</template>

<style lang="scss">
.multiple-email-input {
  .vs__dropdown-toggle {
    background: $white;
  }
  &.search-invalid {
    .vs__search {
      color: $danger;
    }
  }

  .vs__selected {
    background: $background-alert-positive;
    border: 0;
    color: $dark;
    padding: 0.15em 0.5em;
    .vs__deselect {
      margin-left: 0.5em;
    }
  }
}

@keyframes shake {
  16%,
  84% {
    transform: translateY(-3px);
  }

  33%,
  66% {
    transform: translateY(3px);
  }

  50% {
    transform: translateY(-3px);
  }
}

.vs__selected.duplicate {
  animation: shake 0.8s none;
}
</style>
