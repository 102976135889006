<script>
import { get } from "@/requests/server";
import ProfileCommunitySubscriptions from "@/views/profile/CommunitySubscriptions.vue";
import Vue from "vue";

export default Vue.extend({
  name: "ProfileSubscriptions",
  components: { ProfileCommunitySubscriptions },
  data() {
    return {
      loading: false,
      loaded: false,
      /** @type {[{community:{}, subscriptions:[], pricings_by_loanable_types:{}}]} */
      data: [],
    };
  },
  computed: {
    hasAnyCommunityWithPricings() {
      let anyPayingCommunity = false;
      for (const communityPricings of this.data) {
        if (
          communityPricings.pricings_by_loanable_types &&
          Object.keys(communityPricings.pricings_by_loanable_types).length > 0
        ) {
          anyPayingCommunity = true;
          break;
        }
      }

      return anyPayingCommunity;
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.loading = true;
      try {
        const { data } = await get("/subscriptions/my-targets", {
          cleanupCallback: () => (this.loading = false),
          notifications: { action: "chargement des contributions annuelles" },
        });
        this.data = data;
        this.loaded = true;
      } catch (e) {
        await this.$router.push("/app");
      }
    },
  },
});
</script>

<template>
  <layout-loading v-if="loading" />
  <div v-else>
    <template v-if="!hasAnyCommunityWithPricings">
      <h2>Contributions annuelles</h2>
      <b-alert variant="success" show>
        Aucune de vos communautés ne vous permet de contribuer annuellement.
      </b-alert>
    </template>
    <b-card
      v-else
      class="communtiy-subscription-card mb-3"
      v-for="communityContribution in data"
      :key="communityContribution.community.id"
    >
      <profile-community-subscriptions
        :community="communityContribution.community"
        :pricings-per-loanable-type="communityContribution.pricings_by_loanable_types"
        :subscriptions="communityContribution.subscriptions"
        @paid="loadData"
      />
    </b-card>
  </div>
</template>

<style scoped lang="scss">
.communtiy-subscription-card {
  box-shadow: $small-shadow;
}
</style>
