<script>
import Bike from "@/assets/svg/bike.svg";
import BikeCargo from "@/assets/svg/bike_cargo.svg";
import BikeCargoElectric from "@/assets/svg/bike_cargo_electric.svg";
import BikeElectric from "@/assets/svg/bike_electric.svg";
import CarElectric from "@/assets/svg/car_electric.svg";
import CarLarge from "@/assets/svg/car_large.svg";
import CarSmall from "@/assets/svg/car_small.svg";
import Pin from "@/assets/svg/pin.svg";
import Trailer from "@/assets/svg/trailer.svg";
import { loader } from "@/helpers/googleMaps";
import Vue from "vue";

export default Vue.extend({
  name: "MapMarker",
  components: {
    bike_regular: Bike,
    bike_electric: BikeElectric,
    bike_cargo_regular: BikeCargo,
    bike_cargo_electric: BikeCargoElectric,
    car_small: CarSmall,
    car_large: CarLarge,
    car_electric: CarElectric,
    trailer: Trailer,
    // Backups in case the pricing loanable type is not present
    bike: Bike,
    car: CarLarge,
    pin: Pin,
  },
  data() {
    return {
      marker: null,
    };
  },
  props: {
    loanable: {
      required: true,
    },
    map: {
      required: true,
    },
    onclick: {
      type: Function,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    ghost: {
      type: Boolean,
      default: false,
    },
  },
  async mounted() {
    const { AdvancedMarkerElement } = await loader.importLibrary("marker");

    this.marker = new AdvancedMarkerElement({
      map: this.map,
      position: this.loanable.position_google,
      content: this.$refs.marker,
      gmpClickable: true,
      zIndex: this.loanable.available ? 12 : 8,
    });

    if (this.onclick) {
      this.marker.addListener("click", () => this.onclick(this.loanable, this.marker));
    }
  },
  destroyed() {
    this.marker.setMap(null);
    this.marker = null;
  },
  computed: {
    icon() {
      return this.loanable.pricing_loanable_type;
    },
    glyph() {
      if (this.loanable.pricing_loanable_type) {
        return this.loanable.pricing_loanable_type;
      }

      return this.loanable.type;
    },
  },
});
</script>

<template>
  <div class="d-none">
    <div
      ref="marker"
      class="loanable-map-marker"
      :class="{
        ghost: ghost,
        outline: outline,
        car: loanable.type === 'car',
        trailer: loanable.type === 'trailer',
        bike: loanable.type === 'bike',
      }"
    >
      <pin ref="pin" :id="`pin-${loanable.id}`" class="pin" />
      <component ref="glyph" class="glyph" :is="glyph" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.loanable-map-marker {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 5.5px auto;
  gap: 0;
  justify-items: center;
  pointer-events: none;
  user-select: none;
  width: 28px;
  height: 40px;

  &.ghost {
    opacity: 0.5;
  }

  .pin {
    display: block;
    overflow: visible;
    grid-area: 1;
  }

  .glyph {
    grid-area: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 16px;

    path {
      stroke-width: 0.2;
      fill: #fff;
      stroke: #fff;
    }
  }

  &.car {
    .pin path {
      fill: #4fb999;
      stroke: #4fb999;
    }

    &.outline {
      .pin path {
        stroke: #57917f;
      }
      .glyph path {
        stroke: #57917f;
        fill: #57917f;
      }
    }
  }

  &.trailer {
    .pin path {
      fill: #f9ca51;
      stroke: #f9ca51;
    }

    &.outline {
      .pin path {
        stroke: #c49e3c;
      }
      .glyph path {
        stroke: #c49e3c;
        fill: #c49e3c;
      }
    }
  }

  &.bike {
    .pin path {
      fill: #246aea;
      stroke: #246aea;
    }

    &.outline {
      .glyph path {
        stroke: #246aea;
        fill: #246aea;
      }
    }
  }

  &.outline .pin path {
    fill: #fff;
  }
}
</style>
