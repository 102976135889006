<template>
  <a
    ref="link"
    v-if="download && url"
    :download="file.original_filename"
    :href="url"
    target="_blank"
  >
    <slot>{{ file.original_filename }}</slot>
  </a>
  <a v-else-if="url" ref="link" :href="url" target="_blank">
    <slot>{{ file.original_filename }}</slot>
  </a>
  <div v-else class="d-inline-block">
    <b-link @click="downloadFile" :class="{ 'text-danger': failedLoading }">
      <slot>{{ file.original_filename }}</slot>
    </b-link>
    <b-progress v-if="percentCompleted > 0" height="2px" :value="percentCompleted" />
  </div>
</template>

<script>
import Vue from "vue";
import { get } from "@/requests/server";

export default Vue.extend({
  name: "SafeFile",
  props: {
    file: {
      type: Object,
      required: false,
    },
    download: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      url: null,
      percentCompleted: 0,
      failedLoading: false,
    };
  },
  methods: {
    async downloadFile() {
      if (!this.url && !this.percentCompleted) {
        try {
          const response = await get("/files/" + this.file.id, {
            axiosRequestConfig: {
              responseType: "blob",
              onDownloadProgress: (progressEvent) => {
                this.percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                ); // you can use this to show user percentage of file downloaded
              },
            },
            notifications: {
              action: "chargement du fichier",
              ressourceName: this.file.original_filename,
            },
          });
          this.url = URL.createObjectURL(response.data);
        } catch (e) {
          this.failedLoading = true;
        } finally {
          this.percentCompleted = 0;
        }
      }

      if (this.url) {
        // Once we render the link, we click it rather than just opening the url in a new window
        // so we can use the <a>'s "download" attribute which sets the file name.
        this.$nextTick(() => {
          this.$refs.link.click();
        });
      }
    },
  },
  beforeDestroy() {
    if (this.url) {
      URL.revokeObjectURL(this.url);
      this.url = null;
    }
  },
});
</script>
