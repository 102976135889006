<template>
  <div class="d-flex">
    <b-form-select
      size="sm"
      :name="name"
      v-if="type === 'select'"
      :value="typeof value === 'undefined' ? null : value"
      @input="onChange"
    >
      <b-form-select-option v-for="option in options" :key="option.value" :value="option.value">
        {{ option.label | capitalize }}
      </b-form-select-option>
    </b-form-select>
    <div v-else-if="type === 'boolean'">
      <b-form-checkbox
        :name="name"
        :value="true"
        :unchecked-value="false"
        :checked="value"
        @change="onCheckChange"
      >
      </b-form-checkbox>
    </div>
    <div v-else-if="type === 'date'">
      <forms-date-range-picker :value="value" @input="onChange" />
    </div>
    <div v-else-if="type === 'number' || type === 'currency'">
      <forms-number-range-picker :value="value" @input="onChange" />
    </div>
    <div v-else-if="type === 'relation'" class="flex-fill">
      <relation-input
        @click.native.stop.prevent
        ref="relationInput"
        class="filter-input-relation"
        :min-search-length="0"
        :show-clear-button="false"
        value=""
        :query="{
          slug: options.relation,
          value: options.field,
          text: options.label,
          params: options.params,
          details: options.details,
        }"
        :name="name"
        :value="value"
        @input="(c) => onChange(c[options.field])"
      />
    </div>
    <b-form-input ref="input" v-else size="sm" :value="value" debounce="300" @update="onChange" />
    <icon-button
      v-if="type !== 'select' && type !== 'boolean' && value"
      variant="link"
      size="sm"
      @click="clear"
      icon="x"
    />
  </div>
</template>
<script>
import FormsDateRangePicker from "@/components/Forms/DateRangePicker.vue";
import FormsNumberRangePicker from "@/components/Forms/NumberRangePicker.vue";
import RelationInput from "@/components/Forms/RelationInput.vue";
import IconButton from "@/components/shared/IconButton.vue";

export default {
  name: "filterInput",
  components: { IconButton, RelationInput, FormsNumberRangePicker, FormsDateRangePicker },
  props: {
    type: {
      type: String,
      required: true,
    },
    value: {},
    name: {
      type: String,
      required: true,
    },
    options: {
      type: Object | Array,
      default() {
        return null;
      },
    },
  },
  mounted() {
    // We have to set the html element size this way, since it's also a prop on the bootstrap-vue
    // components
    if (this.type === "id") {
      this.$refs.input.$el.size = 5;
    }
  },
  methods: {
    clear() {
      this.$emit("input", null);
    },
    onChange(value) {
      this.$emit("input", value);
    },
    onCheckChange(value) {
      if (value) {
        this.$emit("input", true);
        return;
      }
      this.$emit("input", null);
    },
  },
};
</script>
<style lang="scss">
.filter-input-relation .vs__dropdown-toggle {
  padding-bottom: 0;

  // From .custom-select-sm
  font-size: 0.875rem;
  min-height: calc(1.5em + 0.5rem + 2px);
  .vs__actions {
    padding: 0 10px 0 0;
  }
}
</style>
