<template>
  <b-form-select
    class="time-selector"
    :disabled="disabled"
    :disabled-times-fct="disabledTimesFct"
    v-model="selected"
    :options="timeslots"
    @change="onSelection"
  />
</template>

<script>
import dayjs from "dayjs";

const MILLISECONDS_IN_A_DAY = 24 * 60 * 60 * 1000;
const MILLISECONDS_IN_A_MINUTE = 60 * 1000;

export default {
  name: "TimeSelector",
  data() {
    const timeslots = this.allDayTimeSlots(this.getDateInTimezone(this.value));
    return {
      selected: this.closestValue(timeslots, this.value),
      timeslots,
    };
  },
  props: {
    /**
     * Value must be a dayjs in UTC format.
     */
    value: {
      type: dayjs,
      required: false,
      default: () => dayjs(),
    },
    minuteInterval: {
      type: Number,
      required: false,
      default: () => 15,
      validator: (value) => {
        if (MILLISECONDS_IN_A_DAY % (value * MILLISECONDS_IN_A_MINUTE) !== 0) {
          console.error(
            `Invalid prop in \`minuteInterval\` in TimeSelector: 24 hours should be divisible by this number of minutes`
          );
        }
        return true;
      },
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabledTimesFct: {
      type: Function,
      required: false,
      default: () => false,
    },
    timezone: {
      type: String,
      default: null,
    },
  },

  methods: {
    /**
     * Returns a YYYY-MM-DD formatted date in the component's timezone.
     *
     * @param {dayjs.Dayjs} utcDay
     */
    getDateInTimezone(utcDay) {
      if (this.timezone) {
        return utcDay.tz(this.timezone).format("YYYY-MM-DD");
      }
      return utcDay.format("YYYY-MM-DD");
    },
    /**
     * @param {string} date a YYYY-MM-DD formatted date
     * @return dropdown options for all the timeslots in the given day.
     */
    allDayTimeSlots(date) {
      // Dayjs doesn't handle adding when handling a date with a timezone (specifically arount
      // Daylight saving time changes), so we operate on the UTC date and only
      // use the zoned times for formatting and boundary conditions.

      let utcDate = this.timezone
        ? dayjs.tz(`${date}T00:00`, this.timezone).utc()
        : dayjs.utc(`${date}T00:00`);
      let localDate = this.timezone ? utcDate.tz(this.timezone) : utcDate;

      const options = [];

      while (localDate.format("YYYY-MM-DD") === date) {
        options.push({
          unix: utcDate.unix(),
          value: utcDate,
          text: localDate.format("HH:mm"),
          disabled: this.disabledTimesFct && this.disabledTimesFct(localDate),
        });
        utcDate = utcDate.add(this.minuteInterval, "minutes");
        localDate = this.timezone ? utcDate.tz(this.timezone) : utcDate;
      }
      return options;
    },

    closestValue(timeslots, needle) {
      const needleUnix = needle.unix();

      const closestTimeslots = timeslots
        .filter((f) => !f.disabled)
        .sort((a, b) => Math.abs(a.unix - needleUnix) - Math.abs(b.unix - needleUnix));

      return closestTimeslots[0].value;
    },
    onSelection() {
      if (this.disabled) {
        return;
      }
      this.$emit("input", this.selected);
    },
  },

  watch: {
    value: function (newValue, oldValue) {
      const newDate = this.getDateInTimezone(newValue);
      if (newDate !== this.getDateInTimezone(oldValue)) {
        this.timeslots = this.allDayTimeSlots(newDate);

        let selectedAtNewDate;
        if (this.timezone) {
          // Offset might change when date changes (daylight savings time).
          // We want to keep the same hours:minutes, so we need to do this in the timezone
          selectedAtNewDate = dayjs
            .tz(`${newDate}T${newValue.tz(this.timezone).format("HH:mm")}`, this.timezone)
            .utc();
        } else {
          selectedAtNewDate = newValue;
        }

        this.selected = this.closestValue(this.timeslots, selectedAtNewDate);
        this.onSelection();
        return;
      }

      if (!newValue.isSame(oldValue) && !newValue.isSame(this.selected)) {
        // only time has changed
        this.selected = this.closestValue(this.timeslots, newValue);
        this.onSelection();
      }
    },
  },
};
</script>

<style lang="scss"></style>
