var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('conditional-context',{staticClass:"pricing-form-context",attrs:{"show":_vm.isFormDisabled,"label":"réservé aux admins globaux","background":"white","additional-info":"Vous n'avez pas les permissions pour modifier les tarifications de ce type."}},[_c('validation-observer',{staticClass:"pricing-form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return passes(_vm.save)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('forms-validated-input',{attrs:{"type":"text","name":("name_" + (_vm.pricing.id)),"label":"Nom","disabled":_vm.isFormDisabled,"rules":{
              required: true,
            }},on:{"input":_vm.emitChange},model:{value:(_vm.pricing.name),callback:function ($$v) {_vm.$set(_vm.pricing, "name", $$v)},expression:"pricing.name"}}),_c('forms-validated-input',{attrs:{"disabled":_vm.isFormDisabled,"type":"textarea","name":("description_" + (_vm.pricing.id)),"label":"Description","max-rows":2,"rows":1,"placeholder":"ex. Permet de financer le maintien de la flotte et l'achat d'une nouvelle remorque.","rules":{
              max: 150,
            }},on:{"input":_vm.emitChange},model:{value:(_vm.pricing.description),callback:function ($$v) {_vm.$set(_vm.pricing, "description", $$v)},expression:"pricing.description"}}),_c('forms-validated-input',{attrs:{"disabled":_vm.isFormDisabled,"type":"select","name":("pricing_type_" + (_vm.pricing.id)),"options":_vm.pricingTypeOptions,"label":"Type","rules":{
              required: true,
            }},on:{"input":_vm.emitChange},model:{value:(_vm.pricing.pricing_type),callback:function ($$v) {_vm.$set(_vm.pricing, "pricing_type", $$v)},expression:"pricing.pricing_type"}}),(_vm.exemptFromContributions)?_c('b-alert',{attrs:{"variant":"warning","show":""}},[_vm._v(" Cette communauté est exempte des contributions. Cette tarification n'aura donc aucun effet. ")]):_vm._e(),(_vm.pricing.pricing_type === 'contribution')?_c('conditional-context',{staticClass:"mt-4 mx-0",attrs:{"show":"","label":"Options de contribution","background":"white"}},[(_vm.pricing.community_id)?_c('forms-validated-input',{attrs:{"disabled":_vm.isFormDisabled,"type":"checkbox","name":("is_mandatory_" + (_vm.pricing.id)),"label":"Contribution obligatoire"},on:{"input":_vm.emitChange},model:{value:(_vm.pricing.is_mandatory),callback:function ($$v) {_vm.$set(_vm.pricing, "is_mandatory", $$v)},expression:"pricing.is_mandatory"}}):_vm._e(),_c('forms-validated-input',{attrs:{"type":"currency","name":("yearly_target_per_user_" + (_vm.pricing.id)),"disabled":_vm.isFormDisabled,"label":"Cible annuelle par membre ($)"},on:{"input":_vm.emitChange},model:{value:(_vm.pricing.yearly_target_per_user),callback:function ($$v) {_vm.$set(_vm.pricing, "yearly_target_per_user", $$v)},expression:"pricing.yearly_target_per_user"}})],1):_vm._e()],1),_c('b-col',{attrs:{"md":"6"}},[_c('conditional-context',{staticClass:"mt-4 mx-0",attrs:{"show":"","label":"Véhicules ciblés","background":"white"}},[(
                _vm.pricing.pricing_type === 'insurance' || _vm.pricing.pricing_type === 'contribution'
              )?_c('forms-validated-input',{attrs:{"type":"select","name":("loanable_ownership_type_" + (_vm.pricing.id)),"disabled":_vm.isFormDisabled,"options":_vm.vehicleOwnershipOptions,"label":"Véhicules partagés par","rules":{
                required: true,
              }},on:{"input":_vm.emitChange},model:{value:(_vm.pricing.loanable_ownership_type),callback:function ($$v) {_vm.$set(_vm.pricing, "loanable_ownership_type", $$v)},expression:"pricing.loanable_ownership_type"}}):_vm._e(),_c('b-form-group',{staticClass:"pricing-loanable-types",attrs:{"label":"Types de véhicules","label-for":"pricing_loanable_types"}},[_c('div',{staticClass:"position-relative"},[(_vm.showBikes)?_c('b-checkbox',{staticClass:"group-select-all",attrs:{"checked":_vm.allBikeChecked,"indeterminate":_vm.someBikeChecked,"disabled":_vm.isFormDisabled},on:{"change":_vm.allBikesClicked}},[_vm._v(" Tous les vélos ")]):_vm._e(),_c('validation-provider',{attrs:{"name":"Types de véhicules","rules":{
                    required: true,
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var validated = ref.validated;
                  var valid = ref.valid;
                  var errors = ref.errors;
return [(_vm.showBikes)?_c('b-checkbox-group',{staticClass:"checkbox-group",attrs:{"options":_vm.bikeOptions,"disabled":_vm.isFormDisabled},on:{"input":_vm.emitChange},model:{value:(_vm.pricing.pricing_loanable_types),callback:function ($$v) {_vm.$set(_vm.pricing, "pricing_loanable_types", $$v)},expression:"pricing.pricing_loanable_types"}}):_vm._e(),(_vm.showTrailers)?_c('b-checkbox-group',{staticClass:"checkbox-group",attrs:{"disabled":_vm.isFormDisabled,"options":_vm.trailerOptions},on:{"input":_vm.emitChange},model:{value:(_vm.pricing.pricing_loanable_types),callback:function ($$v) {_vm.$set(_vm.pricing, "pricing_loanable_types", $$v)},expression:"pricing.pricing_loanable_types"}}):_vm._e(),(_vm.showCars)?_c('div',{staticClass:"position-relative"},[_c('b-checkbox',{staticClass:"group-select-all",attrs:{"disabled":_vm.isFormDisabled,"checked":_vm.allCarsChecked,"indeterminate":_vm.someCarsChecked},on:{"change":_vm.allCarsClicked}},[_vm._v(" Toutes les autos ")]),_c('b-checkbox-group',{staticClass:"checkbox-group",attrs:{"disabled":_vm.isFormDisabled,"options":_vm.carOptions},on:{"input":_vm.emitChange},model:{value:(_vm.pricing.pricing_loanable_types),callback:function ($$v) {_vm.$set(_vm.pricing, "pricing_loanable_types", $$v)},expression:"pricing.pricing_loanable_types"}})],1):_vm._e(),_c('b-form-invalid-feedback',{attrs:{"state":validated ? valid : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)])],1)],1)],1),_c('forms-validated-input',{staticClass:"formula-input",attrs:{"type":"textarea","name":("rule_" + (_vm.pricing.id)),"disabled":_vm.isFormDisabled,"label":"Formule par emprunt","rules":{
          required: true,
        }},on:{"input":_vm.emitChange},model:{value:(_vm.pricing.rule),callback:function ($$v) {_vm.$set(_vm.pricing, "rule", $$v)},expression:"pricing.rule"}}),_c('icon-button',{attrs:{"loading":_vm.saving,"role":"save","type":"submit","disabled":_vm.saving || _vm.deleting || !_vm.pricing._changed || _vm.isFormDisabled}},[_vm._v(" "+_vm._s(_vm.pricing.id ? "Enregistrer" : "Créer")+" ")]),(_vm.pricing.id)?_c('validated-button',{staticClass:"ml-2",attrs:{"disabled":_vm.saving || _vm.deleting || _vm.isFormDisabled,"action":_vm.deletePricing,"variant":"outline-danger","label":"Archiver","question":"Êtes-vous sûr de vouloir archiver cette tarification?","icon":"trash"}}):_vm._e(),(_vm.pricing.id)?_c('icon-button',{staticClass:"ml-2",attrs:{"disabled":_vm.saving || _vm.deleting || _vm.isFormDisabled || !_vm.pricing._changed,"role":"reset"},on:{"click":_vm.reset}},[_vm._v(" Réinitialiser ")]):_c('icon-button',{staticClass:"ml-2",attrs:{"role":"remove-item"},on:{"click":_vm.deletePricing}},[_vm._v(" Annuler ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }