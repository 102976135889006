<template>
  <b-card
    no-body
    class="loan-form loan-actions loan-actions-extension"
    :id="`loan-extension-${action.id || 'new'}`"
  >
    <b-card-header
      header-tag="header"
      role="tab"
      class="loan-actions__header"
      :class="{
        'no-content': !hasContent,
      }"
      v-b-toggle="hasContent ? `loan-actions-extension-${action.id || 'new'}` : ''"
    >
      <b-row>
        <b-col>
          <h2>
            <svg-waiting v-if="action.status === 'in_process' && !loanIsCanceled" />
            <svg-check v-else-if="action.status === 'completed'" />
            <svg-danger
              v-else-if="
                action.status === 'canceled' || action.status === 'rejected' || loanIsCanceled
              "
            />

            Retard
          </h2>

          <!-- Canceled loans: current step remains in-process. -->
          <span v-if="action.status === 'in_process' && loanIsCanceled">
            Emprunt annulé &bull; {{ item.canceled_at | datetime }}
          </span>
          <span v-else-if="(action.status == 'in_process') & !loanIsCanceled"> En attente </span>
          <span v-else-if="action.status === 'completed'">
            Accepté &bull; {{ action.executed_at | datetime }}
          </span>
          <span v-else-if="action.status === 'rejected'">
            Refusé &bull; {{ action.executed_at | datetime }}
          </span>
          <span v-else-if="action.status === 'canceled'">
            Annulé &bull; {{ action.executed_at | datetime }}
          </span>
        </b-col>

        <b-col
          lg="8"
          v-if="item.status === 'in_process' && !loanIsCanceled && action.status === 'in_process'"
        >
          <loan-next-date
            :loanable-id="item.loanable.id"
            :loan-id="item.id"
            :extension-id="action.id || 'new'"
          />
        </b-col>
      </b-row>
    </b-card-header>

    <b-collapse
      v-if="hasContent"
      :id="`loan-actions-extension-${action.id || 'new'}`"
      role="tabpanel"
      accordion="loan-actions"
      :visible="open"
    >
      <b-card-body>
        <div v-if="action.status === 'completed'">
          <!-- Action is completed -->
          <p>Demande d'extension jusqu'au {{ zonedReturnAt | datetime }}.</p>

          <blockquote v-if="action.comments_on_extension">
            <user-avatar :user="borrower.user" />
            {{ action.comments_on_extension }}
          </blockquote>

          <blockquote v-if="action.message_for_borrower">
            <user-avatar :user="owner.user" />
            {{ action.message_for_borrower }}
          </blockquote>
        </div>
        <div v-else-if="!action.id">
          <p>Indiquez une nouvelle heure de retour et laissez un message.</p>

          <validation-observer ref="observer" v-slot="{ passes }">
            <b-form
              :novalidate="true"
              class="form loan-actions-extension__form"
              @submit.stop.prevent="passes(createExtension)"
              @reset.stop.prevent="$emit('reset')"
            >
              <b-row>
                <b-col lg="6">
                  <forms-validated-input
                    name="return_at"
                    :rules="{ required: true }"
                    label="Nouvelle date de retour"
                    type="datetime"
                    :disabled-dates-fct="dateIsNotAfterScheduledReturn"
                    :disabled-times-fct="timeIsNotAfterScheduledReturn"
                    v-model="returnAt"
                    :timezone="item.loanable.timezone"
                  />
                </b-col>
                <b-col>
                  <forms-validated-input
                    name="comments_on_extension"
                    :rules="{ required: true }"
                    label="Commentaire"
                    type="textarea"
                    :rows="3"
                    v-model="action.comments_on_extension"
                  />
                </b-col>
              </b-row>

              <div class="loan-actions-extension__buttons text-center">
                <icon-button
                  size="sm"
                  type="submit"
                  variant="success"
                  class="mr-2"
                  :disabled="!extensionValid"
                  :loading="actionLoading"
                >
                  Créer
                </icon-button>

                <icon-button
                  size="sm"
                  role="cancel"
                  @click="item.extensions = item.extensions.filter((e) => e.id)"
                >
                  Annuler
                </icon-button>
              </div>

              <div v-if="!extensionValid" class="loan-actions__alert">
                <b-alert variant="danger" show>
                  <p>
                    La nouvelle date de retour n'est pas valide. La durée minimale d'un retard doit
                    être d'au moins 10 minutes.
                  </p>
                  <p class="loan-extension__bold">
                    Date de retour initiale: {{ initialReturnDate | date }} à
                    {{ initialReturnDate | time }}
                  </p>
                </b-alert>
              </div>
            </b-form>
          </validation-observer>
        </div>
        <div v-else-if="action.status === 'in_process'">
          <p v-if="loanIsCanceled">
            L'emprunt a été annulé. Cette étape ne peut pas être complétée.
          </p>
          <div v-else-if="isBorrower" class="text-center">
            <p>Demande d'extension jusqu'au {{ returnAt | datetime }}.</p>
            <p>
              Merci d'avoir enregistré votre demande d'extension sur la plateforme! Maintenant,
              contactez votre voisin-e pour voir directement avec lui/elle si son véhicule est
              disponible.
            </p>
            <p v-if="item.loanable.owner">{{ item.loanable.owner.user.phone | phone }}</p>

            <blockquote v-if="action.message_for_borrower">
              <user-avatar :user="owner.user" />
              {{ action.message_for_borrower }}
            </blockquote>

            <blockquote v-if="action.comments_on_extension">
              <user-avatar :user="borrower.user" />
              {{ action.comments_on_extension }}
            </blockquote>

            <icon-button
              v-if="canCancelExtension"
              size="sm"
              variant="outline-danger"
              :onclick="cancelExtension"
            >
              Annuler</icon-button
            >
          </div>
          <div v-else>
            <p>
              {{ borrower.user.name }} demande une extension jusqu'au {{ returnAt | datetime }}.
            </p>

            <blockquote v-if="action.comments_on_extension">
              <user-avatar :user="borrower.user" />
              {{ action.comments_on_extension }}
            </blockquote>

            <div class="loan-actions-extension__message_for_borrower text-center mb-3">
              <forms-validated-input
                type="textarea"
                name="message_for_borrower"
                v-model="action.message_for_borrower"
                label="Laissez un message à l'emprunteur (facultatif)"
              />
            </div>

            <div class="loan-actions-extension__buttons text-center">
              <icon-button
                v-if="canAcceptExtension"
                size="sm"
                variant="success"
                class="mx-2"
                :disabled="actionLoading"
                :onclick="completeExtension"
              >
                Accepter
              </icon-button>

              <icon-button
                v-if="canRejectExtension"
                size="sm"
                class="mx-2"
                variant="outline-danger"
                :disabled="actionLoading"
                :onclick="rejectExtension"
              >
                Refuser
              </icon-button>

              <icon-button
                v-if="canCancelExtension"
                size="sm"
                class="mx-2"
                variant="outline-danger"
                :disabled="actionLoading"
                :onclick="cancelExtension"
              >
                Annuler
              </icon-button>
            </div>

            <div class="loan-actions__alert">
              <b-alert variant="warning" show>
                Les informations de l'emprunt peuvent être modifiées jusqu'à 48h après sa
                conclusion. À partir de ce moment, le coût de l'emprunt sera validé avec les détails
                ci-dessus.
              </b-alert>
            </div>
          </div>
        </div>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import FormsValidatedInput from "@/components/Forms/ValidatedInput.vue";
import IconButton from "@/components/shared/IconButton.vue";

import dayjs from "@/helpers/dayjs";

import LoanNextDate from "@/components/Loan/NextDate.vue";
import UserAvatar from "@/components/User/Avatar.vue";

import LoanActionsMixin from "@/mixins/LoanActionsMixin";
import {
  canAcceptExtension,
  canRejectExtension,
  canCancelExtension,
} from "@/helpers/permissions/loans";
import { post, put } from "@/requests/server";

export default {
  name: "LoanActionsExtension",
  mixins: [LoanActionsMixin],
  components: {
    IconButton,
    FormsValidatedInput,
    LoanNextDate,
    UserAvatar,
  },
  data() {
    return {
      canAcceptExtension: canAcceptExtension(this.user, this.item),
      canRejectExtension: canRejectExtension(this.user, this.item),
      canCancelExtension: canCancelExtension(this.user, this.item),
    };
  },
  computed: {
    hasContent() {
      return this.action.status !== "canceled" && this.action.status !== "rejected";
    },
    returnAt: {
      get() {
        return dayjs(this.item.departure_at).add(this.action.new_duration, "minute").toISOString();
      },
      set(val) {
        this.action.new_duration = dayjs(val).diff(this.item.departure_at, "minute");
      },
    },
    zonedReturnAt() {
      return dayjs.atTz(this.returnAt, this.item.loanable.timezone);
    },
    // Dates and times are disabled accounting for the scheduled return time,
    // which also accounts for previously accepted extensions.
    dateIsNotAfterScheduledReturn() {
      return (date) => {
        return dayjs(date).startOfDay().add(1, "day").isSameOrBefore(this.item.actual_return_at);
      };
    },
    timeIsNotAfterScheduledReturn() {
      return (time) => {
        return dayjs(time).isSameOrBefore(this.item.actual_return_at);
      };
    },
    extensionValid() {
      return this.action.new_duration > this.item.actual_duration_in_minutes;
    },
  },
  methods: {
    async createExtension() {
      this.actionLoading = true;
      await post(
        `/loans/${this.action.loan_id}/actions`,
        {
          type: "extension",
          ...this.action,
        },
        {
          cleanupCallback: () => (this.actionLoading = false),
          notifications: { action: "demande d'extension" },
        }
      );

      this.$emit("created");
    },
    async rejectExtension() {
      this.actionLoading = true;
      await put(`/loans/${this.action.loan_id}/extensions/${this.action.id}/reject`, this.action, {
        notifications: { action: "refus d'extension" },
        cleanupCallback: () => (this.actionLoading = false),
      });
      this.$emit("rejected");
    },
    async completeExtension() {
      this.actionLoading = true;

      await put(`/loans/${this.item.id}/extensions/${this.action.id}/complete`, this.action, {
        notifications: { action: "approbation de la demande d'extension" },
        cleanupCallback: () => (this.actionLoading = false),
      });
      this.$emit("completed");
    },
    async cancelExtension() {
      this.actionLoading = true;
      await put(`/loans/${this.item.id}/extensions/${this.action.id}/cancel`, this.action, {
        notifications: { action: "annulation de la demande d'extension" },
        cleanupCallback: () => (this.actionLoading = false),
      });
      this.$emit("canceled");
    },
  },
};
</script>

<style lang="scss">
.loan-extension {
  &__bold {
    font-weight: 600;
  }
}
</style>
