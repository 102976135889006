<template>
  <b-row class="loanable-calendar-legend">
    <b-col md="4" class="loanable-calendar-legend__item">
      <loanable-calendar-month-cell-content
        class="loanable-calendar-legend__icon"
        availability="available"
        current
        >XX</loanable-calendar-month-cell-content
      >
      Disponible
    </b-col>
    <b-col md="4" class="loanable-calendar-legend__item">
      <loanable-calendar-month-cell-content
        class="loanable-calendar-legend__icon"
        availability="partially-available"
        current
        >XX</loanable-calendar-month-cell-content
      >
      Disponibilité limitée
    </b-col>
    <b-col md="4" class="loanable-calendar-legend__item">
      <loanable-calendar-month-cell-content
        class="loanable-calendar-legend__icon"
        availability="unavailable"
        current
        >XX</loanable-calendar-month-cell-content
      >
      Indisponible
    </b-col>
  </b-row>
</template>

<script>
import LoanableCalendarMonthCellContent from "@/components/Loanable/CalendarMonthCellContent.vue";

export default {
  name: "LoanableCalendarLegend",
  components: {
    LoanableCalendarMonthCellContent,
  },
};
</script>

<style lang="scss">
.loanable-calendar-legend {
  .loanable-calendar-legend__item {
    display: flex;
    align-items: center;
  }

  .loanable-calendar-legend__icon {
    width: 3rem;
    height: 3rem;
  }
}
</style>
