<template>
  <b-row no-gutter class="forms-date-time-picker">
    <!--Accessible input -->
    <b-input :value="value" class="sr-only" :id="name" @input="$emit('input', value)"></b-input>

    <b-col col lg="12">
      <forms-date-picker
        :disabled="disabled"
        :disabled-dates="disabledDates"
        :disabled-dates-fct="disabledDatesFct"
        :value="dateValue"
        @input="emitChangeDate"
      />
    </b-col>

    <b-col col lg="12">
      <time-selector
        :disabled="disabled"
        :disabled-times-fct="disabledTimesFct"
        :minute-interval="15"
        :value="timeValue"
        @input="emitChangeTime"
        :timezone="timezone"
      />
    </b-col>
  </b-row>
</template>

<script>
import FormsDatePicker from "@/components/Forms/DatePicker.vue";
import TimeSelector from "@/components/Forms/TimeSelector.vue";
import dayjs from "dayjs";

export default {
  name: "FormsDateTimePicker",
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabledDates: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    disabledDatesFct: {
      type: Function,
      required: false,
      default: () => false,
    },
    disabledTimesFct: {
      type: Function,
      required: false,
      default: () => false,
    },
    value: {
      type: String,
      required: true,
    },
    timezone: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
  },
  components: {
    FormsDatePicker,
    TimeSelector,
  },
  computed: {
    zonedDateTime() {
      return this.timezone ? dayjs.atTz(this.value, this.timezone) : dayjs.utc(this.value);
    },
    dateValue() {
      return this.zonedDateTime.format("YYYY-MM-DD");
    },
    timeValue() {
      // Pass zoneless time
      return dayjs.utc(this.value);
    },
  },
  methods: {
    emitChangeDate(newDate) {
      if (this.disabled) {
        return;
      }

      if (this.timezone) {
        this.$emit(
          "input",
          dayjs
            .tz(`${newDate}T${this.zonedDateTime.format("HH:mm")}`, this.timezone)
            .utc()
            .toISOString()
        );
        return;
      }

      this.$emit("input", `${newDate}T${this.zonedDateTime.format("HH:mm")}`);
    },
    emitChangeTime(value) {
      if (this.disabled) {
        return;
      }

      if (this.timezone) {
        this.$emit("input", value.toISOString());
        return;
      }

      this.$emit("input", value.format("YYYY-MM-DDTHH:mm"));
    },
  },
};
</script>

<style lang="scss">
.forms-date-time-picker {
  .forms-datepicker {
    margin-bottom: 10px;
  }

  .vtimeselector__clear {
    display: none;
  }
}
</style>
