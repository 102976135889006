<template>
  <b-card no-body class="loan-form loan-actions loan-actions-pre_payment">
    <b-card-header
      header-tag="header"
      role="tab"
      class="loan-actions__header"
      v-b-toggle.loan-actions-pre_payment
    >
      <h2>
        <svg-waiting v-if="action.status === 'in_process' && !loanIsCanceled" />
        <svg-check v-else-if="action.status === 'completed'" />
        <svg-danger v-else-if="action.status === 'canceled' || loanIsCanceled" />
        {{ item.is_free ? "Contribution volontaire" : "Prépaiement" }}
      </h2>

      <!-- Canceled loans: current step remains in-process. -->
      <span v-if="action.status === 'in_process' && loanIsCanceled">
        Emprunt annulé &bull; {{ item.canceled_at | datetime }}
      </span>
      <span v-else-if="action.status == 'in_process'"> En attente </span>
      <span v-else-if="action.status === 'completed'">
        Complété &bull; {{ action.executed_at | datetime }}
      </span>
      <span v-else-if="action.status === 'canceled'">
        Annulé &bull; {{ action.executed_at | datetime }}
      </span>
    </b-card-header>

    <b-collapse
      id="loan-actions-pre_payment"
      role="tabpanel"
      accordion="loan-actions"
      :visible="open"
    >
      <b-card-body>
        <div class="loan-actions-pre_payment__description" v-if="!action.executed_at">
          <!-- Action is not completed -->
          <div v-if="action.status === 'in_process' && loanIsCanceled">
            <p>L'emprunt a été annulé. Cette étape ne peut pas être complétée.</p>
          </div>

          <div v-else-if="isBorrower">
            <loan-donations
              :loan="item"
              :onpaid="completePrepayment"
              payment-label="continuer"
              @input="item.platform_tip = $event"
            />
          </div>
          <div v-else-if="isCoownerOrOwner">
            <p>{{ item.borrower.user.name }} doit compléter l'étape de pré-paiement.</p>
          </div>
        </div>
        <div v-else-if="action.status === 'canceled'">
          <!-- Action is cancled -->
          <p>Cette étape a été annulée.</p>
        </div>
        <div v-else>
          <!-- Action is completed -->
          <p>Cette étape est complétée.</p>
        </div>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import FormsValidatedInput from "@/components/Forms/ValidatedInput.vue";
import TinyInvoice from "@/components/Invoice/TinyInvoice.vue";
import Donation from "@/components/shared/Donation.vue";
import DonationSlider from "@/components/shared/DonationSlider.vue";
import IconButton from "@/components/shared/IconButton.vue";
import LoanDonations from "@/components/shared/LoanDonations.vue";
import UserAddCreditBox from "@/components/User/AddCreditBox.vue";
import PaymentDialog from "@/components/User/PaymentDialog.vue";
import ProvisionModal from "@/components/User/ProvisionModal.vue";

import locales from "@/locales";

import LoanActionsMixin from "@/mixins/LoanActionsMixin";
import { put } from "@/requests/server";

export default {
  name: "LoanActionsPrePayment",
  mixins: [LoanActionsMixin],
  components: {
    LoanDonations,
    IconButton,
    ProvisionModal,
    DonationSlider,
    PaymentDialog,
    Donation,
    FormsValidatedInput,
    TinyInvoice,
    UserAddCreditBox,
  },
  methods: {
    async completePrepayment() {
      await put(
        `/loans/${this.item.id}/prepayment/complete`,
        { ...this.action, platform_tip: this.item.platform_tip },
        {
          notifications: { action: "prépaiement" },
        }
      );
      this.$emit("completed");
    },
  },
  i18n: {
    messages: {
      en: {
        bill_items: locales.en.bill_items,
        ...locales.en.loans,
        ...locales.en.forms,
      },
      fr: {
        bill_items: locales.fr.bill_items,
        ...locales.fr.loans,
        ...locales.fr.forms,
      },
    },
  },
};
</script>

<style lang="scss">
@import "~bootstrap/scss/mixins/breakpoints";
.loan-actions-pre_payment {
  .trip-cost-details {
    @include media-breakpoint-down(xs) {
      font-size: 0.9rem;
    }
  }

  .trip-details {
    margin: 0 auto;
    line-height: 1.5;

    th,
    td {
      padding: 0 0.75rem;
      font-weight: normal;
    }

    tr:not(.header):not(.last) th,
    tr:not(.header):not(.last) td {
      border-bottom: 1px dotted lightgray;
    }

    .header th {
      font-weight: bold;
      font-size: 1.2rem;
      padding-top: 0.5rem;
    }
  }

  .trip-details__total {
    border-top: 1px solid black;
  }
}
</style>
