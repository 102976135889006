var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout-page',{attrs:{"name":"community-overview","wide":""}},[_c('b-container',{staticClass:"h-100 position-relative",attrs:{"fluid":""}},[_c('b-row',{staticClass:"h-100"},[(!_vm.fullscreen)?_c('b-col',{staticClass:"community-overview__sidebar",attrs:{"xl":"3"}},[_c('b-form-group',{staticClass:"mb-3"},[_c('div',{staticClass:"d-flex"},[_c('place-autocomplete-input',{ref:"placeAutocomplete",staticClass:"form-control form-control-sm",attrs:{"options":{
                language: 'fr',
                fields: ['geometry', 'formatted_address'],
                componentRestrictions: { country: 'ca' },
              },"placeholder":"Chercher un endroit"},on:{"place-selected":_vm.setSearchLocation},model:{value:(_vm.placeSearchText),callback:function ($$v) {_vm.placeSearchText=$$v},expression:"placeSearchText"}}),(_vm.placeSearchText)?_c('icon-button',{attrs:{"variant":"link","size":"sm","icon":"x"},on:{"click":_vm.clearSearchInput}}):_vm._e()],1)]),(_vm.isAdmin)?_c('icon-button',{staticClass:"mb-3",attrs:{"disabled":!_vm.userPositions,"pressed":_vm.showMembers,"variant":"ghost-secondary","icon":"ui-radios-grid"},on:{"click":function($event){_vm.showMembers = !_vm.showMembers}}},[_vm._v(" Nuage de points des membres ")]):_vm._e(),(_vm.isLoggedIn)?_c('h4',{directives:[{name:"b-toggle",rawName:"v-b-toggle:toggleable",value:('collapse_filters'),expression:"'collapse_filters'",arg:"toggleable"}],staticClass:"section-toggle mb-0"},[_c('b-icon',{attrs:{"font-scale":"0.75","icon":"chevron-right"}}),_vm._v(" Filtres "),(_vm.filterCount > 0)?_c('b-badge',{attrs:{"variant":"success"}},[_vm._v(_vm._s(_vm.filterCount))]):_vm._e()],1):_vm._e(),(_vm.isLoggedIn)?_c('div',{staticClass:"community-overview__filters"},[_c('b-collapse',{attrs:{"id":"collapse_filters","accordion":"collapse_filters"}},[_c('b-row',_vm._l((_vm.filterDefinitions),function(filter,filterKey){return _c('b-col',{key:filterKey,attrs:{"cols":"12","xl":"6"}},[(
                    (_vm.canFilterCommunities || !filter.filtersCommunities) &&
                    (_vm.isAdmin || !filter.adminOnly)
                  )?_c('b-form-group',{attrs:{"label-size":"sm","label":filter.label}},[_c('filter-input',{attrs:{"name":filterKey,"type":filter.type,"options":filter.options,"value":_vm.filters[filterKey]},on:{"input":function($event){_vm.filters[filterKey] = $event;
                      _vm.clearSelectedIfNotShown();}}})],1):_vm._e()],1)}),1)],1)],1):_vm._e(),_c('div',{staticClass:"community-overview__communities mt-3 d-xl-block d-none"},[_c('h4',[_vm._v("Communautés")]),_c('transition-group',{attrs:{"name":"community-list"}},_vm._l((_vm.communities),function(community){return _c('community-overview-card',{key:community.id,attrs:{"community":community,"selected":_vm.selectedCommunityIds.includes(community.id),"muted":_vm.selectedCommunityIds.length > 0 && !_vm.selectedCommunityIds.includes(community.id)},on:{"click":function($event){_vm.selectedCommunityIds = [community.id]}}})}),1)],1)],1):_vm._e(),_c('b-col',{staticClass:"map-container",attrs:{"xl":_vm.fullscreen ? 12 : 9}},[_c('loanable-map',_vm._b({ref:"map",attrs:{"loanables":_vm.loanables,"communities":_vm.communities,"polygons-selectable":"","polygon-options":_vm.polygonOptions,"selected-polygon-options":_vm.selectedPolygonOptions,"selectedCommunityIds":_vm.selectedCommunityIds,"no-recenter":!!_vm.center,"padding-client-width-max":_vm.fullscreen ? Number.MAX_VALUE : 1200,"padding":{
            y: 150,
          },"heatmap-data":_vm.showMembers ? _vm.userPositions : null},on:{"select-communities":function($event){_vm.selectedCommunityIds = $event},"select-loanable":_vm.selectLoanable},scopedSlots:_vm._u([{key:"loanable-details",fn:function(ref){
          var loanable = ref.loanable;
return [_c('loanable-details',{attrs:{"fixed-width":"","loanable":loanable,"basic":""}})]}},{key:"top-right-button",fn:function(){return [(_vm.loading)?_c('layout-loading',{attrs:{"with-button":""}}):_vm._e()]},proxy:true}])},'loanable-map',_vm.mapOptionalProps,false))],1)],1),(_vm.firstSelectedCommunity)?_c('community-overview-card',{staticClass:"floating-community-overview-card",class:{ 'd-xl-none': !_vm.fullscreen },attrs:{"community":_vm.firstSelectedCommunity}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }