<template>
  <layout-page name="admin" :fluid="isFluid ? true : 'lg'">
    <div class="position-relative">
      <div class="toggles d-none d-lg-block" :style="{ 'view-transition-name': 'admin-toggles' }">
        <icon-button
          :class="{ 'ml-3': showSidebar }"
          class="mr-1 d-inline-block"
          variant="ghost-secondary"
          size="sm"
          :pressed="showSidebar"
          icon="layout-sidebar"
          @click="showSidebar = !showSidebar"
        />
        <icon-button
          variant="ghost-secondary"
          size="sm"
          :pressed="isFluid"
          @click="isFluid = !isFluid"
          :icon="isFluid ? 'fullscreen-exit' : 'arrows-fullscreen'"
        />
      </div>
      <b-row class="flex-nowrap">
        <b-col
          lg="2"
          class="admin__sidebar d-none d-lg-block"
          v-if="showSidebar"
          :style="{ 'view-transition-name': 'sidebar' }"
        >
          <b-nav vertical>
            <admin-sidebar item-component="b-nav-item" section-component="b-nav-item" />
          </b-nav>
        </b-col>

        <b-col class="admin__view" :style="{ 'view-transition-name': 'content' }">
          <keep-alive :include="keepAliveComponents">
            <router-view />
          </keep-alive>
        </b-col>
      </b-row>
    </div>
  </layout-page>
</template>

<script>
import AdminSidebar from "@/components/Admin/Sidebar.vue";
import IconButton from "@/components/shared/IconButton.vue";

import Authenticated from "@/mixins/Authenticated";
import UserMixin from "@/mixins/UserMixin";

export default {
  name: "Admin",
  mixins: [Authenticated, UserMixin],
  components: { IconButton, AdminSidebar },
  data: () => ({
    isFluid: false,
    showSidebar: true,
  }),
  computed: {
    hasSingleAdminCommunity() {
      let hasOneAdminCommunity = false;
      for (const community of this.$store.state.user.communities) {
        if (community.role === "admin") {
          if (hasOneAdminCommunity) {
            return false;
          } else {
            hasOneAdminCommunity = true;
          }
        }
      }
      return hasOneAdminCommunity;
    },
    keepAliveComponents() {
      // Keep nothing alive once admin disconnects
      if (!this.user) {
        return [];
      }

      const keepAliveViews = [
        "AdminUsers",
        "AdminPadlocks",
        "AdminLoanables",
        "AdminLoans",
        "AdminCommunities",
        "AdminInvoices",
        "AdminDashboard",
        "AdminInvitations",
      ];

      // For single community admins, we can keep the /admin/communities/:id page alive
      // since they only have that one.
      if (!this.isGlobalAdmin && this.hasSingleAdminCommunity) {
        keepAliveViews.push("AdminCommunity");
      }
      return keepAliveViews;
    },
  },
};
</script>

<style lang="scss">
.admin {
  &__buttons {
    margin-bottom: 0.5rem;
    text-align: right;
    line-height: 48px;
  }

  &__selection {
    margin-bottom: 0.5rem;
    text-align: left;
  }
  .toggles {
    position: absolute;
    top: -2rem;
  }

  .page__content {
    padding-top: 45px;
    padding-bottom: 45px;

    .admin__sidebar.col {
      flex: 0 1 12vw;
    }

    .admin__view {
      // This prevents the column from overflowing, since the row has no-wrap.
      min-width: 0;
      h1 {
        margin-bottom: 0.5rem;
      }
    }
  }
}
</style>
