<template>
  <datepicker
    :class="{
      'forms-datepicker': true,
      'is-valid': state === true,
      'is-invalid': state === false,
      'fit-width': fitWidth,
    }"
    :inline="inline"
    :clear-button="clearButton"
    :input-class="formControlClass"
    :format="format"
    :initial-view="initialView"
    :open-date="openDate"
    :language="language"
    :disabled-dates="disabledDatesCombined"
    :disabled-dates-fct="disabledDatesFct"
    :disabled="disabled"
    :value="dateValue"
    @selected="emitInput($event)"
    ref="datePicker"
  />
</template>

<script>
import Datepicker from "vuejs-datepicker";
import { fr } from "vuejs-datepicker/dist/locale";
import dayjs from "dayjs";

export default {
  name: "FormsDatePicker",
  components: { Datepicker },
  props: {
    clearButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabledDates: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    disabledDatesFct: {
      type: Function,
      required: false,
      default: () => false,
    },
    format: {
      type: [String, Function],
      required: false,
      default() {
        return (val) => {
          if (!val) {
            return "";
          }

          return this.$dayjs(val).format("D MMMM YYYY").toLowerCase();
        };
      },
    },
    initialView: {
      type: String,
      required: false,
      default: "day",
    },
    // Initial date to show on the calendar.
    initialDate: {
      type: Date,
      required: false,
      default: null,
    },
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
    // Force this date to be shown.
    openDate: {
      type: Date,
      required: false,
      default() {
        return new Date();
      },
    },
    selected: {
      type: Function,
      required: false,
      default() {
        return () => {};
      },
    },
    state: {
      type: Boolean,
      required: false,
      default: null,
    },
    value: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      required: false,
    },
    fitWidth: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  mounted() {
    if (this.initialDate) {
      this.$refs.datePicker.setPageDate(this.initialDate);
    }
  },
  computed: {
    // Combine disabledDates and disabledDatesFct into the proper format for
    // vuejs-datepicker
    disabledDatesCombined() {
      return {
        ...this.disabledDates,
        customPredictor: this.disabledDatesFct,
      };
    },
    dateValue() {
      if (!this.value || this.value === "null") {
        return null;
      }

      return dayjs(this.value).toDate();
    },
    language() {
      return fr;
    },
    formControlClass() {
      if (!this.size) {
        return "form-control";
      }
      return "form-control form-control-" + this.size;
    },
  },
  methods: {
    emitInput(value) {
      if (!this.disabled) {
        this.$emit("input", value ? value.format("YYYY-MM-DD") : value);
      }
    },
    isDisabledDate(date) {
      if (!this.disabledDates) {
        return false;
      }

      if (this.disabledDates.dates) {
        this.disabledDates.dates.forEach((d) => {
          if (date.isSame(d, "day")) {
            return true;
          }
        });
      }

      if (this.disabledDates.to && this.disabledDates.to && date.isBefore(this.disabledDates.to)) {
        return true;
      }

      if (
        this.disabledDates.from &&
        this.disabledDates.from &&
        date.isAfter(this.disabledDates.from)
      ) {
        return true;
      }

      if (this.disabledDates.ranges) {
        this.disabledDates.ranges.forEach((range) => {
          if (range.from && range.to && date.isBefore(range.to) && date.isAfter(range.from)) {
            return true;
          }
        });
      }

      if (this.disabledDates.days && this.disabledDates.days.indexOf(date.toDate()) !== -1) {
        return true;
      }

      if (
        this.disabledDates.daysOfWeek &&
        this.disabledDates.daysOfWeek.indexOf(date.day()) !== -1
      ) {
        return true;
      }

      if (
        this.disabledDates.daysOfMonth &&
        this.disabledDates.daysOfMonth.indexOf(date.date()) !== -1
      ) {
        return true;
      }

      return typeof this.disabledDatesFct === "function" && this.disabledDatesFct(date);
    },

    closestOption(date) {
      const day = dayjs(date);
      if (this.isDisabledDate(day)) {
        const maxIter = 100;
        for (let i = 1; i < maxIter; i++) {
          const futureDay = day.clone().add(i, "day");
          if (!this.isDisabledDate(futureDay)) {
            this.emitInput(futureDay);
            return;
          }

          const pastDate = day.clone().subtract(i, "day");
          if (!this.isDisabledDate(pastDate)) {
            this.emitInput(pastDate);
            return;
          }
        }
        // couldn't find a valid date!!
        throw new Error("Couldn't find a valid date");
      }
    },
  },

  watch: {
    value(val) {
      this.closestOption(val);
    },
  },
};
</script>

<style lang="scss">
@import "~bootstrap/scss/mixins/breakpoints";

.forms-datepicker {
  input.form-control[readonly]:not([disabled]) {
    background-color: $white;
  }

  &.fit-width .vdp-datepicker__calendar {
    width: 100%;
  }

  .vdp-datepicker__calendar {
    @include media-breakpoint-down(sm) {
      min-width: 300px;
    }
  }

  .vdp-datepicker__clear-button {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 8px;
    transition: transform $one-tick ease-in-out;

    &:hover {
      transform: scale(1.4);
    }
  }
}
</style>
