import { get, put } from "@/requests/server";
import router from "@/router";

export default {
  namespaced: true,
  state: {
    data: null,
    error: null,
    loaded: false,
  },
  mutations: {
    data(state, data) {
      state.data = data;
    },
    error(state, error) {
      state.error = error;
    },
    loaded(state, loaded) {
      state.loaded = loaded;
    },
    cancelToken(state, cancelToken) {
      state.cancelToken = cancelToken;
    },
  },
  actions: {
    async buyCredit({ commit }, { amount, paymentMethodId }) {
      commit("loaded", false);
      const { data } = await put(
        "/auth/user/balance",
        {
          amount,
          payment_method_id: paymentMethodId,
        },
        {
          requestOptions: { cancelId: "add-to-balance" },
          notifications: { action: "ajout au solde" },
        }
      );

      commit("data", data);
      commit("loaded", true);
    },
    async claimCredit({ commit }) {
      commit("loaded", false);
      const { data } = await put(
        "/auth/user/claim",
        {},
        {
          requestOptions: { cancelId: "claim-balance", expects: [429] },
          notifications: { action: "demande de transfer" },
        }
      );
      commit("data", data);

      commit("loaded", true);
    },
    async mandate({ commit, dispatch }, { mandatedUserId }) {
      const response = await get(`/auth/password/mandate/${mandatedUserId}`);
      await commit("setTokens", { token: response.data }, { root: true });
      await dispatch("loadUser", {}, { root: true });

      router.push("/app");
    },
  },
};
