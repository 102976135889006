<template>
  <component
    :disabled="disabled"
    :is="type"
    :variant="variant"
    v-b-modal="`validation-modal-${id}`"
    v-bind="$attrs"
  >
    <slot id="label">
      <b-spinner small class="mr-2" v-if="loading" />
      <b-icon class="mr-2" v-else-if="icon" :icon="icon" /> {{ capitalize(label) }}
    </slot>
    <b-modal
      :id="`validation-modal-${id}`"
      centered
      :ok-title="label ? capitalize(label) : 'Oui'"
      cancel-title="Annuler"
      button-size="sm"
      @ok="performAction"
      hide-header
      @hidden="$emit('hidden')"
      :ok-variant="variant"
      ref="modal"
      cancel-variant="outline-secondary"
    >
      <slot name="question" :hide="() => $refs.modal.hide()">
        {{ question }}
      </slot>
    </b-modal>
  </component>
</template>

<script>
import { capitalize } from "@/helpers/filters";

let id = 0;
export default {
  name: "ValidatedButton",
  props: {
    question: {
      type: String,
      default: "",
    },
    action: {
      type: Function,
      default: async () => {},
    },
    icon: {
      type: String,
      default: null,
    },
    variant: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: "b-button",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // This is needed in case there are more than one modal with the same name, to avoid all of
      // them opening at the same time.
      id: id++,
      loading: false,
    };
  },
  methods: {
    capitalize,
    async performAction() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      try {
        await this.action();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
