<template>
  <b-card
    no-body
    class="loan-form loan-actions loan-actions-handover loan-actions-handover-self-service"
  >
    <b-card-header
      header-tag="header"
      role="tab"
      class="loan-actions__header"
      v-b-toggle.loan-actions-handover-self-service
    >
      <b-row>
        <b-col>
          <h2>
            <svg-waiting v-if="action.status === 'in_process' && !loanIsCanceled" />
            <svg-check v-else-if="action.status === 'completed'" />
            <svg-danger v-else-if="action.status === 'canceled' || loanIsCanceled" />
            Informations avant de partir
          </h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <span v-if="loanIsCanceled">
            Emprunt annulé &bull; {{ item.canceled_at | datetime }}
          </span>
          <span v-else-if="action.status === 'in_process'"> En attente </span>
          <span v-else-if="action.status === 'completed'">
            Complété &bull; {{ action.executed_at | datetime }}
          </span>
        </b-col>
      </b-row>
    </b-card-header>

    <b-collapse
      id="loan-actions-handover-self-service"
      role="tabpanel"
      accordion="loan-actions"
      :visible="open"
    >
      <b-card-body>
        <div v-if="action.status === 'in_process' && loanIsCanceled">
          <b-row>
            <b-col>
              <b-alert show variant="danger">
                <p>L'emprunt a été annulé. Cette étape ne peut pas être complétée.</p>
              </b-alert>
            </b-col>
          </b-row>
        </div>
        <div v-if="!loanIsCanceled" class="loan-actions-handover-self-service__text">
          <b-row>
            <b-col>
              <template v-if="isBorrower">
                <p>
                  Merci d'avoir enregistré votre demande d'emprunt sur la plateforme! Si vous avez
                  besoin d'aide durant votre emprunt, n'hésitez pas à contacter une personne
                  responsable!
                </p>

                <b-row class="mb-3" :class="{ 'justify-content-center': contacts.length === 1 }">
                  <b-col cols="12" xl="6" v-for="contact of contacts" :key="contact.user.id">
                    <user-blurb show-phone :user="contact.user" :description="contact.title" />
                  </b-col>
                </b-row>
              </template>

              <b-alert show variant="success" v-if="instructions">
                <div class="alert-heading">
                  <h4>Instructions du propriétaire pour l'utilisation du véhicule</h4>
                </div>
                <div class="owner-instructions-text">
                  <p>{{ instructions }}</p>
                </div>
              </b-alert>
              <b-alert show variant="info">
                <div class="alert-heading"><h4>Important</h4></div>
                <div>
                  <p>
                    Assurez-vous d'avoir lu
                    <a :href="item.community.starting_guide_url" target="_blank">
                      le guide de départ</a
                    >
                    avant votre emprunt.
                  </p>
                  <p>
                    {{
                      $t("views.loan.self_service_free_after_use", [
                        zonedTimes.returnAt.format("HH:mm"),
                        zonedTimes.returnAt.format("D MMMM"),
                        $t(`loanables.types_with_article.${item.loanable.type}`),
                      ])
                    }}
                  </p>
                </div>
              </b-alert>
              <b-alert show variant="warning" v-if="item.loanable.has_padlock">
                <div class="alert-heading"><h4>Cadenas intelligent</h4></div>
                <div>
                  <p>
                    Il faut attendre au minimum 5 minutes après avoir fait la réservation pour
                    pouvoir débarrer le cadenas intelligent sur le véhicule!
                  </p>
                  <p>
                    Pour garder l'accès au cadenas intelligent, prolongez votre emprunt avec le
                    bouton «&nbsp;Signaler un retard&nbsp;».
                  </p>
                </div>
              </b-alert>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <p class="text-center"><strong>Bonne route!</strong></p>
            </b-col>
          </b-row>

          <div
            v-if="!action.executed_at && !loanIsCanceled"
            class="loan-actions-handover-self-service text-center"
          >
            <icon-button
              size="sm"
              variant="success"
              :disabled="startsInTheFuture"
              :loading="actionLoading"
              @click="completeHandover"
            >
              Terminer l'emprunt
            </icon-button>
            <br />
            <div v-if="startsInTheFuture">
              <small class="text-muted">
                {{ startInFutureText }}
              </small>
            </div>
          </div>
        </div>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import FormsImageUploader from "@/components/Forms/ImageUploader.vue";
import FormsValidatedInput from "@/components/Forms/ValidatedInput.vue";
import IconButton from "@/components/shared/IconButton.vue";

import UserAddCreditBox from "@/components/User/AddCreditBox.vue";
import UserAvatar from "@/components/User/Avatar.vue";
import { shortDate, time } from "@/helpers/filters";

import LoanActionsMixin from "@/mixins/LoanActionsMixin";
import UserBlurb from "@/components/User/UserBlurb.vue";
import { put } from "@/requests/server";
import { getZonedTimes } from "@/helpers/loanTimezones";

export default {
  name: "LoanActionsOngoing",
  mixins: [LoanActionsMixin],
  components: {
    IconButton,
    UserBlurb,
    FormsImageUploader,
    FormsValidatedInput,
    UserAddCreditBox,
    UserAvatar,
  },
  computed: {
    zonedTimes() {
      return getZonedTimes(this.item);
    },
    startsInTheFuture() {
      return this.$second.isBefore(this.item.departure_at, "minute");
    },
    startInFutureText() {
      return `Il sera possible de terminer l'emprunt après l'heure du début de la
                        réservation (${shortDate(this.zonedTimes.departureAt)} à
                        ${time(this.zonedTimes.departureAt)}${
                          this.zonedTimes.differsFromCurrentTimezone
                            ? " " + this.zonedTimes.timezoneAbbrev
                            : ""
                        }).`;
    },
    contacts() {
      return [
        { user: this.item.loanable.owner.user, title: "Propriétaire" },
        ...this.item.loanable.coowners.filter((c) => c.show_as_contact),
      ];
    },
    instructions() {
      return this.item.loanable.trusted_borrower_instructions ?? this.item.loanable.instructions;
    },
  },
  methods: {
    time,
    async completeHandover() {
      this.actionLoading = true;

      await put(`/loans/${this.item.id}/handover/complete`, this.action, {
        notifications: { action: "fin de l'emprunt" },
        cleanupCallback: () => (this.actionLoading = false),
      });
      this.$emit("completed");
    },
  },
};
</script>

<style lang="scss"></style>
