<template>
  <div class="register-step">
    <b-pagination-nav
      v-if="currentPage < 4"
      v-bind:value="currentPage"
      :number-of-pages="3"
      pills
      align="center"
      use-router
      :hide-goto-end-buttons="true"
      disabled
    >
      <template v-slot:page="{ page, active }">
        <span v-if="page < currentPage" class="checked">
          <b-icon icon="check" font-scale="2" />
        </span>
        <span v-else>{{ page }}</span>
      </template>
    </b-pagination-nav>

    <div v-if="item && currentPage === 2">
      <h3>Profil</h3>

      <p class="text-center">Mettez votre voisinage en confiance.</p>
      <profile-form
        v-if="item"
        :form="form"
        :user="item"
        :loading="savingProfile"
        @submit="saveProfile"
      >
        <forms-validated-input
          name="accept_conditions"
          :label="($t('users.fields.accept_conditions') + '*') | capitalize"
          :rules="form.general.accept_conditions.rules"
          type="checkbox"
          :placeholder="placeholderOrLabel('accept_conditions', 'users') | capitalize"
          v-model="item.accept_conditions"
        />
      </profile-form>
      <layout-loading v-else />
    </div>

    <layout-loading v-if="!item && currentPage == 2" />

    <div v-if="currentPage == 3" class="register-step__community">
      <template v-if="itemLoaded">
        <proof-form
          class="mt-4"
          :user="item"
          skip="/register/4"
          :loading="proofLoading"
          @submit="submitUserProofs"
        />
      </template>
      <layout-loading v-else />
    </div>

    <div v-if="currentPage === 4" class="register-step__reasons-why">
      <img src="/donation-bike_288.jpg" alt="Enfants dans une remorque LocoMotion" />
      <p class="title">Bravo! Vous faites maintenant partie de l'aventure.</p>
      <p class="subtitle">Merci d'avancer avec nous vers plus de partage dans votre communauté!</p>

      <b-btn variant="primary" to="/app">On avance!</b-btn>
    </div>
  </div>
</template>

<script>
import Authenticated from "@/mixins/Authenticated";
import Notification from "@/mixins/Notification";
import UserMixin from "@/mixins/UserMixin";

import ResidencyProofForm from "@/components/User/ProofForm.vue";
import ProfileForm from "@/components/Profile/ProfileForm.vue";

import FormsValidatedInput from "@/components/Forms/ValidatedInput.vue";
import FormLabelsMixin from "@/mixins/FormLabelsMixin";
import FormMixin from "@/mixins/FormMixin";

import { extractErrors } from "@/helpers";

import Driving from "@/assets/svg/driving.svg";
import Lend from "@/assets/svg/home-lend.svg";
import Biking from "@/assets/svg/biking.svg";
import ProofForm from "@/components/User/ProofForm.vue";
import LayoutLoading from "@/components/Layout/Loading.vue";
import { post } from "@/requests/server";

export default {
  name: "RegisterStep",
  mixins: [Authenticated, FormLabelsMixin, FormMixin, Notification, UserMixin],
  components: {
    LayoutLoading,
    ProofForm,
    ResidencyProofForm,
    FormsValidatedInput,
    ProfileForm,
    "svg-driving": Driving,
    "svg-lend": Lend,
    "svg-biking": Biking,
  },
  data() {
    return {
      proofLoading: false,
      // We do not want to submit an empty community array here, since it would
      // overwrite the one we automatically assign on user relocation
      ignoreFields: ["communities"],
      savingProfile: false,
    };
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.step === "4") {
      // Ensure we reload the fully registered user at the last step before
      // showing on the dashboard.
      this.$store.dispatch("loadUser");
    }
    next();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.isLoggedIn) {
        // Has not finalized his account creation
        if (!vm.isRegistered) {
          if (vm.$route.path !== "/register/2") {
            vm.$router.replace("/register/2");
          }
        }
      }
    });
  },
  props: {
    id: {
      required: false,
      default: "me",
    },
    step: {
      type: String,
      required: true,
    },
  },
  computed: {
    currentPage() {
      const stepIndex = parseInt(this.step, 10);

      if (Number.isNaN(stepIndex)) {
        return 1;
      }

      return stepIndex;
    },
    invitation() {
      return this.$store.state.invitation;
    },
    hasInvitation() {
      return this.invitation.code && this.invitation.id;
    },
    hasUnapprovedCommunities() {
      return this.item.communities.filter((c) => !c.approved_at).length > 0;
    },
  },
  methods: {
    async saveProfile() {
      this.savingProfile = true;

      try {
        await this.submit();

        if (this.hasInvitation) {
          await this.$store.dispatch("invitation/accept");
        } else if (this.item.email_verified_at) {
          // TODO: create a separate endpoint for completing user registration with invitation acceptation
          await this.$store.dispatch("invitation/acceptOnRegistration");
        }

        // invitations may have added communities, so we merge them back from the current user
        this.$store.commit("users/mergeItem", {
          communities: this.user.communities,
        });
      } finally {
        this.savingProfile = false;
      }

      if (this.hasUnapprovedCommunities) {
        // Go to "Submit proof of residency"
        await this.$router.push("/register/3");
      } else {
        await this.$router.push("/register/4");
      }
    },
    async submitUserProofs() {
      try {
        this.proofLoading = true;
        await this.submit(false);
        this.$router.push("/register/4");
      } catch (e) {
        if (e.request) {
          switch (e.request.status) {
            case 422:
            default:
              this.$store.commit("addNotification", {
                content: extractErrors(e.response.data).join(", "),
                title: "Erreur d'inscription",
                variant: "danger",
                type: "register",
              });
          }
        }
      } finally {
        this.proofLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.register-step {
  h3,
  h4 {
    text-align: center;
  }

  h3 {
    margin-top: 30px;
    font-size: 26px;
    font-weight: 600;
  }

  h4 {
    color: grey;
    font-size: 20px;
    margin: 20px 0;
    font-weight: 400;
  }

  &__completed__button {
    text-align: center;
  }

  &__reasons-why {
    text-align: center;

    // Match dashboard jumbotron styles
    .title {
      font-size: 2rem;
      font-weight: 700;
    }
    .subtitle {
      font-size: 1.25rem;
      font-weight: 300;
      margin-bottom: 2rem;
    }

    img {
      border-radius: 100%;
      padding: 2rem;
      max-width: 100%;
    }
  }
}
</style>
