import { render, staticRenderFns } from "./UserBlurb.vue?vue&type=template&id=16cff2ae&scoped=true&"
import script from "./UserBlurb.vue?vue&type=script&lang=js&"
export * from "./UserBlurb.vue?vue&type=script&lang=js&"
import style0 from "./UserBlurb.vue?vue&type=style&index=0&id=16cff2ae&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16cff2ae",
  null
  
)

export default component.exports