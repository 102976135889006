import Loan from "@/views/Loan.vue";

export default [
  {
    path: "/loans/:id",
    component: Loan,
    props: true,
    meta: {
      auth: true,
      slug: "loans",
      title: "titles.loan",
      data: {
        loans: {
          options: {},
        },
      },
    },
  },
];
