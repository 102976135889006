<template>
  <div class="simple-map-wrapper">
    <div class="simple-map" ref="map"></div>
  </div>
</template>

<script>
import { loader, mapId } from "@/helpers/googleMaps";

export default {
  name: "simple-map",
  mounted() {
    this.initMap();
  },
  props: {
    polygons: {
      type: Array,
      required: true,
    },
    polygonOptions: {
      type: Object,
      default: () => ({
        fillColor: "#16a59e",
        fillOpacity: 0.25,
        strokeOpacity: 0,
        zIndex: 2,
        clickable: false,
      }),
    },
    center: {
      type: Object,
      default: () => null,
    },
    zoom: {
      type: Number,
      default: 15,
    },
  },
  data() {
    return {
      map: null,
      initialized: false,
      mapOptions: {
        clickableIcons: false,
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        maxZoom: 19,
        zoom: this.zoom,
      },
      coreApi: null,
      mapsApi: null,
      markerApi: null,
      geometryApi: null,
    };
  },
  methods: {
    async initMap() {
      const { Map, Polygon } = await loader.importLibrary("maps");

      this.map = new Map(this.$refs.map, {
        ...this.mapOptions,
        center: this.center || { lat: 45.53748, lng: -73.60145 },
        mapId,
        gestureHandling: "greedy",
      });

      if (this.polygons) {
        new Polygon({ ...this.polygonOptions, paths: this.polygons, map: this.map });

        if (!this.center) {
          const { LatLngBounds } = await loader.importLibrary("core");

          const bounds = new LatLngBounds();

          for (const polygon of this.polygons) {
            for (const point of polygon) {
              bounds.extend(point);
            }
          }

          this.map.fitBounds(bounds);
        }
      }
    },
  },
  watch: {
    center(newCenter) {
      if (this.map && newCenter) {
        this.map.panTo(newCenter);
      }
    },
  },
};
</script>

<style lang="scss"></style>
