<template>
  <paginated-table
    endpoint="communities"
    id="admin-communities-table"
    :fetch-params="{ for: 'admin' }"
    :columns="columns"
    :label="$tc('community', 2)"
    :extra-filters="extraFilters"
    :extra-data="['users_count', 'loans_count', 'invitations_count']"
    ref="table"
    sync-filters-with-url
    row-link
  >
    <template #head-buttons>
      <icon-button v-if="canCreateCommunity" :to="`/admin/communities/new`" role="add">
        {{ $t("create_community") | capitalize }}
      </icon-button>
    </template>

    <template #cell(actions)="{ item }">
      <admin-list-actions
        slug="communities"
        :item-name="item.name"
        :item="item"
        @change="$refs.table.refresh"
        :destroy-params="destroyParams"
        @destroy-hidden="destroyParams = initialDestroyParams()"
      >
        <template #destroy-question="{ hide }">
          <div class="communities-destroy-form">
            <p>
              <strong>Êtes-vous sûr-e de vouloir archiver {{ item.name }}?</strong>
            </p>

            <forms-validated-input
              v-if="item.users_count || item.loans_count || item.invitations_count"
              class="mt-2"
              :value="destroyParams.merge_destination"
              type="relation"
              name="merge_destination"
              @relation="
                (community) => {
                  if (!community) {
                    destroyParams = initialDestroyParams();
                  } else {
                    destroyParams.merge_destination = community.id;
                  }
                }
              "
              :query="{
                slug: 'communities',
                value: 'id',
                text: 'name',
                params: {
                  '!id': item.id,
                },
              }"
              label="Transférer les données vers une autre communauté"
              description="Ceci est optionnel, mais peut être désirable pour automatiquement
                 transférer le membrariat ou préserver les statistiques. Sans transfert,
                 les emprunts et historique de membrariat seront conservés tel quel (en lien avec
                 la communautée archivée), mais pourraient être plus difficilement accessibles."
            />

            <b-form-group
              v-if="item.loans_count"
              description="Ceci est purement pour des fins statistiques. Les propriétaires
                               des véhicules ne seront pas ajoutés dans l'autre communauté."
            >
              <b-form-checkbox
                v-model="destroyParams.merge_loans"
                :disabled="!destroyParams.merge_destination"
              >
                Transférer les
                <b-link @click="hide" :to="`/admin/loans?community_id=${item.id}`">
                  emprunts ({{ item.loans_count }})
                </b-link>
                ?
              </b-form-checkbox>
            </b-form-group>
            <p v-else>Aucun emprunt dans cette communauté.</p>

            <b-form-group
              v-if="item.users_count"
              description="Si coché, le membrariat des participant.s sera copié (sans leur rôle)
                 dans la nouvelle communauté, s'ils ou elles n'en font pas déjà partie."
            >
              <b-form-checkbox
                v-model="destroyParams.merge_users"
                :disabled="!destroyParams.merge_destination"
              >
                Copier les
                <b-link @click="hide" :to="`/admin/communities/${item.id}`">
                  membres ({{ item.users_count }})
                </b-link>
                ?
              </b-form-checkbox>
            </b-form-group>
            <p v-else>Aucun membre dans cette communauté.</p>
            <b-form-group
              v-if="destroyParams.merge_users"
              description="Attention : si coché le statut de chaque membre (approuvé, suspendu,
                               etc.) sera copié tel quel à la nouvelle communauté. Sinon, seuls les
                              membres approuvés et en attente de validation seront copiés (donc pas
                              les membres suspendus) mais sans leur statut d'approbation."
            >
              <b-form-checkbox v-model="destroyParams.merge_user_status">
                Préserver le statut des membres?
              </b-form-checkbox>
            </b-form-group>

            <b-form-group
              v-if="item.invitations_count"
              description="Si coché, les invitations seront transférées de l'ancienne communauté à la nouvelle, avec les pré-approbations."
            >
              <b-form-checkbox
                v-model="destroyParams.merge_invitations"
                :disabled="!destroyParams.merge_destination"
              >
                Transférer les
                <b-link @click="hide" :to="`/admin/invitations?community_id=${item.id}`">
                  invitations ({{ item.invitations_count }})
                </b-link>
                ?
              </b-form-checkbox>
            </b-form-group>
            <p v-else>Aucun invitation.</p>
          </div>
        </template>
        <template #restore-question>
          <p>
            <strong>Êtes-vous sûr-e de vouloir restaurer {{ item.name }}?</strong>
          </p>
        </template>
      </admin-list-actions>
    </template>
  </paginated-table>
</template>

<script>
import AdminListActions from "@/components/Admin/ListActions.vue";
import IconButton from "@/components/shared/IconButton.vue";
import locales from "@/locales";
import PaginatedTable from "@/components/shared/PaginatedTable.vue";
import { isGlobalAdmin } from "@/helpers/permissions/users";
import { Column, Filter } from "@/components/shared/PaginatedTableColumns";
import FormsValidatedInput from "@/components/Forms/ValidatedInput.vue";
import { canSeeDeletedCommunity } from "@/helpers/permissions/communities";

function initialDestroyParams() {
  return {
    merge_loans: false,
    merge_users: false,
    merge_destination: null,
    merge_user_status: false,
    merge_invitations: false,
  };
}

export default {
  name: "AdminCommunities",
  components: {
    IconButton,
    FormsValidatedInput,
    PaginatedTable,
    AdminListActions,
  },
  data() {
    const data = {
      destroyParams: initialDestroyParams(),
      selected: [],
      extraFilters: [],
      columns: [
        new Column("id", "ID", "id"),
        new Column("name", "Nom"),
        Column.withSelect("type", "Type", [
          { value: null, label: this.$t("fields.types.null") },
          { value: "borough", label: this.$t("fields.types.borough"), variant: "success" },
          { value: "private", label: this.$t("fields.types.private"), variant: "primary" },
        ]),
      ],
    };

    if (canSeeDeletedCommunity(this.$store.state.user)) {
      data.columns.push(
        new Column("deleted_at", "Date d'archivage", "date", { showByDefault: false })
      );
      data.extraFilters.push(new Filter("is_deleted", "Archivé?", "boolean"));
    }
    return data;
  },
  computed: {
    canCreateCommunity() {
      // Global admins only for the moment.
      const loggedInUser = this.$store.state.user;
      return isGlobalAdmin(loggedInUser);
    },
  },
  methods: {
    initialDestroyParams,
  },
  i18n: {
    messages: {
      en: {
        ...locales.en.communities,
        ...locales.en.forms,
      },
      fr: {
        ...locales.fr.communities,
        ...locales.fr.forms,
      },
    },
  },
};
</script>

<style lang="scss">
.communities_destroy_form .form-group {
  margin-bottom: 0.5rem;
}
</style>
