import { capitalize, currency, datetime } from "@/helpers/filters";

/** @typedef {('text'|'id'|'boolean'|'number'|'currency'|'date'|'select'|'relation')} FieldType */
/** @typedef {{value: *, label:string}[]} SelectOptions */
/** @typedef {{relation: string, field:string, params: {}, label: string, details?:string}} RelationOptions */
/** @typedef {SelectOptions|RelationOptions} FilterOptions */
/** @typedef {function(string,string,object):string|{text:string, variant: string}} Formatter */
/** @typedef {function(*):string} UrlFct */

export class Column {
  /**
   * Column id for fetching the data
   * @type {String}
   */
  key;

  /**
   * @type String
   */
  label;

  /**
   * @type FieldType
   */
  type;

  /**
   * Whether this column is visible initially
   * @type {Boolean}
   */
  showByDefault;

  /** @type {Boolean} */
  sortable;

  /** @type {Filter} */
  filter;

  /**@type Formatter */
  formatter;

  /**@type UrlFct */
  urlFct;

  /**
   * css class to apply on the column cells
   * @type string
   */
  class;

  /**
   * @typedef {{
   *  showByDefault?: boolean,
   *  sortable?: boolean,
   *  formatter?: Formatter,
   *  urlFct?: UrlFct,
   *  cellClass?: string,
   * }} ColumnOptions
   */

  /**
   *
   * @param {String} key
   * @param {String} label
   * @param {FieldType} type
   * @param {ColumnOptions} options
   * @param {String} filterField
   * @param {FilterOptions} filterOptions
   */
  constructor(
    key,
    label,
    type = "text",
    { showByDefault = true, sortable = true, urlFct = null, formatter = null, cellClass = "" } = {},
    { filterField = key, filterOptions = null } = {}
  ) {
    this.key = key;
    this.label = label;
    this.type = type;
    this.showByDefault = showByDefault;
    this.sortable = sortable;
    this.urlFct = urlFct;
    this.class = cellClass;
    if (!formatter) {
      this.formatter = this.getDefaultFormatterForType(type, filterOptions);
    } else {
      this.formatter = formatter;
    }
    if (filterField !== null) {
      this.filter = new Filter(filterField, label, type, filterOptions);
    }
  }

  /**
   *
   * @param {FieldType} type
   * @param {FilterOptions} filterOptions
   * @return Formatter
   */
  getDefaultFormatterForType(type, filterOptions) {
    if (type === "boolean") {
      return (value) => (!!value ? "✓" : "✗");
    }
    if (type === "currency") {
      return currency;
    }
    if (type === "date") {
      return datetime;
    }
    if (type === "select") {
      return (value) => {
        if (value === null) {
          return "";
        }

        const option = filterOptions.filter((f) => f.value === value);
        if (option.length !== 1) {
          return "";
        }
        if (option[0].variant) {
          return {
            text: capitalize(option[0].label),
            variant: option[0].variant,
          };
        }

        return capitalize(option[0].label);
      };
    }
    // Identity
    return (x) => x;
  }

  /**
   * @param key
   * @param label
   * @param type
   * @param {ColumnOptions} options
   * @return {Column}
   */
  static withoutFilter(key, label, type = "text", options = {}) {
    return new Column(key, label, type, options, { filterField: null });
  }

  /**
   * @param {String} key
   * @param {String} label
   * @param {SelectOptions} filterOptions
   * @param {ColumnOptions} columnOptions
   * @param {String} filterField
   * @return {Column}
   */
  static withSelect(key, label, filterOptions, columnOptions = {}, filterField = key) {
    return new Column(key, label, "select", columnOptions, {
      filterField,
      filterOptions,
    });
  }

  /**
   * @param {String} key
   * @param {String} label
   * @param {RelationOptions} filterOptions
   * @param {ColumnOptions} columnOptions
   * @param {String} filterField
   * @return {Column}
   */
  static withRelation(key, label, filterOptions, filterField = key, columnOptions = {}) {
    return new Column(key, label, "relation", columnOptions, {
      filterField,
      filterOptions,
    });
  }
}

export class Filter {
  /**
   * Field for filtering the data
   * @type {String}
   */
  field;

  /**  @type {String} */
  label;

  /**
   * @type FieldType
   */
  type;

  /**
   * @type FilterOptions
   */
  options;

  constructor(field, label, type, options) {
    this.field = field;
    this.label = label;
    this.type = type;
    this.options = options;
  }
}
