import i18n from "@/i18n";

const getTotalsPerType = function (invoice) {
  let totalsPerType = {};

  if (invoice) {
    for (const invoiceItem of invoice.items) {
      let key = invoiceItem.item_type;

      if (key in totalsPerType) {
        totalsPerType[key] += invoiceItem.total;
      } else {
        totalsPerType[key] = invoiceItem.total;
      }
    }
  }

  return totalsPerType;
};

const summarizeBorrowerInvoice = function (
  borrowerInvoice,
  options = {
    ownerName: null,
    communityName: null,
    groupCompensations: true,
    groupContributions: true,
    contributionsDetails: true,
  }
) {
  let invoice = {
    items: [],
  };

  if (!borrowerInvoice) {
    return invoice;
  }

  let totalsPerType = getTotalsPerType(borrowerInvoice);
  let hasNonContributionItems = false;

  let contributionItems = [];
  // There should only be a single item for each of global and local contributions
  for (const item of borrowerInvoice?.items || []) {
    if (item.item_type !== "loan.contribution") continue;
    if (item.contribution_community_id) {
      let label = "Contributions à la communauté";
      let subtext = null;
      if (options.contributionsDetails) {
        if (options.communityName) {
          label = item.meta?.pricing_name
            ? item.meta?.pricing_name + ` (${options.communityName})`
            : `Contributions à la communauté ${options.communityName}`;
        }
        subtext = item.meta?.pricing_description;
      }

      contributionItems.push({
        item_type: "loan.contribution",
        label,
        subtext,
        total: -item.total,
        class: "invoice-contribution",
      });
    } else {
      let label = "Contributions au Réseau LocoMotion";
      let subtext = null;
      if (options.contributionsDetails) {
        if (item.meta?.pricing_name) {
          label = item.meta.pricing_name + " (Réseau LocoMotion)";
        }
        subtext = item.meta?.pricing_description;
      }

      contributionItems.push({
        item_type: "loan.contribution",
        label,
        subtext,
        total: -item.total,
        class: "invoice-contribution",
      });
    }
  }

  if (contributionItems.length > 0 && options.groupContributions) {
    invoice.items.push({
      label: "Contritbutions",
      items: contributionItems,
    });
  } else {
    for (const contributionItem of contributionItems) {
      invoice.items.push(contributionItem);
    }
  }

  let compensationItems = [];
  if (totalsPerType["loan.price"]) {
    hasNonContributionItems = true;
    compensationItems.push({
      item_type: "loan.price",
      label: i18n.t("bill_items.item_types.loan_price"),
      total: -totalsPerType["loan.price"],
    });
    if (totalsPerType["loan.expenses"]) {
      hasNonContributionItems = true;
      compensationItems.push({
        item_type: "loan.expenses",
        label: i18n.t("bill_items.item_types.loan_expenses"),
        total: -(totalsPerType["loan.expenses"] ?? 0),
      });
    }
  }

  if (compensationItems.length > 0 && options.groupCompensations) {
    let label = "Montant remis au propriétaire";
    if (options.ownerName) {
      `Montant remis à ${options.ownerName}`;
    }
    invoice.items.push({
      label,
      items: compensationItems,
    });
  } else {
    for (const compensationItem of compensationItems) {
      invoice.items.push(compensationItem);
    }
  }

  if (totalsPerType["loan.insurance"]) {
    hasNonContributionItems = true;
    invoice.items.push({
      item_type: "loan.insurance",
      label: i18n.t("bill_items.item_types.loan_insurance"),
      total: -totalsPerType["loan.insurance"],
    });
  }

  if (totalsPerType["donation.loan"]) {
    invoice.items.push({
      item_type: "donation.loan",
      label: i18n.t("bill_items.item_types.donation_loan"),
      total: -totalsPerType["donation.loan"],
    });
  }

  if (hasNonContributionItems) {
    invoice.items.push({
      class: "invoice-total",
      label: "Total",
      total: -borrowerInvoice.user_balance_change,
    });
  }

  return invoice;
};

const summarizeOwnerInvoice = function (loan) {
  let invoice = {
    items: [],
  };

  if (!loan.owner_invoice) {
    return invoice;
  }

  const totalsPerType = getTotalsPerType(loan.owner_invoice);

  let total = 0;
  for (const key in totalsPerType) {
    invoice.items.push({
      item_type: key,
      label: i18n.t("bill_items.item_types." + key.replace(".", "_")),
      total: totalsPerType[key],
    });

    total += totalsPerType[key];
  }

  invoice.items.push({
    class: "invoice-total",
    label: `Montant remis par ${loan.borrower.user.full_name}`,
    total: total,
  });

  return invoice;
};

export { getTotalsPerType, summarizeBorrowerInvoice, summarizeOwnerInvoice };
