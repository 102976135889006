<template>
  <div class="community-list" :class="{ loading }">
    <!-- loanable cards -->
    <div v-if="visibleLoanables.length > 0">
      <b-row>
        <b-col
          v-for="loanable in visibleLoanables"
          class="community-list--mobile"
          :key="loanable.id"
          xl="3"
          md="4"
          sm="6"
        >
          <b-card class="mb-3 position-relative community-list__result">
            <loanable-details
              :loanable="loanable"
              @test="emitTest(loanable)"
              @select="emitSelect(loanable)"
            />
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="community-list--margin">
          <b-pagination align="right" v-model="page" :total-rows="total" :per-page="perPage" />
        </b-col>
      </b-row>
    </div>
    <!---->
    <!-- container if no loanables -->
    <b-row v-else>
      <b-col class="community-list__no-results">
        <b-card>
          <b-card-body>
            <h3>Désolé, aucun véhicule ne correspond à ces critères.</h3>
            <p class="community-list--dark">
              Essayez d'autres critères ou invitez vos voisins à rejoindre LocoMotion ;)
            </p>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <!---->
    <layout-loading v-if="loading" class="loading-overlay" />
  </div>
</template>

<script>
import LoanableDetails from "@/components/Loanable/Details.vue";

export default {
  name: "List",
  components: {
    LoanableDetails,
  },
  data() {
    return {
      page: 1,
      perPage: 12,
    };
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    total() {
      return this.data.length;
    },
    visibleLoanables() {
      return this.data.slice((this.page - 1) * this.perPage, this.page * this.perPage);
    },
    types() {
      return this.$store.state["loanable.search"].selectedLoanableTypes;
    },
  },
  methods: {
    emitSelect(loanable) {
      this.$emit("select", loanable);
    },
    emitTest(loanable) {
      this.$emit("test", loanable);
    },
  },
  watch: {
    types() {
      this.page = 1;
    },
  },
};
</script>

<style lang="scss">
@import "~bootstrap/scss/mixins/breakpoints";

.community-list {
  width: 100%;
  position: relative;

  &__no-results h3 {
    font-weight: 700;
  }

  &__result {
    overflow: hidden;
    .card-body {
      padding: 0;
    }
  }

  &.loading {
    opacity: 0.5;
    pointer-events: none;
  }

  .loading-overlay {
    position: absolute;
    top: calc(50% + 1.5rem); // Move down from section header
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.community-list--margin {
  margin: 15px;
}

.community-list--dark {
  color: $dark;
}
</style>
