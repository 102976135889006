<script>
import IconButton from "@/components/shared/IconButton.vue";
import Vue from "vue";
import { canLoanCar } from "@/helpers/permissions/users";

export default Vue.extend({
  name: "BorrowerProfileWarning",
  components: { IconButton },
  computed: {
    user() {
      return this.$store.state.user;
    },
    borrowerSuspended() {
      return !!this.user.borrower.suspended_at;
    },
    canLoanCar() {
      return canLoanCar(this.user);
    },
  },
  props: {
    size: {
      type: String,
      required: false,
    },
  },
});
</script>

<template>
  <b-alert show variant="warning" v-if="!canLoanCar">
    <template v-if="borrowerSuspended">
      <strong>Oops! Votre dossier de conduite à été suspendu.</strong>
      <br />
      Pour emprunter une voiture, veuillez contacter info@locomotion.app afin de rétablir votre
      dossier de conduite.
    </template>
    <template v-else>
      <strong>Oops! Pour emprunter l'auto de vos voisin&#8209;e&#8209;s</strong> vous devez remplir
      votre dossier de conduite.
      <br />
      <icon-button
        to="/profile/borrower"
        pill
        class="loan-search-form__button-borrower"
        variant="warning"
        :size="size"
      >
        Remplissez votre dossier
      </icon-button>
    </template>
  </b-alert>
</template>
