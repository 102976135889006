import RestModule from "../RestModule";
import { del, get, put } from "@/requests/server";

export default new RestModule(
  "loanables",
  {},
  {
    /**
     * Attempts to retrieve a loanable which may be accessible by the user
     */
    async tryRetrieveOne({ dispatch, commit, state }, { params, id }) {
      commit("loaded", false);
      commit("loading", true);

      await dispatch("options");

      try {
        const { data } = await get(`/${state.slug}/${id}`, {
          axiosRequestConfig: { params },
          notifications: { action: "chargement" },
          cleanupCallback: () => commit("loading", false),
          requestOptions: { expects: [404] },
        });
        commit("item", data);
        commit("initialItem", data);

        commit("loaded", true);
        commit("loading", false);
      } catch (e) {
        if (e.request && e.request.status === 404) {
          commit(
            "addNotification",
            {
              content: "Ce véhicule n'est pas partagé dans votre communauté.",
              title: "Véhicule inaccessible",
              variant: "danger",
            },
            { root: true }
          );
        }
        throw e;
      }
    },
    async disable({ commit, state }, id) {
      const { data: deleted } = await del(`/${state.slug}/${id}`, {
        notifications: { action: "Archivage du véhicule", onSuccess: "Archivage réussi!" },
      });

      commit("deleted", deleted);
    },
    async addCoowner({ commit, state }, { loanable, user }) {
      const { data } = await put(
        `/loanables/${loanable.id}/coowners`,
        {
          user_id: user.id,
        },
        {
          notifications: {
            action: "ajout de co-propriétaire",
            onSuccess: "Co-propriétaire ajouté(e)!",
          },
        }
      );
      const coowners = [
        ...(state.item.coowners ?? []),
        {
          ...data,
          loanable,
          user,
        },
      ];

      commit("patchItem", { coowners });
      commit("patchInitialItem", { coowners });
    },
    async removeCoowner({ commit, state }, { coownerId }) {
      await del(`/loanables/roles/${coownerId}`, {
        notifications: {
          action: "retrait de co-propriétaire",
          onSuccess: "Co-propriétaire retiré(e)!",
        },
      });
      const coowners = [...state.item.coowners.filter((c) => c.id !== coownerId)];
      commit("patchItem", { coowners });
      commit("patchInitialItem", { coowners });
    },
  }
);
