<template>
  <div :class="`forms-map-input ${stateClass}`">
    <div class="forms-map-input__map" ref="map"></div>
    <p v-if="description">{{ description }}</p>
  </div>
</template>

<script>
import { loader, mapId } from "@/helpers/googleMaps";

export default {
  name: "FormsMapInput",
  props: {
    bounded: {
      type: Boolean,
      required: false,
      default: false,
    },
    center: {
      type: Object,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    allowFullscreen: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    state: {
      type: Boolean,
      required: false,
      default: true,
    },
    polygons: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    polygonOptions: {
      type: Object,
      default: () => ({
        fillColor: "#16a59e",
        fillOpacity: 0.25,
        strokeOpacity: 0,
        clickable: false,
        zIndex: 2,
      }),
    },
    value: {
      required: true,
      type: Array | null,
    },
  },
  mounted() {
    this.initMap();
  },
  data() {
    return {
      mapOptions: {
        clickableIcons: false,
        fullscreenControl: this.allowFullscreen,
        mapTypeControl: false,
        streetViewControl: false,
        maxZoom: 19,
        zoom: 14,
      },
      mapPolygon: null,
      marker: null,
    };
  },
  computed: {
    markerPosition() {
      if (this.value?.length === 2) {
        return {
          lat: this.value[0],
          lng: this.value[1],
        };
      }

      return null;
    },
    stateClass() {
      if (this.state === null) {
        return "";
      }

      if (this.state) {
        return "is-valid";
      }

      return "is-invalid";
    },
  },
  methods: {
    async initMap() {
      const { Map, Polygon } = await loader.importLibrary("maps");

      const markerPosition =
        this.value?.length === 2
          ? {
              lat: this.value[0],
              lng: this.value[1],
            }
          : null;

      this.map = new Map(this.$refs.map, {
        ...this.mapOptions,
        center: markerPosition || this.center || { lat: 45.5342925, lng: -73.599039 },
        mapId,
        gestureHandling: "greedy",
      });

      this.map.addListener("click", this.savePosition);

      await this.createOrUpdateMarker(markerPosition);

      if (this.polygons) {
        this.mapPolygon = new Polygon({
          ...this.polygonOptions,
          paths: this.polygons,
          map: this.map,
        });

        if (!markerPosition && !this.center) {
          const { LatLngBounds } = await loader.importLibrary("core");

          const bounds = new LatLngBounds();

          for (const polygon of this.polygons) {
            for (const point of polygon) {
              bounds.extend(point);
            }
          }

          this.map.fitBounds(bounds);
        }
      }
    },
    async createOrUpdateMarker(position) {
      if (!position) {
        return;
      }

      if (this.marker) {
        this.marker.position = position;
        return;
      }

      const { AdvancedMarkerElement } = await loader.importLibrary("marker");
      this.marker = new AdvancedMarkerElement({
        map: this.map,
        position: this.markerPosition,
        gmpDraggable: !this.disabled,
      });

      this.marker.addListener("dragend", this.savePosition);
    },
    async savePosition(event) {
      if (!this.disabled) {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();

        if (this.bounded && this.mapPolygon) {
          const { poly } = await loader.importLibrary("geometry");
          if (poly.containsLocation(event.latLng, this.mapPolygon)) {
            this.$emit("input", [lat, lng]);
          }
          return;
        }

        this.$emit("input", [lat, lng]);
      }
    },
  },
  watch: {
    markerPosition(newPosition) {
      this.createOrUpdateMarker(newPosition);
    },
  },
};
</script>

<style lang="scss">
.forms-map-input {
  // Match other form input border
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
  overflow: hidden;

  &__map {
    height: 240px;
  }

  &.is-invalid {
    border: 1px solid $danger;
  }

  &.is-valid {
    border: 1px solid $success;
  }
}
</style>
