<template>
  <b-card no-body class="loan-form loan-actions loan-actions-payment">
    <b-card-header
      header-tag="header"
      role="tab"
      class="loan-actions__header"
      v-b-toggle.loan-actions-payment
    >
      <h2>
        <svg-waiting v-if="action.status === 'in_process' && !loanIsCanceled && !loanIsContested" />
        <svg-check v-else-if="action.status === 'completed'" />
        <svg-danger v-else-if="action.status === 'canceled' || loanIsCanceled || loanIsContested" />

        Conclusion
      </h2>

      <!-- Canceled loans: current step remains in-process. -->
      <span v-if="action.status === 'in_process' && loanIsCanceled">
        Emprunt annulé &bull; {{ item.canceled_at | datetime }}
      </span>
      <span v-else-if="action.status === 'in_process'"> En attente </span>
      <span v-else-if="action.status === 'completed'">
        Payé &bull; {{ action.executed_at | datetime }}
      </span>
      <span v-else-if="action.status === 'canceled'">
        Annulé &bull; {{ action.executed_at | datetime }}
      </span>
    </b-card-header>

    <b-collapse id="loan-actions-payment" role="tabpanel" accordion="loan-actions" :visible="open">
      <b-card-body>
        <div v-if="!!action.executed_at">
          <p>L'emprunt s'est conclu avec succès!</p>

          <div v-if="isLoanAdmin || isBorrower">
            <h3>Coût du trajet</h3>

            <div class="trip-cost-details" v-if="item.borrower_invoice">
              <tiny-invoice :invoice="borrowerInvoice"> </tiny-invoice>
            </div>
            <div v-else>Gratuit!</div>
          </div>

          <div v-if="isCoownerOrOwner && !isBorrower && item.owner_invoice">
            <h3>Compensation du trajet</h3>

            <div class="trip-cost-details">
              <tiny-invoice :invoice="ownerInvoice"> </tiny-invoice>
            </div>
          </div>
        </div>
        <div v-else-if="action.status === 'in_process' && loanIsCanceled">
          <p>L'emprunt a été annulé. Cette étape ne peut pas être complétée.</p>
        </div>
        <div v-else>
          <div class="loan-actions-payment__warning" v-if="loanIsContested">
            <p>
              Vous ne pouvez pas clôturer cette réservation car elle a été contestée, notre équipe
              va vous contacter.
            </p>
          </div>
          <div v-else>
            <!-- Validation header -->
            <div v-if="needsUserValidation">
              <p>
                Validez les informations sur ce trajet&nbsp;: le kilomètrage, la facture
                d'essence&hellip;
              </p>
              <p>
                <icon-button
                  size="sm"
                  variant="ghost-secondary"
                  class="mr-2"
                  v-b-toggle.loan-actions-takeover
                >
                  Informations au début
                </icon-button>
                <icon-button size="sm" variant="ghost-secondary" v-b-toggle.loan-actions-handover>
                  Informations à la fin
                </icon-button>
              </p>

              <hr />
            </div>
            <div v-else-if="needsValidation">
              <p v-if="needsBorrorwerValidation || needsOwnerValidation">
                En attente de la validation des informations par
                <template v-if="needsBorrorwerValidation">{{
                  item.borrower.user.full_name
                }}</template>
                <template v-if="needsBorrorwerValidation && needsOwnerValidation"> et </template>
                <template v-if="needsOwnerValidation">{{
                  item.loanable.owner.user.full_name
                }}</template
                >.
              </p>
              <p v-else>Emprunt entièrement validé. En attente du paiement!</p>

              <hr />
            </div>

            <!-- Owner content -->
            <div v-if="isCoownerOrOwner && !isBorrower">
              <h3>Compensation du trajet</h3>

              <div class="trip-cost-details">
                <tiny-invoice :invoice="ownerInvoice"> </tiny-invoice>
              </div>

              <div v-if="needsValidation" class="mt-4">
                <div v-if="!needsUserValidation" class="text-center">
                  <icon-button icon="check2-circle" variant="success" disabled>
                    Validé!
                  </icon-button>
                </div>
                <div v-else-if="needsBorrorwerValidation">
                  <b-alert show variant="warning">
                    Le paiement sera effectué lorsque vous et l'emprunteur-se aurez validé les
                    informations, ou au plus tard 48h après la fin de la réservation. ({{
                      validationLimitText
                    }}).
                  </b-alert>
                  <div class="text-center">
                    <icon-button
                      icon="check2-circle"
                      variant="success"
                      :loading="updating"
                      @click="validate"
                    >
                      Valider
                    </icon-button>
                  </div>
                </div>
                <div v-else>
                  <b-alert show variant="success">
                    L'emprunteur-se a déjà validé l'information. Vous avez jusqu'à 48h après la fin
                    de la réservation ({{ validationLimitText }}) pour contester. Validez dès
                    maintenant pour recevoir le paiement!
                  </b-alert>
                  <div class="text-center">
                    <icon-button
                      size="sm"
                      icon="check2-circle"
                      variant="success"
                      :loading="updating"
                      @click="validateAsOwner"
                    >
                      Valider
                    </icon-button>
                  </div>
                </div>
              </div>

              <b-alert
                variant="light"
                show
                class="mt-4 mb-0"
                v-if="
                  (!needsValidation || !needsUserValidation) &&
                  !item.is_self_service &&
                  item.loanable.sharing_mode === 'hybrid' &&
                  !item.is_borrower_trusted
                "
              >
                <h4 class="alert-heading">Ajouter au réseau de confiance</h4>
                <p>
                  Si l'emprunt s'est bien déroulé vous pouvez ajouter
                  {{ item.borrower_user.name }} au réseau de confiance pour ce véhicule. Leurs
                  futurs emprunts n'auront plus besoin de votre approbation (mode libre partage).
                </p>
                <p>
                  <icon-button
                    variant="outline-success"
                    icon="shield-fill-check"
                    :onclick="addBorrowerToTrustedBorrowers"
                  >
                    Ajouter {{ item.borrower_user.name }}
                  </icon-button>
                  <b-button
                    variant="link"
                    :to="`/profile/loanables/${item.loanable.id}#trusted_borrowers`"
                  >
                    Voir le réseau de confiance
                  </b-button>
                </p>
              </b-alert>
            </div>

            <!-- Borrower and admin content -->
            <div v-if="isLoanAdmin || isBorrower">
              <loan-donations
                :loan="item"
                :disabled="paymentButtonOptions.disabled"
                :onpaid="paymentButtonOptions.onclick"
                :payment-label="paymentButtonOptions.label"
                :payment-warning="paymentButtonOptions.warning"
                :reload-user-on-paid="!paymentButtonOptions.willComplete"
                @input="platformTip = $event"
              >
                <template #complete-button v-if="isLoanAdmin && !isBorrower">
                  <icon-button
                    size="sm"
                    variant="outline-warning"
                    :loading="updating"
                    icon="skip-forward-circle"
                    @click="pay"
                  >
                    Compléter le paiement immédiatement
                  </icon-button>
                </template>
              </loan-donations>
            </div>
          </div>
        </div>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import Donation from "@/components/shared/Donation.vue";
import FormsValidatedInput from "@/components/Forms/ValidatedInput.vue";
import IconButton from "@/components/shared/IconButton.vue";
import DonationSlider from "@/components/shared/DonationSlider.vue";
import LoanDonations from "@/components/shared/LoanDonations.vue";
import PaymentDialog from "@/components/User/PaymentDialog.vue";
import TinyInvoice from "@/components/Invoice/TinyInvoice.vue";
import ProvisionModal from "@/components/User/ProvisionModal.vue";
import { summarizeBorrowerInvoice, summarizeOwnerInvoice } from "@/helpers/invoices";

import LoanActionsMixin from "@/mixins/LoanActionsMixin";
import LoanStepsSequence from "@/mixins/LoanStepsSequence";

import { normalizeCurrency } from "@/helpers/filters";
import locales from "@/locales";
import { canPay, canValidateLoan } from "@/helpers/permissions/loans";
import { put } from "@/requests/server";
import { addTrustedBorrower } from "@/requests/trustedBorrowersRequests";

export default {
  name: "LoanActionsPayment",
  mixins: [LoanActionsMixin, LoanStepsSequence],
  components: {
    LoanDonations,
    ProvisionModal,
    IconButton,
    DonationSlider,
    Donation,
    FormsValidatedInput,
    PaymentDialog,
    TinyInvoice,
  },
  data() {
    return {
      platformTip: normalizeCurrency(this.item.platform_tip),
      initialTip: normalizeCurrency(this.item.platform_tip),
      updating: false,
      canPay: canPay(this.user, this.item),
      canValidateLoan: canValidateLoan(this.user, this.item),
    };
  },
  computed: {
    actualPrice() {
      return -(this.item.borrower_invoice?.user_balance_change ?? 0);
    },
    paymentButtonOptions() {
      if (!this.needsValidation || this.isFullyValidated) {
        return {
          label: this.actualPrice === 0 ? "terminer" : "payer",
          onclick: this.updateTipAndPay,
          willComplete: true,
        };
      }

      if (this.needsOwnerValidation && this.needsBorrorwerValidation) {
        return {
          label: "valider",
          warning: `Le paiement sera effectué lorsque vous et le-a propriétaire aurez validé les informations ou au plus tard 48h après la fin de la réservation. (${this.validationLimitText}).`,
          onclick: this.updateTipAndValidate,
          willComplete: false,
        };
      }

      if (this.needsBorrorwerValidation) {
        return {
          label: "payer",
          warning: `Le-a propriétaire a déjà validé l'information. Vous avez jusqu'à 48h après la fin de la réservation (${this.validationLimitText}) pour pour contester. Sinon, validez les informations et payez dès maintenant!`,
          onclick: this.updateTipValidateAndPay,
          willComplete: true,
        };
      }

      if (this.hasModifiedTip) {
        return {
          label: "enregistrer",
          warning: `Enregistrez vos changements à la contribution volontaire. Le paiement sera effectué lorsque le-a propriétaire aura validé les informations ou au plus tard 48h après la fin de la réservation (${this.validationLimitText})`,
          onclick: this.updateTip,
          willComplete: false,
        };
      }
      return {
        label: "Validé !",
        disabled: true,
      };
    },
    borrowerInvoice() {
      return summarizeBorrowerInvoice(this.item.borrower_invoice, {
        ownerName: this.item.loanable.owner.user.name,
        communityName: this.item.community.name,
        groupCompensations: true,
        contributionsDetails: true,
      });
    },
    ownerInvoice() {
      return summarizeOwnerInvoice(this.item);
    },
    needsValidation() {
      return this.item.needs_validation;
    },
    isFullyValidated() {
      return !!this.item.owner_validated_at && !!this.item.borrower_validated_at;
    },
    needsOwnerValidation() {
      return this.needsValidation && !this.item.owner_validated_at;
    },
    needsBorrorwerValidation() {
      // Or if user has modified tip!
      return this.needsValidation && !this.item.borrower_validated_at;
    },
    needsUserValidation() {
      return (
        (this.isBorrower && this.needsBorrorwerValidation) ||
        (this.isCoownerOrOwner && this.needsOwnerValidation)
      );
    },
    validationLimit() {
      return this.$dayjs(this.item.actual_return_at).add(48, "hour");
    },
    validationLimitText() {
      return this.validationLimit.format("D MMMM YYYY à HH:mm");
    },
    hasModifiedTip() {
      return this.platformTip !== this.initialTip;
    },
  },
  methods: {
    // Returns true if tip has been updated successfully or if it didn't need to be updated.
    async updateTip() {
      if (this.hasModifiedTip) {
        try {
          this.updating = true;
          await this.$store.dispatch("loans/update", {
            id: this.item.id,
            data: { platform_tip: this.platformTip },
            params: this.$route.meta.params,
          });
          this.initialTip = this.platformTip;
          this.updating = false;
          return true;
        } catch (e) {
          return false;
        }
      }
      return true;
    },
    async validate() {
      try {
        this.updating = true;
        await this.$store.dispatch("loans/validate", { loan: this.item, user: this.user });
        return true;
      } catch (e) {
        return false;
      } finally {
        this.updating = false;
      }
    },
    async pay(requestOptions = {}) {
      this.updating = true;

      await put(`/loans/${this.item.id}/payment/complete`, this.action, {
        notifications: { action: "paiement", onSuccess: "paiement complété!" },
        cleanupCallback: () => (this.updating = false),
        requestOptions,
      });

      this.$emit("completed");
      return true;
    },
    async updateTipAndValidate() {
      (await this.updateTip()) && (await this.validate());
    },
    async validateAsOwner() {
      // Owners can try to complete the payment immediately if borrower has
      // already validated, but we do it silently without showing an error if the borrower
      // cannot pay (e.g. if their balance is not sufficient).
      const validated = await this.validate();
      if (!validated) {
        return;
      }
      try {
        await this.pay({ expects: [403] });
      } catch (e) {
        if (e.response?.status !== 403) {
          throw e;
        }
      }
    },
    async updateTipAndPay() {
      (await this.updateTip()) && (await this.pay());
    },
    async updateTipValidateAndPay() {
      (await this.updateTip()) && (await this.validate()) && (await this.pay());
    },
    async addBorrowerToTrustedBorrowers() {
      await addTrustedBorrower(this.item.loanable.id, this.item.borrower_user.id);
      await this.$store.commit("loans/patchItem", { is_borrower_trusted: true });
    },
  },
  i18n: {
    messages: {
      en: {
        bill_items: locales.en.bill_items,
        ...locales.en.loans,
        ...locales.en.forms,
      },
      fr: {
        bill_items: locales.fr.bill_items,
        ...locales.fr.loans,
        ...locales.fr.forms,
      },
    },
  },
};
</script>

<style lang="scss">
@import "~bootstrap/scss/mixins/breakpoints";
.loan-actions-payment {
  .trip-cost-details {
    @include media-breakpoint-down(xs) {
      font-size: 0.9rem;
    }
  }

  .trip-details {
    margin: 0 auto;
    line-height: 1.5;

    th,
    td {
      padding: 0 0.75rem;
      font-weight: normal;
    }

    tr:not(.header):not(.last) th,
    tr:not(.header):not(.last) td {
      border-bottom: 1px dotted lightgray;
    }

    .header th {
      font-weight: bold;
      font-size: 1.2rem;
      padding-top: 0.5rem;
    }
  }

  .trip-details__total {
    border-top: 1px solid black;
  }
}
</style>
