<template>
  <div>
    <div>
      <b-row class="align-items-center">
        <b-col cols="12" lg
          ><slot name="head">
            <h2>{{ label | capitalize }}</h2>
          </slot>
        </b-col>
        <b-col cols="auto" class="paginated-table_button-list">
          <slot name="head-buttons"> </slot>

          <b-button-group>
            <icon-button
              v-if="showColumnSelection"
              variant="ghost-secondary"
              v-b-modal="`column-modal-${id}`"
              v-b-tooltip:hover="'Colonnes à afficher'"
              icon="layout-three-columns"
            />

            <icon-button
              v-if="hasFilters"
              class="toggle-filters-button"
              variant="ghost-secondary"
              @click="toggleFilters"
              :pressed="showFilters"
              icon="filter"
            >
              Filtres
              <b-badge class="ml-2" v-if="activeFilterCount > 0" variant="danger">{{
                activeFilterCount
              }}</b-badge>
            </icon-button>

            <icon-button
              v-if="differsFromDefault"
              variant="ghost-secondary"
              @click="resetToDefaults"
              v-b-tooltip:hover="'Réinitialiser les filtres et les colonnes'"
              icon="skip-start-circle"
            >
            </icon-button>
          </b-button-group>

          <div
            class="d-inline-block"
            @mouseenter="calculateTimeSinceLastFetch"
            v-b-tooltip:hover="
              'Dernière mise à jour il y a ' + this.minutesSinceLastFetch + ' minutes.'
            "
          >
            <icon-button
              class="refresh-button"
              variant="ghost-secondary"
              :loading="isBusy"
              @click="() => refresh()"
              icon="arrow-clockwise"
              spin-icon
            />
          </div>
        </b-col>
      </b-row>

      <b-card class="filter-list" v-if="showFilters && detachedFilters.length > 0">
        <b-row>
          <b-col class="filter" lg="3" md="6" v-for="filter of detachedFilters" :key="filter.field">
            <b-form-group label-size="sm" :label="filter.label" :key="filter.field">
              <filter-input
                v-model="filters[filter.field]"
                :type="filter.type"
                :name="filter.field"
                :options="filter.options"
                @input="() => $emit('input:filters', filters)"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
    </div>

    <b-modal :id="`column-modal-${id}`" title="Colonnes visibles" ok-only>
      <b-checkbox-group
        v-model="columnsToShow"
        :options="columnsOptions"
        switches
        stacked
      ></b-checkbox-group>
    </b-modal>

    <lazy @load="fetchItems">
      <b-table
        ref="table"
        :busy="isBusy"
        class="paginated-table"
        :class="{ 'with-filters': showFilters && detachedFilters.length > 0 }"
        :primary-key="primaryKey"
        responsive
        hover
        no-local-sorting
        :api-url="endpoint"
        :fields="shownColumns"
        :items="items"
        :show-empty="true"
        empty-filtered-text="Aucun résultat"
        empty-text="Aucun résultat"
        :current-page="currentPage"
        :per-page="0"
        :filter="filters"
        v-bind="$attrs"
        :selectable="selectable"
        @row-selected="rowSelected"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        @sort-changed="currentPage = 1"
        sort-icon-left
        label-sort-asc=""
        label-sort-desc=""
        label-sort-clear=""
        borderless
        no-select-on-click
        @row-clicked="rowClicked"
        :table-class="{
          'row-clickable': hasRowDetails || rowLink || hasRowClickedListener,
          'has-row-details': hasRowDetails,
        }"
        :tbody-tr-class="computeRowClasses"
      >
        <template v-for="column in shownColumns" v-slot:[column.head]="data">
          <div class="header-wrapper">
            <div class="header-label">
              <b-form-checkbox
                v-if="selectable && column.key === keyColumn"
                size="sm"
                class="mr-0 d-inline-block"
                @change="toggleSelection"
                :indeterminate="selectedCount > 0 && itemCount !== selectedCount"
                :checked="itemCount > 0 && itemCount === selectedCount"
              />
              {{ column.key !== "actions" ? data.label : "" }}
            </div>
            <div class="mt-2" v-if="showFilters && hasFilters && column.filter">
              <slot :name="column.filtertemplate" :filters="filters">
                <filter-input
                  :class="column.class"
                  v-model="filters[column.filter.field]"
                  :type="column.filter.type"
                  :name="column.filter.field"
                  :options="column.filter.options"
                />
              </slot>
            </div>
          </div>
        </template>
        <template v-for="column in shownColumns" v-slot:[column.cell]="rowData">
          <slot :name="column.cell" v-bind="rowData">
            <template v-if="column.key === 'actions'">
              <div class="text-right">
                <b-icon
                  class="details-chevron"
                  icon="chevron-right"
                  :class="{
                    'details-showing': rowData.detailsShowing,
                  }"
                  v-if="hasRowDetails && !rowDisabled(rowData.item)"
                />
                <span v-if="hasRowDetails"></span>
                <admin-list-actions
                  v-else
                  :slug="endpoint"
                  :item-name="`ce ${label}`"
                  :item="rowData.item"
                  :show-destroy="false"
                  :show-restore="false"
                />
              </div>
            </template>
            <template v-else-if="column.key === keyColumn">
              <template v-if="selectable">
                <div
                  class="select-wrapper"
                  @click.prevent.stop="
                    () => (rowData.rowSelected ? rowData.unselectRow() : rowData.selectRow())
                  "
                >
                  <b-form-checkbox size="sm" class="d-inline-block" :checked="rowData.rowSelected">
                    <b-link v-if="rowLink" :to="`/admin/${endpoint}/${rowData.value}`">{{
                      rowData.value
                    }}</b-link>
                    <span v-else>{{ rowData.value }}</span>
                  </b-form-checkbox>
                </div>
              </template>
              <template v-else>
                <b-link v-if="rowLink" :to="`/admin/${endpoint}/${rowData.value}`">{{
                  rowData.value
                }}</b-link>
                <span v-else>{{ rowData.value }}</span>
              </template>
            </template>
            <template v-else>
              <template v-if="isUrlValid(column, rowData.item)">
                <a v-if="isExternalUrl(column, rowData.item)" :href="column.urlFct(rowData.item)">
                  {{ rowData.value }}
                </a>
                <router-link v-else :to="column.urlFct(rowData.item)">
                  {{ rowData.value }}
                </router-link>
              </template>
              <template v-else-if="rowData.value">
                <b-badge v-if="rowData.value.variant" :variant="rowData.value.variant">{{
                  rowData.value.text
                }}</b-badge>
                <template v-else>
                  {{ rowData.value }}
                </template>
              </template>
            </template>
          </slot>
        </template>
        <template #row-details="row">
          <div class="p-3">
            <slot
              name="row-details"
              v-bind="
                editableItems
                  ? { ...row, initialItem: getFetchedItem(row.item), updateItem: updateItem }
                  : row
              "
            />
          </div>
        </template>
      </b-table>
    </lazy>

    <b-row class="mb-2">
      <b-col md="6">
        <export-button
          ref="exportButton"
          :store="endpoint"
          :params="combinedParams"
          v-if="showExportButton"
        />
        <slot name="moreExports" />
      </b-col>
      <b-col md="6" v-if="total > 10">
        <admin-pagination
          class="justify-content-end"
          :page="currentPage"
          :perPage="itemsPerPage"
          @update:perPage="itemsPerPage = $event"
          @update:page="currentPage = $event"
          :total="total"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import filterInput from "@/components/shared/FilterInput.vue";
import IconButton from "@/components/shared/IconButton.vue";
import { debounce } from "@/helpers/debounce";
import router from "@/router";
import AdminListActions from "@/components/Admin/ListActions.vue";
import AdminPagination from "@/components/Admin/Pagination.vue";
import { Column, Filter } from "@/components/shared/PaginatedTableColumns";
import ExportButton from "@/components/shared/ExportButton.vue";
import Lazy from "@/components/shared/Lazy.vue";
import { get } from "@/requests/server";
import dayjs from "dayjs";

export default {
  name: "PaginatedTable",
  components: { IconButton, Lazy, ExportButton, AdminPagination, AdminListActions, filterInput },
  created() {
    this.debouncedFetchItems = debounce(this.fetchItems, 16);
  },
  activated() {
    // page may be activated with new url params
    let updatedWithParams = false;
    if (this.syncFiltersWithUrl) {
      const urlFilters = this.getFiltersFromUrl();
      if (Object.keys(urlFilters).length > 0) {
        this.filters = urlFilters;
        updatedWithParams = true;
      }
    }

    // refresh the table if reactivated after more than 15 minutes of idleness
    if (
      updatedWithParams ||
      (this.lastFetch && this.$dayjs().diff(this.lastFetch, "minutes") > 15)
    ) {
      this.$nextTick(this.fetchItems);
    }
  },
  data() {
    let filters = null;
    let columnsToShow = null;

    const listState = this.id ? this.$store.state.paginatedTables[this.id] : {};
    filters = this.getSavedFiltersOrDefault(listState);
    if (this.syncFiltersWithUrl) {
      const urlFilters = this.getFiltersFromUrl();
      if (Object.keys(urlFilters).length > 0) {
        filters = urlFilters;
      }
    }
    columnsToShow = this.getSavedColumnsToShowOrDefault(listState);

    this.$emit("filters-changed", filters);

    return {
      total: 0,
      currentPage: listState?.currentPage ?? 1,
      selectedCount: 0,
      filters,
      columnsToShow,
      showFilters: false,
      itemCount: 0,
      sortBy: listState?.sortBy ?? this.initialSortBy,
      sortDesc: listState?.sortDesc ?? this.initialSortDesc,
      itemsPerPage: listState?.perPage ?? 10,
      isBusy: false,
      lastFetch: null,
      minutesSinceLastFetch: null,
      showDetails: {},
      fetchedItems: [],
    };
  },
  props: {
    id: {
      type: String,
    },
    columns: {
      type: Array[Column],
      required: true,
    },
    extraFilters: {
      type: Array[Filter],
      required: false,
      default: () => [],
    },
    extraData: {
      type: Array[String],
      required: false,
      default: () => [],
    },
    selectable: { type: Boolean, default: false },
    label: {},
    endpoint: {
      type: String,
      required: true,
    },
    /**
     * Params to propagate directly to the request. Can set fixed filters which cannot be altered
     * with the UI.
     */
    fetchParams: {
      type: Object,
      default: () => ({}),
    },
    initialFilters: {
      type: Object,
      default: () => ({}),
    },
    syncFiltersWithUrl: {
      type: Boolean,
      default: false,
    },
    showGenerateCsv: {
      type: Boolean,
      default: true,
    },
    initialSortBy: {
      type: String,
      default: "id",
    },
    initialSortDesc: {
      type: Boolean,
      default: true,
    },
    showActionColumn: {
      type: Boolean,
      default: true,
    },
    showColumnSelection: {
      type: Boolean,
      default: true,
    },
    hasRowDetails: {
      type: Boolean,
      default: false,
    },
    rowLink: {
      type: Boolean,
      default: false,
    },
    keyColumn: {
      type: String,
      default: "id",
    },
    newItem: {
      type: Object,
      default: () => null,
    },
    rowDisabled: {
      type: Function,
      default: () => false,
    },
    editableItems: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    items() {
      const fetchedCopy = structuredClone(this.fetchedItems);
      if (this.newItem) {
        return [this.newItem, ...fetchedCopy];
      }
      return fetchedCopy;
    },
    hasRowClickedListener() {
      return this.$listeners && this.$listeners["row-clicked"];
    },
    columnsOptions() {
      return this.columns.map((c) => ({
        text: c.label,
        value: c.key,
      }));
    },
    columnsToShowSet() {
      return new Set(this.columnsToShow);
    },
    shownColumns() {
      const columns = this.columns.filter(this.shouldShowColumn).map((column) => {
        column.cell = `cell(${column.key})`;
        column.head = `head(${column.key})`;
        if (column.filter) {
          // this slot name has to be lowercased per the documentation at
          // https://vuejs.org/guide/essentials/template-syntax.html#dynamic-argument-syntax-constraints
          column.filtertemplate = `filter(${column.key})`;
        }

        if (column.type === "id") {
          column.class = "tabular-nums text-right shrink-column";
        } else if (column.type === "currency" || column.type === "number") {
          column.class = "tabular-nums text-right";
        }

        return column;
      });

      if (this.showActionColumn) {
        columns.push({
          key: "actions",
          label: "Actions",
          tdClass: "table__cell__actions",
          cell: "cell(actions)",
          head: "head(actions)",
        });
      }

      return columns;
    },
    primaryKey() {
      const idColumn = this.columns.filter((c) => c.key === this.keyColumn);
      if (idColumn.length >= 1) {
        return idColumn[0].key;
      }

      return this.shownColumns[0].key;
    },
    hasFilters() {
      return this.allFilters.length > 0;
    },
    fetchFields() {
      const columnData = this.columns.map((c) => c.key);

      return [...columnData, ...this.extraData].join(",");
    },
    allFilters() {
      const columnFilters = this.columns.filter((c) => c.filter).map((c) => c.filter);

      return [...columnFilters, ...this.extraFilters];
    },
    // Extra filters and filters for not-visible columns
    detachedFilters() {
      const columnFilters = this.columns
        .filter((c) => c.filter && !this.shouldShowColumn(c))
        .map((c) => c.filter);
      return [...columnFilters, ...this.extraFilters];
    },
    combinedParams() {
      const params = {
        ...this.filters,
        ...this.fetchParams,
        fields: this.fetchFields,
        per_page: this.itemsPerPage,
        page: this.currentPage,
      };
      if (this.sortBy) {
        params.order = (this.sortDesc ? "-" : "") + this.sortBy;
      }
      return params;
    },
    showExportButton() {
      return this.showGenerateCsv && this.$store.state.user.role === "admin";
    },
    activeFilterCount() {
      return Object.values(this.filters).filter((f) => f !== null).length;
    },
    differsFromDefault() {
      if (this.activeFilterCount !== Object.keys(this.initialFilters).length) {
        return true;
      }

      for (const filter in this.filters) {
        if (this.filters[filter] != this.initialFilters[filter]) {
          return true;
        }
      }

      const columnsToShowByDefault = this.columns.filter((c) => c.showByDefault).map((c) => c.key);
      if (columnsToShowByDefault.length !== this.columnsToShow.length) {
        return true;
      }

      for (const column of columnsToShowByDefault) {
        if (!this.columnsToShow.includes(column)) {
          return true;
        }
      }

      return false;
    },
  },
  watch: {
    combinedParams(newParams, oldParams) {
      if (JSON.stringify(newParams) !== JSON.stringify(oldParams)) {
        // We debounce here since params might change twice quickly: e.g. changing order direction
        // and param by clicking on a column header
        this.debouncedFetchItems();
      }
    },
    itemsPerPage() {
      this.updateState();
    },
    currentPage() {
      this.updateState();
    },
    sortBy() {
      this.updateState();
    },
    sortDesc() {
      this.updateState();
    },
    columnsToShow() {
      this.updateState();
    },
    filters: {
      handler: function () {
        this.currentPage = 1;
        if (this.syncFiltersWithUrl) {
          const setFilters = {};
          Object.keys(this.filters)
            .filter((key) => this.filters[key] !== undefined && this.filters[key] !== null)
            .forEach((k) => (setFilters[k] = this.filters[k]));
          if (JSON.stringify(router.currentRoute.query) !== JSON.stringify(setFilters)) {
            // Catch expected 'Redundant Navigation' error
            router.replace({ query: setFilters }).catch(() => {});
          }
        }
        this.updateState();
        this.$emit("filters-changed", this.filters);
      },
      deep: true,
    },
  },
  methods: {
    calculateTimeSinceLastFetch() {
      return (this.minutesSinceLastFetch = dayjs().diff(this.lastFetch, "minutes"));
    },
    // Removes unspecified filters keys
    trimFilters(extendedFilters) {
      const filters = {};

      // We cannot use the computed allFilters value, since this is called from data()
      const columnFilters = this.columns.filter((c) => c.filter).map((c) => c.filter);
      for (const filter of [...columnFilters, ...this.extraFilters]) {
        if (filter.field in extendedFilters) {
          filters[filter.field] = extendedFilters[filter.field];
        }
      }

      return filters;
    },

    shouldShowColumn(column) {
      return this.columnsToShowSet.has(column.key);
    },
    // to set filters programatically
    setFilters(filters) {
      this.filters = filters;
    },
    toggleFilters() {
      this.showFilters = !this.showFilters;
    },
    getFiltersFromUrl() {
      return this.trimFilters(router.currentRoute.query);
    },
    resetToDefaults() {
      this.currentPage = 1;
      this.filters = { ...this.initialFilters };
      this.columnsToShow = this.columns.filter((c) => c.showByDefault).map((c) => c.key);
    },
    getSavedColumnsToShowOrDefault(listState) {
      if (listState?.columnsToShow) {
        const validColumnKeys = new Set(this.columns.map((c) => c.key));
        const invalidColumnsToShow = listState.columnsToShow.filter((f) => !validColumnKeys.has(f));
        if (invalidColumnsToShow.length === 0) {
          return listState.columnsToShow;
        }
      }
      return this.columns.filter((c) => c.showByDefault).map((c) => c.key);
    },
    getSavedFiltersOrDefault(listState) {
      if (listState?.filters) {
        const validFilterFields = new Set(
          [...this.extraFilters, ...this.columns.filter((c) => c.filter).map((c) => c.filter)].map(
            (f) => f.field
          )
        );
        const invalidFilters = Object.keys(listState.filters).filter(
          (f) => !validFilterFields.has(f)
        );
        if (invalidFilters.length === 0) {
          return listState.filters;
        }
      }
      return { ...this.initialFilters };
    },
    refresh() {
      this.fetchItems();
      if (this.$refs.exportButton) {
        this.$refs.exportButton.reset();
      }
    },
    selectAll() {
      this.$refs.table.selectAllRows();
    },
    rowSelected(event) {
      this.selectedCount = event.length;
      this.$emit("row-selected", event);
    },
    toggleSelection(val) {
      if (val === true) {
        this.$refs.table.selectAllRows();
      } else if (val === false) {
        this.$refs.table.clearSelected();
      }
    },
    async fetchItems() {
      this.$emit("updating");
      this.isBusy = true;
      try {
        const {
          data: { data, total },
        } = await get(this.endpoint, {
          axiosRequestConfig: {
            params: this.combinedParams,
          },
          notifications: { action: "chargement de la liste" },
        });
        this.itemCount = data.length;
        this.total = total;
        this.lastFetch = this.$dayjs();
        this.isBusy = false;
        this.$emit("updated");
        this.showDetails = {};
        this.fetchedItems = data.map((item) => ({ ...item, _showDetails: false, _changed: false }));
      } catch (e) {
        this.isBusy = false;
        this.fetchedItems = [];
      }
    },
    isExternalUrl(column, item) {
      return column.urlFct(item).includes("http", 0);
    },
    isUrlValid(column, item) {
      if (!column || !column.urlFct) {
        return false;
      }

      try {
        return !!column.urlFct(item);
      } catch (e) {
        return false;
      }
    },
    rowClicked(item, index, event) {
      this.$emit("row-clicked", item);

      if (this.rowLink && !item.deleted_at) {
        if (event.ctrlKey) {
          const routeData = this.$router.resolve(`/admin/${this.endpoint}/${item[this.keyColumn]}`);
          window.open(routeData.href, "_blank");
        } else {
          this.$router.push(`/admin/${this.endpoint}/${item[this.keyColumn]}`);
        }
      }

      if (this.hasRowDetails && !this.rowDisabled(item)) {
        item._showDetails = !item._showDetails;
      }
    },
    updateState() {
      if (this.id) {
        this.$store.commit("paginatedTables/updateListState", {
          listId: this.id,
          sortBy: this.sortBy,
          sortDesc: this.sortDesc,
          filters: this.filters,
          perPage: this.itemsPerPage,
          currentPage: this.currentPage,
          columnsToShow: this.columnsToShow,
        });
      }
    },
    computeRowClasses(item) {
      if (!item) {
        return;
      }
      let classes = [];
      if (item.deleted_at) {
        classes.push("row-item-deleted");
      }
      if (this.rowDisabled(item)) {
        classes.push("row-item-disabled");
      }

      return classes;
    },
    getFetchedItem(item) {
      if (!item || !item[this.keyColumn]) {
        return undefined;
      }
      return this.fetchedItems.find((i) => i[this.keyColumn] === item[this.keyColumn]);
    },
    updateItem(item) {
      if (!item || !item[this.keyColumn]) {
        return;
      }

      let index = this.fetchedItems.findIndex((i) => i[this.keyColumn] === item[this.keyColumn]);
      if (index >= 0) {
        this.fetchedItems.splice(index, 1, item);
      } else {
        this.fetchedItems.unshift(item);
      }
    },
  },
};
</script>

<style lang="scss">
.refresh-button svg.b-icon {
  animation-duration: 1s;
}

.filter-list {
  .filter {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }

  &.card {
    margin: 0 0.4rem;
    position: relative;
    top: 0.5rem;
    border-radius: 0.5rem;
    background: transparentize($secondary, 0.9);
    box-shadow: inset 0 1px 3px transparentize($secondary, 0.5);
    border: 1px solid $light-grey;

    .card-body {
      padding: 1rem 1rem 0;

      .form-group {
        margin-bottom: 1rem;
        flex: 1;
      }
    }
  }
}

.paginated-table_button-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.25rem;
}

.paginated-table {
  &.table-responsive {
    margin-bottom: 0.5rem;
  }

  .details-chevron {
    color: $light;
    stroke: $light;
    stroke-width: 2;
    transition: transform 0.3s ease;

    &.details-showing {
      transform: rotate(90deg);
    }
  }

  &:not(.with-filters) {
    margin-top: -0.5rem;
  }

  tbody tr {
    &:not(.b-table-empty-row):not(.row-item-disabled) td {
      background: $white;
    }

    &.row-item-disabled {
      background-color: rgb(232, 234, 236);
      &:hover {
        background-color: rgb(232, 234, 236);
      }
      opacity: 0.8;
    }
    &.b-table-empty-row {
      background: $eggshell;
      td {
        color: $grey;
        border: 1px solid $light-grey;
        padding: 0.2rem;
      }
    }
  }
  .table {
    border-collapse: separate;
    border-spacing: 0 0.4rem;
    padding: 0 4px;

    // Show loading text when table refreshing witout any content
    &[aria-busy="true"] .b-table-empty-row td div {
      > div {
        display: none;
      }
      &:after {
        display: block;
        content: "Chargement...";
        text-align: center;
      }
    }

    &.row-clickable
      tbody
      tr:not(.b-table-empty-row):not(.b-table-details):not(.row-item-deleted):not(
        .row-item-disabled
      ) {
      cursor: pointer;

      &:hover {
        box-shadow:
          $small-shadow,
          0 0 1px 1px $locomotion-green;
        .details-chevron {
          color: $locomotion-green;
          stroke: $locomotion-green;
        }
      }
    }

    tbody {
      tr:not(.b-table-details) td {
        height: 3rem;
        font-weight: 600;
      }
      tr {
        td {
          position: relative;

          &:first-child {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
          }

          &:last-child {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
          }
        }

        &:not(.b-table-empty-row) {
          box-shadow: $small-shadow;
          border-radius: 8px;
        }

        &.b-table-details {
          position: relative;
          top: -0.55rem;
          box-shadow: $small-shadow;
          border-top-right-radius: 0;
          border-top-left-radius: 0;
          td:first-child,
          td:last-child {
            border-top-right-radius: 0;
            border-top-left-radius: 0;
          }
          td {
            padding: 0;
            height: 0;
          }
        }
        &.b-table-has-details {
          box-shadow: $small-shadow;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
          td:first-child,
          td:last-child {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
          }
        }

        &.b-table-has-details:hover + tr {
          box-shadow:
            $small-shadow,
            1px 1px 1px $locomotion-green,
            -1px 1px 1px $locomotion-green;
        }
        &.b-table-has-details:hover {
          box-shadow:
            $small-shadow,
            0 0 1px 1px $locomotion-green;
        }

        &.table-active {
          td {
            background-color: #ecfffe;
          }
          &:hover td {
            background: #ecfffe !important;
          }
        }
      }
    }

    thead {
      tr {
        box-shadow: none;
        top: 0.5rem;
        height: 1px;
        @supports (height: -moz-available) {
          height: 100%;
        }
      }
      th {
        font-size: 0.85rem;
        background: transparent;
        color: $content-neutral-secondary;
        padding: 0.75rem 0.5rem;
        font-weight: 400;

        @supports (height: -moz-available) {
          height: 100%;
        }
      }
    }

    th,
    td {
      height: 1px;
      padding: 0.4rem;
      font-size: 0.9rem;
      color: $dark;

      select,
      input,
      textarea {
        font-size: 0.9rem;
      }

      &.shrink-column {
        width: 1%;

        .form-control {
          min-width: 4rem;
          width: auto;
        }
      }
    }

    .header-wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .header-label {
        white-space: nowrap;
      }
    }

    td {
      vertical-align: middle;
      .select-wrapper {
        cursor: pointer;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        &:hover .custom-control-label::before {
          border-color: $locomotion-green;
        }
      }
    }

    th {
      vertical-align: top;
    }

    // Move order toggles to right when text is right aligned.
    th {
      padding-right: 0.2rem !important;
      padding-left: 0.2rem !important;
      background-position: left calc(0.75rem / 2) top 0.8rem !important;
    }
    th.b-table-sort-icon-left .header-label {
      padding-left: 1rem;
    }
    th.b-table-sort-icon-left.text-right {
      background-position: right calc(0.75rem / 2) top 0.8rem !important;
    }
    th.b-table-sort-icon-left.text-right .header-label {
      padding-right: 1rem;
      padding-left: 0;
    }
  }
}
</style>
