import LoanableSearch from "@/views/loanable/Search.vue";

export default [
  {
    path: "/search/:view",
    name: "loanable-list",
    component: LoanableSearch,
    // Get the ':view' as a prop
    props: true,
    meta: {
      auth: true,
      slug: "loanables",
      title: "titles.search",
      // Avoid jumping to top of page when switching between map and list view
      keepScroll: true,
      data: {
        loans: {
          loadEmpty: {},
        },
      },
    },
  },
];
