<template>
  <info-box
    class="loanable-info-box"
    :loading="loading"
    :big-img="loanable.image"
    :link="`/profile/loanables/${loanable.id}`"
    :title="loanable.name"
    :user-avatar="hasDifferentOwner ? loanable.owner.user : null"
  >
    <b-row class="flex-fill align-items-center" no-gutters align-h="between">
      <b-col cols="12" xl="8">
        <span class="loanable-info-box__owner" v-if="hasDifferentOwner"
          >{{ loanable.owner.user.full_name }}<br
        /></span>
        <span class="loanable-info-box__name">{{ loanable.name }}</span>

        <div class="mt-2">
          <availability-status :loanable="loanable" />
        </div>
      </b-col>

      <b-col class="loanable-info-box__actions mt-2 mt-xl-0" cols="12" xl="4">
        <icon-button
          size="sm"
          :disabled="loading"
          variant="ghost-secondary"
          v-if="hasButton('availability')"
          :to="`/profile/loanables/${loanable.id}#availability`"
          icon="calendar4-range"
        >
          Modifier les disponibilités
        </icon-button>

        <icon-button
          size="sm"
          :disabled="loading"
          variant="outline-danger"
          role="remove-item"
          v-if="hasButton('remove') && canDeleteLoanable"
          @click.prevent="disableLoanableModal"
        >
          Retirer
        </icon-button>
      </b-col>
    </b-row>
  </info-box>
</template>

<script>
import IconButton from "@/components/shared/IconButton.vue";
import UserAvatar from "@/components/User/Avatar.vue";
import InfoBox from "@/components/shared/InfoBox.vue";
import { canDeleteLoanable } from "@/helpers/permissions/loanables";
import LoanStatus from "@/components/Loan/Status.vue";
import AvailabilityStatus from "@/components/Loanable/AvailabilityStatus.vue";

export default {
  name: "LoanableInfoBox",
  components: { IconButton, AvailabilityStatus, LoanStatus, InfoBox, UserAvatar },
  props: {
    buttons: {
      type: Array,
      required: false,
      default() {
        return ["availability", "remove"];
      },
    },
    loanable: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      canDeleteLoanable: canDeleteLoanable(this.user, this.loanable),
    };
  },
  computed: {
    hasDifferentOwner() {
      if (!this.loanable.owner) {
        return false;
      }
      return this.loanable.owner.user.id !== this.user.id;
    },
  },
  methods: {
    disableLoanableModal() {
      this.$bvModal
        .msgBoxConfirm("Êtes-vous sûr-e de vouloir retirer ce véhicule de la plateforme?", {
          size: "sm",
          buttonSize: "sm",
          okTitle: "Oui, retirer",
          cancelTitle: "Annuler",
          okVariant: "danger",
          cancelVariant: "ghost-secondary",
          footerClass: "p-2 border-top-0",
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.disableLoanable();
          }
        });
    },
    async disableLoanable() {
      this.loading = true;
      try {
        await this.$store.dispatch("loanables/disable", this.loanable.id);
        this.$emit("disabled", this.loanable.id);
        this.$store.dispatch("dashboard/loadLoanables");
      } catch (e) {
      } finally {
        this.loading = false;
      }
    },
    hasButton(name) {
      return this.buttons.indexOf(name) > -1;
    },
  },
};
</script>

<style lang="scss">
@import "~bootstrap/scss/mixins/breakpoints";

.loanable-info-box {
  &__name {
    color: $black;
    font-size: 1.25rem;
    position: unset;
  }

  &__owner {
    color: $black;
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;

    @include media-breakpoint-up(xl) {
      justify-content: end;
    }
  }
}
</style>
