var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('paginated-table',{ref:"table",attrs:{"id":"profile-loans-table","columns":_vm.columns,"extra-filters":_vm.extraFilters,"extra-data":[
    'intention.status',
    'takeover.status',
    'pre_payment.status',
    'handover.status',
    'payment.status',
    'incidents.status',
    'extensions.status',
    'community.deleted_at',
    'loanable.can_edit',
    'loanable.timezone' ],"endpoint":"loans","fetch-params":{ for: 'profile' },"sync-filters-with-url":"","show-action-column":false},on:{"filters-changed":_vm.filtersUpdated,"row-clicked":function (item) { return _vm.$router.push(("/loans/" + (item.id))); }},scopedSlots:_vm._u([{key:"cell(status)",fn:function(ref){
  var item = ref.item;
return [_c('loan-status',{attrs:{"item":item}})]}},{key:"head-buttons",fn:function(){return [_c('b-dropdown',{attrs:{"right":"","text":_vm.modeText,"variant":"ghost-secondary","toggle-class":{ active: _vm.modeText !== 'Tous les emprunts' }}},[(_vm.modeText !== 'Tous les emprunts')?_c('b-dropdown-item',{on:{"click":_vm.showAllLoans}},[_vm._v(" Tous les emprunts ")]):_vm._e(),(_vm.modeText !== 'Mes emprunts')?_c('b-dropdown-item',{on:{"click":_vm.showMyLoans}},[_vm._v(" Mes emprunts ")]):_vm._e(),(_vm.modeText !== 'Emprunts de mes véhicules')?_c('b-dropdown-item',{on:{"click":_vm.showLoansOfMyLoanables}},[_vm._v(" Emprunts de mes véhicules ")]):_vm._e()],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }