<script>
export default {
  name: "lazy",
  data() {
    return {
      show: false,
      observer: null,
    };
  },
  props: {
    offset: {
      type: Number,
      default: 200,
    },
  },
  methods: {
    unobserve() {
      if (this.observer) {
        this.observer.unobserve(this.$el);
        this.observer = null;
      }
    },
  },
  mounted() {
    // If the element is in the viewport initially, it should be loaded immediately.
    const rect = this.$el.getBoundingClientRect();
    if (
      // offsetParent is set to null when not visible (display: hidden on any ancestor)
      this.$el.offsetParent !== null &&
      rect.top >= window.scrollY - this.offset &&
      rect.top < window.scrollY + window.innerHeight + this.offset &&
      rect.left >= window.scrollX - this.offset &&
      rect.left < window.scrollX + window.innerWidth + this.offset
    ) {
      this.show = true;
      this.$emit("load");
      return;
    }

    // Otherwise, setup an observer to load it only when we scroll to it.
    this.observer = new IntersectionObserver(
      (entries) => {
        // Check the last entry
        if (entries[entries.length - 1].isIntersecting) {
          this.show = true;
          this.unobserve();
          this.$emit("load");
        }
      },
      { rootMargin: `${this.offset}px` }
    );
    this.observer.observe(this.$el);
  },
  beforeDestroy() {
    this.unobserve();
  },
};
</script>
<template>
  <div>
    <slot v-if="show" />
  </div>
</template>
