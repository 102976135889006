<template>
  <layout-page name="login" wide bg-image centered box>
    <login-box />
  </layout-page>
</template>

<script>
import LoginBox from "@/components/Login/Box.vue";
import store from "../store";

export default {
  name: "Login",
  beforeRouteEnter(to, from, next) {
    if (!!store.state.user && !to.query.logout) {
      next("/app");
      return;
    }

    if (to.query.token) {
      store.commit("token", to.query.token);
      store.dispatch("loadUser").then(() => next("/app"));
      return;
    }

    next();
  },
  components: { LoginBox },
};
</script>

<style lang="scss"></style>
