<template>
  <div id="locomotion" :class="{ fullscreen: fullScreen }">
    <a href="#" class="update-banner" v-if="clientUpdateNeeded" @click.prevent.stop="reload">
      Une mise à jour de locomotion.app est disponible! Cliquez ici pour rafraîchir la page.
    </a>

    <layout-header v-if="!fullScreen" class="page__header" :title="pageTitle" />
    <div class="page__content">
      <keep-alive :include="this.$store.state.user ? ['Dashboard'] : []">
        <router-view />
      </keep-alive>
    </div>
    <layout-footer v-if="!fullScreen" class="page__footer" />
    <conditions-updated-toast />
  </div>
</template>

<script>
import Notification from "@/mixins/Notification";
import ConditionsUpdatedToast from "@/views/ConditionsUpdatedToast.vue";
import { capitalize } from "@/helpers/filters";

export default {
  name: "LocoMotion",
  components: { ConditionsUpdatedToast },
  mixins: [Notification],
  mounted() {
    if (this.$store.state.token && !this.$store.state.loading && !this.$store.state.loaded) {
      this.$store.dispatch("loadUser");
    }
  },
  computed: {
    clientUpdateNeeded() {
      if (!this.$store.state.clientVersionFromBackend) {
        return false;
      }

      const latestVersion = this.$store.state.clientVersionFromBackend.split(".");
      const appVersion = process.env.VUE_APP_VERSION.split(".");

      if (latestVersion.length < 3 || appVersion.length < 3) {
        console.error(
          `Semver for app is not as expected: from backend ${latestVersion}, from frontend ${appVersion}`
        );
        return false;
      }

      const majorLatestVersion = latestVersion[0];
      const minorLatestVersion = latestVersion[1];

      const majorAppVersion = appVersion[0];
      const minorAppVersion = appVersion[1];

      return (
        majorLatestVersion > majorAppVersion ||
        (majorLatestVersion === majorAppVersion && minorLatestVersion > minorAppVersion)
      );
    },
    pageTitle() {
      if (this.$route.meta && this.$route.meta.title) {
        return capitalize(this.$i18n.t(this.$route.meta.title));
      }

      return "";
    },
    fullScreen() {
      // We user window.location.search rather than $route.query since $route
      // is not available immediately upon first rendering, which causes the header to flash
      // in full screen.
      const searchParams = new URLSearchParams(window.location.search);
      return searchParams.get("full_screen");
    },
  },
  methods: {
    reload() {
      location.reload();
    },
  },
};
</script>

<style lang="scss">
.update-banner {
  display: block;
  position: sticky;
  padding: 0.5rem;
  text-align: center;
  background: $background-alert-positive;
  color: $content-alert-positive;
  top: 0;
  z-index: 10;
  box-shadow: $small-shadow;
}
</style>
