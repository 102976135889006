<template>
  <b-card no-body class="loan-form loan-actions loan-actions-intention">
    <b-card-header
      header-tag="header"
      role="tab"
      class="loan-actions__header"
      v-b-toggle.loan-actions-intention
    >
      <h2>
        <svg-waiting v-if="action.status === 'in_process' && !loanIsCanceled" />
        <svg-check v-else-if="action.status === 'completed'" />
        <svg-danger v-else-if="action.status === 'canceled' || loanIsCanceled" />

        Confirmation de l'emprunt
      </h2>

      <!-- Canceled loans: current step remains in-process. -->
      <span v-if="action.status === 'in_process' && loanIsCanceled">
        Emprunt annulé &bull; {{ item.canceled_at | datetime }}
      </span>
      <span v-else-if="action.status === 'in_process'"> En attente d'approbation </span>
      <span v-else-if="action.status === 'completed'">
        Approuvé &bull; {{ action.executed_at | datetime }}
      </span>
      <span v-else-if="action.status === 'canceled'">
        Refusé &bull; {{ action.executed_at | datetime }}
      </span>
    </b-card-header>

    <b-collapse
      id="loan-actions-intention"
      role="tabpanel"
      accordion="loan-actions"
      :visible="open"
    >
      <b-card-body>
        <div v-if="action.status === 'in_process' && loanIsCanceled">
          <p>L'emprunt a été annulé. Cette étape ne peut pas être complétée.</p>
        </div>

        <div v-else>
          <div v-if="isCoownerOrOwner">
            <b-row class="justify-content-center mb-3">
              <b-col xl="8" cols="12">
                <user-blurb :user="borrower.user" show-phone show-email />
              </b-col>
            </b-row>
            <p>
              {{ borrower.user.name }} veut emprunter {{ item.loanable.name }}.
              <template v-if="shownAsContact">
                Vos coordonnées sont visibles à l'emprunteur-se, mais n'hésitez pas à les contacter
                en premier pour clarifier les détails de l'emprunt.
              </template>
              <template v-else>
                Vos coordonnées ne sont pas partagées à l'emprunteur-se, mais n'hésitez pas à les
                contacter pour clarifier les détails de l'emprunt.
              </template>
            </p>
          </div>
          <div v-else-if="isBorrower" class="mb-3">
            <p>
              Merci d'avoir enregistré votre demande d'emprunt sur la plateforme! N'hésitez pas à
              contacter directement une personne responsable du véhicule. Il est plus vraisemblable
              qu'il ou elle accepte le prêt si vous faites connaissance en premier!
            </p>
            <div
              :class="{
                'contact-list-single': contacts.length === 1,
                'contact-list-multiple': contacts.length > 1,
              }"
            >
              <user-blurb
                v-for="contact of contacts"
                show-phone
                :user="contact.user"
                :key="contact.user.id"
                :description="contact.title"
              />
            </div>
          </div>

          <blockquote v-if="item.message_for_owner">
            <user-avatar :user="borrower.user" />
            {{ item.message_for_owner }}
          </blockquote>

          <blockquote v-if="action.message_for_borrower">
            <user-avatar :user="owner.user" />
            {{ action.message_for_borrower }}
          </blockquote>
        </div>

        <div v-if="action.status === 'in_process' && !loanIsCanceled">
          <div v-if="canAcceptLoan">
            <div class="loan-actions-intention__message_for_borrower text-center mb-3">
              <forms-validated-input
                type="textarea"
                name="message_for_borrower"
                v-model="action.message_for_borrower"
                label="Laissez un message à l'emprunteur (facultatif)"
              />
            </div>
          </div>

          <div class="loan-actions-intention__buttons text-center">
            <icon-button
              v-if="canAcceptLoan"
              size="sm"
              variant="success"
              class="mr-2"
              :onclick="completeIntention"
              :disabled="actionLoading"
            >
              Accepter
            </icon-button>

            <icon-button
              v-if="canRejectLoan"
              size="sm"
              variant="outline-danger"
              :disabled="actionLoading"
              :onclick="cancelIntention"
            >
              Refuser
            </icon-button>
          </div>

          <div class="loan-actions__alert" v-if="item.loanable.type === 'car'">
            <b-alert variant="warning" show>
              <p>
                Desjardins assurances ne couvrera le trajet que s'il est bien renseigné sur
                LocoMotion! Pensez à accepter et vérifier le pré-paiement de la réservation ici.
              </p>

              <p>
                <router-link to="/assurances-desjardins">
                  Voir les conditions d'assurances
                </router-link>
              </p>
            </b-alert>
          </div>
        </div>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import FormsValidatedInput from "@/components/Forms/ValidatedInput.vue";
import IconButton from "@/components/shared/IconButton.vue";
import UserAvatar from "@/components/User/Avatar.vue";

import LoanActionsMixin from "@/mixins/LoanActionsMixin";
import { canAcceptLoan, canRejectLoan } from "@/helpers/permissions/loans";
import UserBlurb from "@/components/User/UserBlurb.vue";
import LayoutLoading from "@/components/Layout/Loading.vue";
import { acceptLoan, rejectLoan } from "@/requests/loanRequests";

export default {
  name: "LoanActionsIntention",
  mixins: [LoanActionsMixin],
  components: {
    IconButton,
    LayoutLoading,
    UserBlurb,
    FormsValidatedInput,
    UserAvatar,
  },
  computed: {
    canAcceptLoan() {
      return canAcceptLoan(this.user, this.item);
    },
    canRejectLoan() {
      return canRejectLoan(this.user, this.item);
    },
    contacts() {
      return [
        { user: this.item.loanable.owner.user, title: "Propriétaire" },
        ...this.item.loanable.coowners.filter((c) => c.show_as_contact),
      ];
    },
    shownAsContact() {
      return !!this.contacts.find((c) => c.user.id === this.user.id);
    },
  },
  methods: {
    async completeIntention() {
      this.actionLoading = true;
      const acceptedLoan = await acceptLoan(this.item, this.action);
      this.actionLoading = false;

      if (acceptedLoan) {
        this.$emit("completed");
      }
    },
    async cancelIntention() {
      this.actionLoading = true;

      try {
        await rejectLoan(this.item.id, this.action);
        this.$emit("canceled");
      } finally {
        this.actionLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.contact-list-multiple {
  display: grid;
  gap: 0.5rem;
  grid-auto-columns: 1fr;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
}
.contact-list-single {
  display: flex;
  justify-content: center;
  > * {
    width: 100%;
    max-width: 20rem;
  }
}
</style>
