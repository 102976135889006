import { get, put } from "@/requests/server";
import axios from "axios";
import RestModule from "../RestModule";

export default new RestModule(
  "loans",
  {
    evaluatingPrice: false,
    exportFields: [
      "id",
      "status",
      "departure_at",
      "duration_in_minutes",
      "estimated_distance",
      "actual_distance",
      "alternative_to",
      "alternative_to_other",
      "borrower.user.id",
      "borrower.user.name",
      "borrower.user.last_name",
      "loanable.id",
      "loanable.owner.user.id",
      "loanable.owner.user.name",
      "loanable.owner.user.last_name",
      "intention.status",
      "handover.purchases_amount",
      "calendar_days",
      "canceled_at",
    ],
  },
  {
    async resume({ dispatch }, { id, params }) {
      await dispatch("update", { id, data: { status: "in_process", canceled_at: null }, params });
    },
    async test({ commit, state }, params) {
      const distance = state.item.handover?.mileage_end
        ? state.item.handover.mileage_end - state.item.takeover.mileage_beginning
        : state.item.estimated_distance;

      // duration_in_minutes can be modified directly before the start of the loan,
      // but actual_duration_in_minutes will have the accurate duration including extensions, which
      // can only be added once loan starts.
      // TODO: Calculate actual duration here and give estimation when adding an extension.
      const duration_in_minutes =
        state.item.extensions && state.item.extensions.length > 0
          ? state.item.actual_duration_in_minutes
          : state.item.duration_in_minutes;

      const mergedParams = {
        departure_at: state.item.departure_at,
        duration_in_minutes,
        estimated_distance: distance,
        loanable_id: state.item.loanable?.id,
        platform_tip: state.item.platform_tip,
        ...params,
      };

      if (state.item.borrower) {
        mergedParams.borrower_id = state.item.borrower.id;
        mergedParams.borrower_user_id = state.item.borrower.user.id;
      }

      if (state.item.handover?.purchases_amount && !mergedParams.purchases_amount) {
        mergedParams.purchases_amount = state.item.handover.purchases_amount;
      }

      if (state.item.community?.id) {
        mergedParams.community_id = state.item.community.id;
      }

      if (state.item.id) {
        mergedParams.loan_id = state.item.id;
      }

      // Avoid sending invalid requests.
      if (mergedParams.estimated_distance < 0 || mergedParams.duration_in_minutes < 15) {
        return;
      }

      commit("setEvaluatingPrice", true);

      try {
        const { data } = await get(`/loanables/${mergedParams.loanable_id}/test`, {
          axiosRequestConfig: { params: mergedParams },
          requestOptions: {
            cancelId: `evaluating-price-${mergedParams.loanable_id}`,
            expects: [403],
          },
          notifications: { action: "évaluation du coût de l'emprunt" },
          cleanupCallback: () => commit("setEvaluatingPrice", false),
        });

        commit("mergeItem", {
          loanable: {
            available: data.available,
          },
        });
        commit("patchItem", {
          borrower_invoice: data.borrower_invoice,
          owner_invoice: data.owner_invoice,
        });

        return data;
      } catch (e) {
        // Cancelling is expected here
        if (axios.isCancel(e)) {
          return;
        }
        throw e;
      }
    },
    async validate({ commit }, { loan, user }) {
      const response = await put(`/loans/${loan.id}/validate`, null, {
        notifications: {
          action: "validation des informations",
          onSuccess: "Données de l'emprunt validées!",
        },
      });

      if (response) {
        const { data } = response;

        if (user.id === loan.borrower.user.id) {
          commit("patchItem", {
            borrower_validated_at: data,
          });
        }
        if (user.id === loan.loanable.owner.user.id) {
          commit("patchItem", {
            owner_validated_at: data,
          });
        }
      }
    },
  },
  {
    setEvaluatingPrice(state, value) {
      state.evaluatingPrice = value;
    },
  }
);
