<script>
import PaymentDialog from "@/components/User/PaymentDialog.vue";
import Vue from "vue";

export default Vue.extend({
  name: "ProvisionModal",
  components: { PaymentDialog },
  props: {
    price: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    reloaduser: {
      type: Boolean,
      default: false,
    },
    actionName: {
      type: String,
      required: true,
    },
    minimumDefault: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    show() {
      this.$refs.modal.show();
    },
    hide() {
      this.$refs.modal.hide();
    },
  },
});
</script>

<template>
  <b-modal ref="modal" size="lg" hide-footer title="Ajoutez à votre solde">
    <div class="provision-summary">
      <div class="provision-operand">
        <div class="provision-box">
          <div>Coût du trajet</div>
          <div class="provision-amount">{{ price | currency }}</div>
        </div>
      </div>
      <div class="provision-operand">
        <div class="provision-symbol"><span>-</span></div>
        <div class="provision-box">
          <div>Votre solde actuel</div>
          <div class="provision-amount">{{ user.balance | currency }}</div>
        </div>
      </div>
      <div class="provision-operand">
        <div class="provision-symbol equals"><span>=</span></div>
        <div class="provision-box">
          <div>Minimum à ajouter</div>
          <div class="provision-amount provision-minimum">
            {{ (price - user.balance) | currency }}
          </div>
        </div>
      </div>
    </div>

    <payment-dialog
      :user="user"
      :price="price"
      @complete="
        () => {
          hide();
          $emit('complete');
        }
      "
      :loading="loading"
      :action-name="actionName"
      :reload-user="reloaduser"
      :minimum-default="minimumDefault"
    />
  </b-modal>
</template>

<style scoped lang="scss">
@import "~bootstrap/scss/mixins/breakpoints";
.provision-summary {
  display: flex;
  flex-flow: row;
  gap: 1rem;
  align-items: stretch;
  margin-bottom: 1rem;
  justify-content: center;

  .provision-operand {
    display: flex;
    flex-flow: row;
    gap: 1rem;
  }

  @include media-breakpoint-down(xs) {
    display: flex;
    flex-wrap: wrap;
  }

  .provision-symbol {
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .provision-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: $beige;
    padding: 1rem 1rem;
    border-radius: 0.5rem;
    text-align: center;
    box-shadow: $small-shadow;
    font-size: 0.9rem;
    gap: 0.25rem;
  }

  .provision-amount {
    font-size: 1.5rem;
  }

  .provision-minimum {
    color: $locomotion-green;
    font-weight: bold;
  }
}
</style>
