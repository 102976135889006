import state from "../store";
import { canSeeDeletedUser } from "@/helpers/permissions/users";
import { canSeeDeletedCommunity } from "@/helpers/permissions/communities";

function loanableLink(loanable) {
  if (loanable.deleted_at) {
    return `/admin/loanables?id=${loanable.id}&is_deleted=true`;
  }
  return `/admin/loanables/${loanable.id}`;
}

function userLink(user) {
  if (user.deleted_at) {
    if (canSeeDeletedUser(state.state.user)) {
      return `/admin/users?id=${user.id}&is_deleted=true`;
    }
    return null;
  }
  return `/admin/users/${user.id}`;
}

function communityLink(community) {
  if (community.deleted_at) {
    if (canSeeDeletedCommunity(state.state.user)) {
      return `/admin/communities?id=${community.id}&is_deleted=true`;
    }
    return null;
  }

  return `/admin/communities/${community.id}`;
}

function loanLoanableLink(loan) {
  return loanableLink(loan.loanable);
}

function loanOwnerLink(loan) {
  return userLink(loan.loanable.owner.user);
}

function loanBorrowerLink(loan) {
  return userLink(loan.borrower.user);
}

function loanCommunityLink(loan) {
  return communityLink(loan.community);
}

export {
  loanLoanableLink,
  loanBorrowerLink,
  loanOwnerLink,
  loanCommunityLink,
  userLink,
  loanableLink,
  communityLink,
};
