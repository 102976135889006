<template>
  <b-card no-body class="loan-form loan-actions loan-actions-takeover">
    <b-card-header
      header-tag="header"
      role="tab"
      class="loan-actions__header"
      v-b-toggle.loan-actions-takeover
    >
      <h2>
        <svg-danger
          v-if="(action.status === 'in_process' && loanIsCanceled) || action.status === 'canceled'"
        />
        <svg-waiting v-else-if="action.status === 'in_process'" />
        <svg-check v-else-if="action.status === 'completed'" />

        Informations avant de partir
      </h2>

      <span v-if="item.is_contested && action.status === 'in_process' && !loanIsCanceled">
        Bloqué
      </span>
      <span v-else>
        <!--
          Canceled loans: current step remains in-process.
          Canceled action means contestation. Give way to canceled-loan status.
        -->
        <span
          v-if="(action.status === 'in_process' || action.status === 'canceled') && loanIsCanceled"
        >
          Emprunt annulé &bull; {{ item.canceled_at | datetime }}
        </span>
        <span v-else-if="action.status == 'in_process'"> En attente </span>
        <span v-else-if="action.status === 'completed'">
          Complété &bull; {{ action.executed_at | datetime }}
        </span>
        <span v-else-if="action.status === 'canceled'">
          Contesté &bull; {{ action.executed_at | datetime }}
        </span>
      </span>
    </b-card-header>

    <b-collapse id="loan-actions-takeover" role="tabpanel" accordion="loan-actions" :visible="open">
      <b-card-body>
        <div
          v-if="(action.status === 'in_process' || action.status === 'canceled') && loanIsCanceled"
        >
          <p>L'emprunt a été annulé. Cette étape ne peut pas être complétée.</p>
        </div>

        <div v-else>
          <b-alert show variant="success" v-if="instructions">
            <div class="alert-heading">
              <h4>Instructions du propriétaire pour l'utilisation du véhicule</h4>
            </div>
            <div class="owner-instructions-text">
              <p>{{ instructions }}</p>
            </div>
          </b-alert>

          <div class="loan-actions-takeover__instructions">
            <validation-observer ref="observer" v-slot="{ passes }">
              <b-form
                :novalidate="true"
                class="loan-actions-takeover__form"
                @keydown.enter.prevent
                @submit.stop.prevent="passes(completeTakeover)"
              >
                <!-- Add message if user is borrower, but not owner. -->
                <step :number="1" :show-header="canChangeTakeoverInfo && isParticipant">
                  <template #title>
                    <template v-if="isBorrower && !isCoownerOrOwner">
                      <span v-if="item.loanable.details.report">
                        Prenez connaissance de l'état de l'auto et comparez avec la
                        <safe-file :file="item.loanable.details.report"
                          >fiche d'état de l'auto.</safe-file
                        >
                      </span>
                      <template v-else> Prenez connaissance de l'état de l'auto. </template>
                    </template>
                    <template v-else-if="isCoownerOrOwner">
                      <span v-if="item.loanable.details.report">
                        Assurez-vous que la
                        <safe-file :file="item.loanable.details.report"
                          >fiche d'état de l'auto</safe-file
                        >
                        est à jour. Vous pouvez la mettre à jour
                        <router-link :to="`/profile/loanables/${item.loanable.id}#report-section`">
                          ici</router-link
                        >.
                      </span>
                      <template v-else>
                        <span>
                          Prenez connaissance de l'état de votre véhicule et
                          <router-link
                            :to="`/profile/loanables/${item.loanable.id}#report-section`"
                          >
                            ajoutez la fiche d'état ici.
                          </router-link>
                        </span>
                      </template>
                    </template>
                  </template>
                </step>
                <step
                  :number="2"
                  title="Entrez le KM au compteur du tableau de bord, au départ."
                  :show-header="canChangeTakeoverInfo && isParticipant"
                >
                  <forms-validated-input
                    id="mileage_beginning"
                    name="mileage_beginning"
                    label="KM au compteur"
                    type="text"
                    :rules="{ required: true, min_value: 0 }"
                    placeholder=""
                    mask="#########"
                    :formatter="integer"
                    :disabled="!canChangeTakeoverInfo"
                    v-model="action.mileage_beginning"
                  />
                </step>
                <step
                  :number="3"
                  :show-header="canChangeTakeoverInfo && isParticipant"
                  title="
                      Ajoutez une photo du tableau de bord ou inscrivez le KM dans le carnet de bord
                      qui se trouve dans l'auto."
                >
                  <b-row v-if="canChangeTakeoverInfo" class="loan-actions-takeover__form__image">
                    <b-col lg="6">
                      <forms-image-uploader
                        label="Photo du tableau de bord"
                        field="image"
                        v-model="action.image"
                      />
                    </b-col>
                    <b-col lg="2" class="d-flex justify-content-center align-items-center">
                      <rounded-tag class="loan-actions-handover__instructions__alternative">
                        ou
                      </rounded-tag>
                    </b-col>
                    <b-col lg="4" class="d-flex justify-content-center align-items-center">
                      <img
                        class="loan-actions-handover__form__booklet"
                        src="/carnetdebord.png"
                        alt="carnet de bord"
                      />
                    </b-col>
                  </b-row>
                  <b-row v-else-if="action.image" class="loan-actions-takeover__form__image">
                    <safe-image :image="action.image" />
                  </b-row>
                </step>

                <b-row
                  class="loan-actions-takeover__buttons text-center"
                  v-if="canChangeTakeoverInfo"
                >
                  <b-col>
                    <icon-button
                      type="submit"
                      size="sm"
                      variant="success"
                      :loading="actionLoading"
                      :disabled="!isContested && startsInTheFuture"
                    >
                      <span v-if="isContested">Corriger</span>
                      <span v-else>Enregistrer</span>
                    </icon-button>

                    <div v-if="!isContested && startsInTheFuture">
                      <small class="text-muted">
                        {{ startInFutureText }}
                      </small>
                    </div>
                  </b-col>
                </b-row>

                <b-row class="loan-actions__alert" v-if="!action.executed_at && !loanIsCanceled">
                  <b-col>
                    <b-alert variant="warning" show>
                      Les informations de l'emprunt peuvent être modifiées jusqu'à 48h après sa
                      conclusion. À partir de ce moment, le coût de l'emprunt sera validé avec les
                      détails ci-dessus.
                    </b-alert>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </div>
        </div>

        <div v-if="!isContested">
          <div v-if="isContestable && !loanIsCanceled && canContestTakeoverInfo">
            <hr />

            <validation-observer ref="observer" v-slot="{ passes }">
              <b-form
                :novalidate="true"
                class="loan-actions-takeover__contestation"
                @submit.stop.prevent="passes(contestTakeover)"
              >
                <b-row>
                  <b-col lg="6">
                    <p>Cette information est-elle incorrecte?</p>
                    <p>
                      Pour la modifier, vous pouvez procéder à une "contestation". Par cette
                      procédure, un membre de l'équipe LocoMotion sera appelé à arbitrer la
                      résolution du conflit entre l'emprunteur et le propriétaire.
                    </p>
                  </b-col>

                  <b-col lg="6">
                    <forms-validated-input
                      id="comments_on_contestation"
                      name="comments_on_contestation"
                      type="textarea"
                      :rules="{ required: true }"
                      :rows="3"
                      label="Commentaires sur la contestation"
                      placeholder="Indiquer quelles sont les informations à corriger"
                      v-model="action.comments_on_contestation"
                    />
                  </b-col>
                </b-row>

                <b-row class="loan-actions-takeover__buttons text-center">
                  <b-col>
                    <icon-button
                      size="sm"
                      variant="outline-danger"
                      :loading="actionLoading"
                      type="submit"
                    >
                      Contester
                    </icon-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </div>
        </div>
        <div v-else-if="!loanIsCanceled">
          <hr />

          <p>Les données ont été contestées {{ action.comments_on_contestation ? `:` : `` }}</p>

          <b-alert variant="warning" v-if="action.comments_on_contestation" show>
            {{ action.comments_on_contestation }}
          </b-alert>

          <p>
            Un membre de l'équipe LocoMotion contactera les participant-e-s et ajustera les données.
          </p>

          <div v-if="canResolveContestedLoan" class="text-center">
            <icon-button
              type="submit"
              size="sm"
              variant="success"
              :loading="actionLoading"
              @click="completeTakeover"
            >
              Résoudre la contestation
            </icon-button>
          </div>
        </div>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import FormsImageUploader from "@/components/Forms/ImageUploader.vue";
import FormsValidatedInput from "@/components/Forms/ValidatedInput.vue";
import IconButton from "@/components/shared/IconButton.vue";

import LoanActionsMixin from "@/mixins/LoanActionsMixin";
import {
  canChangeTakeoverInfo,
  canContestTakeoverInfo,
  canResolveContestedLoan,
} from "@/helpers/permissions/loans";
import RoundedTag from "@/components/shared/RoundedTag.vue";
import Step from "@/components/Loan/Actions/Step.vue";
import { integer, shortDate, time } from "@/helpers/filters";
import SafeImage from "@/components/shared/SafeImage.vue";
import SafeFile from "@/components/shared/SafeFile.vue";
import { put } from "@/requests/server";
import dayjs from "@/helpers/dayjs";
import { getZonedTimes } from "@/helpers/loanTimezones";

export default {
  name: "LoanActionsTakeover",
  mixins: [LoanActionsMixin],
  components: {
    IconButton,
    SafeFile,
    SafeImage,
    Step,
    RoundedTag,
    FormsImageUploader,
    FormsValidatedInput,
  },
  data() {
    return {
      canResolveContestedLoan: canResolveContestedLoan(this.user, this.item),
      canContestTakeoverInfo: canContestTakeoverInfo(this.user, this.item),
    };
  },
  computed: {
    canChangeTakeoverInfo() {
      return canChangeTakeoverInfo(this.user, this.item);
    },
    startsInTheFuture() {
      return this.$second.isBefore(this.item.departure_at, "minute");
    },
    zonedTimes() {
      return getZonedTimes(this.item);
    },
    startInFutureText() {
      return `Il sera possible de démarrer l'emprunt après l'heure du début de la
                        réservation (${shortDate(this.zonedTimes.departureAt)} à
                        ${time(this.zonedTimes.departureAt)}${
                          this.zonedTimes.differsFromCurrentTimezone
                            ? " " + this.zonedTimes.timezoneAbbrev
                            : ""
                        }).`;
    },
    instructions() {
      return this.item.loanable.trusted_borrower_instructions ?? this.item.loanable.instructions;
    },
  },
  methods: {
    integer,
    async completeTakeover() {
      this.actionLoading = true;
      await put(`/loans/${this.item.id}/takeover/complete`, this.action, {
        notifications: { action: "début de l'emprunt" },
        cleanupCallback: () => (this.actionLoading = false),
      });
      this.$emit("completed");
    },
    async contestTakeover() {
      await put(`/loans/${this.item.id}/takeover/contest`, this.action, {
        notifications: { action: "contestation des informations" },
        cleanupCallback: () => (this.actionLoading = false),
      });
      this.$emit("canceled");
    },
  },
  watch: {
    "action.mileage_beginning": async function (mileageBeginning) {
      if (this.item.handover?.mileage_end) {
        await this.$store.dispatch("loans/test", {
          estimated_distance: this.item.handover.mileage_end - mileageBeginning,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.loan-actions-takeover {
  &__form {
    &__booklet {
      max-width: 100%;
      height: 15rem;
    }
  }

  &__instructions {
    &__alternative {
      padding: 0.25rem 0.5rem 0.5rem 0.5rem;
    }
  }
}
.owner-instructions-text {
  white-space: pre-wrap;
}
</style>
