<template>
  <awareness-banner class="donation" :image="image" :title="title">
    <slot></slot>

    <b-form-checkbox-group
      v-if="amountOptions && amountOptions.length > 0"
      buttons
      class="donation__button-row"
      :checked="selectedAmountOptions"
      @change="changeAmountOption"
    >
      <b-checkbox
        v-for="option in amountOptions"
        :key="option.value"
        class="donation__amount-button"
        :value="option.value"
      >
        <span class="donation__amount-button-title">{{ option.title }}</span>
        <span v-if="option.subtitle" class="donation__amount-button-subtitle">{{
          option.subtitle
        }}</span>
      </b-checkbox>
    </b-form-checkbox-group>

    <forms-validated-input
      :disabled="disabled"
      name="customAmount"
      type="currency"
      :min="0"
      :placeholder="$t('donation_custom_amount_placeholder')"
      :value="customAmount"
      @input="changeCustomAmount"
    />

    <slot name="under"></slot>
  </awareness-banner>
</template>

<script>
import AwarenessBanner from "@/components/shared/AwarenessBanner.vue";
import FormsValidatedInput from "@/components/Forms/ValidatedInput.vue";

export default {
  name: "Donation",
  components: {
    AwarenessBanner,
    FormsValidatedInput,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    // If no amount options, then only display custom amount.
    amountOptions: {
      type: Array,
      required: false,
      default: [],
    },
    amount: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    selectedAmountOptions() {
      return this.amountOptions.filter((o) => this.amount == o.value).map((o) => o.value);
    },

    customAmount() {
      // If the amount is in the options, then prefer activating button instead of displaying custom amount.
      if (this.amountOptions.filter((o) => this.amount == o.value).length > 0) {
        return null;
      }

      return this.amount;
    },
  },
  methods: {
    changeAmountOption(newSelection) {
      let selectedAmountOptions = newSelection.filter((a) => a != this.amount);

      if (selectedAmountOptions.length > 0) {
        this.$emit("amountChanged", selectedAmountOptions[0]);
      } else {
        this.$emit("amountChanged", null);
      }
    },
    changeCustomAmount(newValue) {
      this.$emit("amountChanged", newValue);
    },
  },
  i18n: {
    messages: {
      fr: {
        donation_custom_amount_placeholder: "Saisir un montant personnnalisé $",
      },
    },
  },
};
</script>

<style lang="scss">
@import "~bootstrap/scss/mixins/breakpoints";

.donation {
  /* Override image style. */
  &__button-row {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
  }

  &__amount-button {
    width: 5rem;
    height: 5rem;
    &.btn {
      border-radius: 0.5rem;
    }
  }

  &__amount-button-title {
    display: block;
    font-weight: bold;
    font-size: 1.25rem;
  }

  &__amount-button-subtitle {
    display: block;
  }

  /* For bootstrap-vue checkboxes */
  &__button-row.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
  &__button-row.btn-group > .btn-group:not(:last-child) > .btn,
  &__button-row.btn-group > .btn:not(:first-child),
  &__button-row.btn-group > .btn-group:not(:first-child) > .btn {
    border-radius: 0.5rem;
  }

  .form-group {
    margin-bottom: 0;
  }

  &__button-row.btn-group label.btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__button-row label.btn {
    border: 2px solid $locomotion-light-green;
    background-color: #fff;
    color: $locomotion-light-green;
  }

  /* States: {active, inactive} x {enabled, disabled} */
  &__button-row .btn:not(:disabled):not(.disabled):active,
  &__button-row .btn:not(:disabled):not(.disabled).active {
    background-color: $locomotion-light-green;
    border: 2px solid $locomotion-light-green;
    color: #fff;

    -webkit-box-shadow: none;
    box-shadow: none;
  }

  &__button-row .btn.disabled {
    background-color: #fff;
    border-color: $light !important;
    color: #7a7a7a;
  }

  &__button-row .btn.disabled.active {
    background-color: $light;
  }
}
</style>
