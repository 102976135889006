<template>
  <div class="loan__actions__buttons flex-column flex-md-row-reverse" v-if="!!item.id">
    <icon-button
      role="delete"
      v-if="item.status !== 'canceled'"
      :disabled="!canCancelLoan"
      :onclick="cancelLoanFunction"
      icon="x-octagon"
    >
      Annuler la réservation
    </icon-button>
    <icon-button
      v-if="canResumeLoan"
      variant="outline-success"
      icon="play-circle"
      :onclick="resumeLoanFunction"
    >
      Réactiver la réservation
    </icon-button>
    <icon-button
      v-if="canDeclareExtension"
      variant="outline-warning"
      :disabled="!hasReachedStep('takeover') || hasReachedStep('payment')"
      @click="addExtension"
      icon="calendar-plus"
    >
      Signaler un retard
    </icon-button>
    <icon-button
      v-if="canDeclareIncident"
      variant="outline-warning"
      :disabled="!hasReachedStep('takeover')"
      @click="$emit('incident')"
      icon="exclamation-triangle"
    >
      Signaler un incident
    </icon-button>
  </div>
</template>

<script>
import IconButton from "@/components/shared/IconButton.vue";
import LoanStepsSequence from "@/mixins/LoanStepsSequence";
import UserMixin from "@/mixins/UserMixin";
import {
  canCancelLoan,
  canDeclareExtension,
  canDeclareIncident,
  canResumeLoan,
} from "@/helpers/permissions/loans";

export default {
  name: "ActionButtons",
  components: { IconButton },
  mixins: [LoanStepsSequence, UserMixin],
  props: {
    // Item is a loan.
    item: {
      type: Object,
      required: true,
    },
    cancelLoanFunction: {
      type: Function,
      required: true,
    },
    resumeLoanFunction: {
      type: Function,
      required: false,
    },
  },
  computed: {
    canCancelLoan() {
      return canCancelLoan(this.user, this.item, this.$second);
    },
    canDeclareIncident() {
      return canDeclareIncident(this.user, this.item);
    },
    canResumeLoan() {
      return canResumeLoan(this.user, this.item);
    },
    canDeclareExtension() {
      return canDeclareExtension(this.user, this.item, this.$second);
    },
  },
  methods: {
    addExtension() {
      // Only a single unapproved extension is allowed at a time
      const activeExtension = this.item.extensions.find((e) => e.status === "in_process");

      if (activeExtension) {
        this.$store.commit("addNotification", {
          title: "Retard déjà demandé!",
          content:
            "Un seul retard peut-être demandé à la fois. Annulez le retard en cours et demandez-en un nouveau.",
          variant: "warning",
        });

        setTimeout(() => {
          const el = document.getElementById(`loan-extension-${activeExtension.id ?? "new"}`);
          this.$scrollTo(el);
        }, 10);

        return;
      }

      this.$emit("extension");
    },
  },
};
</script>

<style lang="scss">
.loan__actions__buttons {
  display: flex;
  gap: 0.5rem;
}
</style>
