<script>
import IconButton from "@/components/shared/IconButton.vue";
import { canCreateLoanable } from "@/helpers/permissions/loanables";
import Vue from "vue";
import Bike from "@/assets/svg/bike.svg";
import Car from "@/assets/svg/car.svg";
import Trailer from "@/assets/svg/trailer.svg";
import { userIsApproved } from "@/helpers/permissions/users";

export default Vue.extend({
  name: "LoanableTypeSelection",
  components: {
    IconButton,
    "svg-bike": Bike,
    "svg-car": Car,
    "svg-trailer": Trailer,
  },
  props: {
    loanable: {
      type: Object,
      required: true,
    },
    availableTypes: {
      type: Array,
      required: true,
    },
    ownerUser: {
      type: Object,
      required: true,
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    ownerUserCommunities() {
      return this.ownerUser?.communities ?? [];
    },
    isCreatorOwner() {
      return this.user.id === this.ownerUser.id;
    },
    canCreateLoanable() {
      return canCreateLoanable(this.user) || userIsApproved(this.user);
    },
    allTypesAllowed() {
      return (
        this.availableTypes.includes("car") &&
        this.availableTypes.includes("trailer") &&
        this.availableTypes.includes("bike")
      );
    },
    communitiesWithChatGroup() {
      return this.user.communities.filter((c) => !!c.chat_group_url);
    },
  },
});
</script>

<template>
  <div>
    <div v-if="!canCreateLoanable">
      Vous devez être approuvé-e dans une communauté pour ajouter un véhicule.
    </div>
    <div v-else>
      <h3 class="mb-4">Quel type de véhicule voulez-vous partager?</h3>

      <div class="loanable-types">
        <icon-button
          variant="outline-success"
          :disabled="!availableTypes.includes('car')"
          @click="() => (loanable.type = 'car')"
        >
          <svg-car />
          <div>Auto <span v-if="!availableTypes.includes('car')">*</span></div>
        </icon-button>
        <icon-button
          variant="outline-success"
          :disabled="!availableTypes.includes('bike')"
          @click="() => (loanable.type = 'bike')"
        >
          <svg-bike />
          <div>Vélo <span v-if="!availableTypes.includes('bike')">*</span></div>
        </icon-button>
        <icon-button
          variant="outline-success"
          :disabled="!availableTypes.includes('trailer')"
          @click="() => (loanable.type = 'trailer')"
        >
          <svg-trailer />
          <div>Remorque <span v-if="!availableTypes.includes('trailer')">*</span></div>
        </icon-button>
      </div>

      <div v-if="!allTypesAllowed" class="mt-4">
        <div v-if="ownerUserCommunities.length > 1">
          <template v-if="isCreatorOwner">
            * Vos communautés ne permettent pas le partage de certains types de véhicules.
            Impliquez-vous dans le comité de votre quartier pour développer le partage de ces
            véhicules!
            <div class="mt-2" v-if="communitiesWithChatGroup.length > 0">
              <strong>Groupes de discussions</strong>
              <ul>
                <li v-for="c in communitiesWithChatGroup" :key="c.id">
                  <a :href="c.chat_group_url" target="_blank">{{ c.name }}</a>
                </li>
              </ul>
            </div>
          </template>
          <template v-else>
            * Les communautés du propriétaire ({{ ownerUser.full_name }}) ne permettent pas le
            partage de certains types de véhicules.
          </template>
        </div>
        <div v-else-if="ownerUserCommunities.length === 1">
          * {{ ownerUserCommunities[0].name }} ne permet pas le partage de certains types de
          véhicules.
          <template v-if="isCreatorOwner">
            <a
              v-if="ownerUserCommunities[0].chat_group_url"
              :href="ownerUserCommunities[0].chat_group_url"
              target="_blank"
            >
              Impliquez-vous dans le comité
            </a>
            <span v-else>Impliquez-vous dans le comité</span>
            pour développer le partage de ces véhicules.
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.loanable-types {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;

  .btn {
    border-width: 3px;
    flex-direction: column;
    border-radius: 0.5rem;
    svg {
      fill: currentColor;
      min-width: 5rem;
    }
  }
}
</style>
