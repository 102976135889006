<template>
  <paginated-table
    endpoint="invoices"
    :columns="columns"
    :fetch-params="{ 'user.id': 'me' }"
    @row-clicked="showInvoice"
    initial-sort-by="created_at"
    :initial-sort-desc="true"
    :show-generate-csv="false"
    :show-action-column="false"
  >
    <template #cell(paid_at)="{ item }">
      {{ item.paid_at ? "✓" : "✗" }}
    </template>
  </paginated-table>
</template>

<script>
import Authenticated from "@/mixins/Authenticated";
import UserMixin from "@/mixins/UserMixin";

import locales from "@/locales";
import PaginatedTable from "@/components/shared/PaginatedTable.vue";
import { Column } from "@/components/shared/PaginatedTableColumns";

export default {
  name: "ProfileInvoices",
  components: { PaginatedTable },
  mixins: [Authenticated, UserMixin],
  data() {
    return {
      columns: [
        Column.withoutFilter("id", "ID", "id", { showByDefault: false }),
        new Column("created_at", "Date", "date"),
        Column.withoutFilter("paid_at", "Payée", "text"),
        new Column("total", "Total", "currency"),
        new Column("total_with_taxes", "Total avec taxes", "currency"),
      ],
    };
  },
  i18n: {
    messages: {
      en: {
        ...locales.en.invoices,
        ...locales.en.forms,
      },
      fr: {
        ...locales.fr.invoices,
        ...locales.fr.forms,
      },
    },
  },
  methods: {
    showInvoice(item) {
      this.$router.push(`/profile/invoices/${item.id}`);
    },
  },
};
</script>

<style lang="scss">
.profile-invoices__invoices__invoice {
  margin-bottom: 20px;
}
</style>
