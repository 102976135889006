<template>
  <div class="instructions">
    <p>Veuillez téléverser une des preuves d’identité avec photo suivantes*&nbsp;:</p>
    <ul>
      <li><icons-check class="accepted" /> Permis de conduire;</li>
      <li>
        <icons-check class="accepted" />
        Carte d’assurance maladie (RAMQ);
      </li>
      <li><icons-check class="accepted" /> Passeport.</li>
    </ul>
    <forms-files-uploader
      no-label
      class="mb-0"
      center
      :label="$t('fields.identity_proof')"
      :disabled="loading"
      field="identity_proof"
      v-model="user.identity_proof"
      required
    />
  </div>
</template>

<script>
import IconsCheck from "@/assets/icons/check.svg";

import FormsFilesUploader from "@/components/Forms/FilesUploader.vue";

import locales from "@/locales";

export default {
  name: "IdentityProofInput",
  components: {
    "icons-check": IconsCheck,
    FormsFilesUploader,
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  i18n: {
    messages: {
      en: {
        ...locales.en.communities,
        ...locales.en.forms,
      },
      fr: {
        ...locales.fr.communities,
        ...locales.fr.forms,
      },
    },
  },
  methods: {
    submit(...params) {
      this.$emit("submit", ...params);
    },
  },
};
</script>
<style lang="scss" scoped>
.accepted {
  fill: $success;
}

.instructions {
  ul {
    list-style-type: none;
    padding-left: 1rem;
    li {
      margin-bottom: 0.25rem;
    }
  }
}
</style>
