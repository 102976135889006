<template>
  <layout-page fluid="lg" name="profile">
    <b-row>
      <b-col md="3">
        <profile-sidebar />
      </b-col>

      <b-col md="9" :style="{ 'view-transition-name': 'content' }">
        <router-view />
      </b-col>
    </b-row>
  </layout-page>
</template>

<script>
import ProfileSidebar from "@/components/Profile/Sidebar.vue";

import Authenticated from "@/mixins/Authenticated";
import UserMixin from "@/mixins/UserMixin";

const routeGuard = (to, from, next) => {
  if (to.name === "profile") {
    next("/profile/locomotion");
  } else {
    next();
  }
};

export default {
  name: "Account",
  mixins: [Authenticated, UserMixin],
  components: {
    ProfileSidebar,
  },
  beforeRouteEnter: routeGuard,
  beforeRouteUpdate: routeGuard,
  beforeRouteLeave(to, from, next) {
    // Set the root store as not loaded to force a reload of the user
    this.$store.commit("loaded", false);
    next();
  },
  computed: {
    pageTitle() {
      return this.$i18n.t(`profile.${this.$route.meta.title}`);
    },
  },
};
</script>

<style lang="scss">
.profile.page {
  .page__content {
    padding-top: 45px;
    padding-bottom: 45px;
  }
}
</style>
