<template>
  <b-toast
    toast-class="conditions-toast"
    variant="warning"
    visible
    v-if="showToast"
    no-auto-hide
    no-close-button
    solid
    :title="$t('conditions_updated_title')"
  >
    <p>{{ $t("conditions_updated_text") }}</p>

    <dl>
      <template v-for="conditions in unacceptedConditionsUpdates">
        <dt>{{ conditions | date }}</dt>
        <dd>
          {{ $t(`updates.${conditions}.text`) }}
          <a :href="$t(`updates.${conditions}.link`)">{{ $t("link") }}</a>
        </dd>
      </template>
    </dl>

    <i18n path="read_all_conditions" tag="p">
      <router-link to="/conditions">{{ $t("conditions") }}</router-link>
    </i18n>

    <div class="d-flex justify-content-between">
      <div>
        <icon-button icon="check2-square" :onclick="acceptConditions" variant="success" size="sm">
          {{ $t("accept_updates_button") }}
        </icon-button>
      </div>
      <icon-button
        variant="link"
        size="sm"
        class="text-muted align-self-end"
        to="/profile/account#deactivate"
      >
        {{ $t("unregister_link") }}
      </icon-button>
    </div>
  </b-toast>
</template>

<script>
import IconButton from "@/components/shared/IconButton.vue";
import conditions from "@/locales/views/Conditions";
import { post } from "@/requests/server";
export default {
  name: "ConditionsUpdatedToast",
  components: { IconButton },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    currentAcceptationDate() {
      return this.$dayjs(this.$store.state.user.conditions_accepted_at);
    },
    showToast() {
      // Avoid showing the toast on the conditions page, which would make it unreadable on mobile.
      if (this.$route.name === "conditions") {
        return false;
      }

      if (!this.$store.state.loaded || !this.$store.state.user || !this.mostRecentUpdate) {
        return false;
      }

      return this.currentAcceptationDate.isBefore(this.mostRecentUpdate);
    },
    mostRecentUpdate() {
      return this.pastConditionsUpdates.at(-1);
    },
    unacceptedConditionsUpdates() {
      return this.pastConditionsUpdates.filter((d) => this.currentAcceptationDate.isBefore(d));
    },
    pastConditionsUpdates() {
      const today = this.$dayjs();
      return Object.keys(conditions.fr.updates)
        .sort()
        .filter((d) => today.isAfter(d));
    },
  },

  methods: {
    async acceptConditions() {
      this.loading = true;
      await post("/acceptConditions", null, {
        notifications: { action: "approbation des conditions d'utilisation" },
        cleanupCallback: () => (this.loading = false),
      });
      await this.$store.dispatch("loadUser");
      this.$store.commit("addNotification", {
        title: "Succès",
        content: "Conditions d'utilisation acceptées!",
        variant: "success",
      });
    },
  },

  i18n: {
    messages: {
      fr: {
        ...conditions.fr,
      },
      en: {
        ...conditions.en,
      },
    },
  },
};
</script>

<style scoped lang="scss">
.conditions-toast {
  max-height: calc(100vh - 1rem);
  overflow: auto !important;
}
</style>
