<template>
  <div class="dashboard-loan-history">
    <b-dropdown
      variant="white-secondary"
      toggle-class="dashboard-h3 toggle px-0"
      :text="widgetTitle"
      v-model="loanHistoryType"
    >
      <b-dropdown-item :active="loanHistoryType === 'past'" @click="loanHistoryType = 'past'">
        Passés
      </b-dropdown-item>
      <b-dropdown-item :active="loanHistoryType === 'ongoing'" @click="loanHistoryType = 'ongoing'">
        En cours
      </b-dropdown-item>
      <b-dropdown-item
        :active="loanHistoryType === 'upcoming'"
        @click="loanHistoryType = 'upcoming'"
      >
        À venir
      </b-dropdown-item>
      <b-dropdown-item :active="loanHistoryType === 'waiting'" @click="loanHistoryType = 'waiting'">
        Nouveaux
      </b-dropdown-item>
    </b-dropdown>

    <div v-if="loans.length > 0" class="dashboard-loan-history__loans">
      <ul class="dashboard-loan-history__loans" v-for="loan in loans" :key="loan.id">
        <li class="dashboard-loan-history__loans__loan">
          <router-link :to="`/loans/${loan.id}`">{{ loan.loanable.name }}</router-link>
          <br />
          <span>{{ getZonedTimes(loan).departureAt | date }}</span>
          <br />
          <span v-if="loan.borrower.id === borrower.id">{{
            loan.loanable.owner.user.full_name
          }}</span>
          <span v-else>{{ loan.borrower.user.full_name }}</span>
          <br />
          <template v-if="loan.status === 'completed'">
            <span v-if="loan.borrower.id === borrower.id">
              Payé {{ -loan.borrower_total | currency }}
            </span>
            <span v-else> Reçu {{ loan.owner_total | currency }} </span>
          </template>
        </li>
      </ul>
    </div>
    <div v-else class="dashboard-loan-history__loans">
      <p class="muted">Aucun trajet.</p>
    </div>
    <transition name="fade">
      <div v-if="$store.state.dashboard.loansLoading" style="height: 3rem">
        <layout-loading class="d-block" full-height />
      </div>
    </transition>
  </div>
</template>

<script>
import { getZonedTimes } from "@/helpers/loanTimezones";

export default {
  name: "DashboardLoanHistory",
  methods: { getZonedTimes },
  props: {
    borrower: {
      type: Object,
      required: true,
    },
    ongoingLoans: {
      type: Array,
      required: true,
    },
    pastLoans: {
      type: Array,
      required: true,
    },
    upcomingLoans: {
      type: Array,
      required: true,
    },
    waitingLoans: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loanHistoryType: "past",
    };
  },
  computed: {
    loans() {
      switch (this.loanHistoryType) {
        case "upcoming":
          return this.upcomingLoans;
        case "waiting":
          return this.waitingLoans;
        case "ongoing":
          return this.ongoingLoans;
        case "past":
        default:
          return this.pastLoans;
      }
    },
    widgetTitle() {
      switch (this.loanHistoryType) {
        case "upcoming":
          return "Trajets à venir";
        case "waiting":
          return "Nouveaux trajets";
        case "ongoing":
          return "Trajets en cours";
        case "past":
          return "Trajets passés";
        default:
          return "Mes trajets";
      }
    },
  },
};
</script>

<style lang="scss">
.dashboard-loan-history {
  .toggle {
    display: flex;
    align-items: center;
  }
  .dropdown-item {
    line-height: 1.5rem;
  }

  &__loans,
  &__link {
    font-size: 13px;
  }

  &__loans {
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin-bottom: 1rem;
  }
}
</style>
