<template>
  <div class="instructions">
    <p>Veuillez téléverser une des preuves d’adresse suivantes*&nbsp;:</p>
    <ul>
      <li>
        <icons-check class="accepted" /> Permis de conduire (recto ET verso si l’adresse est au
        verso{{
          duplicateDriversLicense
            ? ", une deuxième fois si vous l'avez mis pour la preuve d'identité"
            : ""
        }});
      </li>
      <li>
        <icons-check class="accepted" />
        Une facture d’un service public (électricité, télécommunication, gaz);
      </li>
      <li>
        <icons-check class="accepted" /> Un document émis par un gouvernement (municipal, provincial
        ou fédéral);
      </li>
      <li>
        <icons-check class="accepted" /> Un document d’une institution bancaire (mais pas de relevé
        de carte de crédit);
      </li>
      <li><icons-check class="accepted" /> Un bulletin scolaire ou relevé de note.</li>
    </ul>
    <p>
      Le fichier joint doit montrer clairement votre
      <strong>nom, votre adresse et la date</strong>. Il doit inclure
      <strong>l’entête officielle</strong> de l’organisation émettrice. Il doit dater de moins d’un
      an ou ne pas être expiré.
    </p>

    <forms-files-uploader
      no-label
      :label="$t('fields.residency_proof')"
      class="mb-0"
      center
      field="residency_proof"
      v-model="user.residency_proof"
      required
    />
  </div>
</template>

<script>
import IconsCheck from "@/assets/icons/check.svg";
import FormsFilesUploader from "@/components/Forms/FilesUploader.vue";
import locales from "@/locales";

export default {
  name: "ResidencyProofInput",
  components: {
    "icons-check": IconsCheck,
    FormsFilesUploader,
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    user: {
      type: Object,
      required: true,
    },
    duplicateDriversLicense: {
      type: Boolean,
      default: false,
    },
  },
  i18n: {
    messages: {
      en: {
        ...locales.en.communities,
        ...locales.en.forms,
      },
      fr: {
        ...locales.fr.communities,
        ...locales.fr.forms,
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.accepted {
  fill: $success;
}

.instructions {
  ul {
    list-style-type: none;
    padding-left: 1rem;
    li {
      margin-bottom: 0.25rem;
    }
  }
}
</style>
