<template>
  <b-form-group class="mb-2 mt-2" label="Statut" label-cols="auto">
    <b-form-select v-model="selected" :options="options" switches @change="optionsChanged">
    </b-form-select>
  </b-form-group>
</template>

<script>
export default {
  name: "LoanFilters",
  data() {
    return {
      selected: "completed,in_process,canceled",
      options: [
        { text: "Tous", value: "completed,in_process,canceled" },
        { text: "Complétés", value: "completed" },
        { text: "Annulés", value: "canceled" },
        { text: "En cours", value: "in_process" },
      ],
    };
  },
  methods: {
    optionsChanged(newValue) {
      this.$emit("update", {
        status: newValue,
      });
    },
  },
  watch: {
    value(newValue) {
      this.selected = newValue.status;
    },
  },
};
</script>

<style scoped></style>
