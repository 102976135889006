var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('paginated-table',{ref:"pricingsTable",staticClass:"mt-4",attrs:{"label":"Tarifications","endpoint":"pricings","columns":_vm.columns,"fetch-params":_vm.pricingsFetchParams,"initial-filters":_vm.initialFilters,"extra-data":_vm.pricingsExtraData,"has-row-details":"","new-item":_vm.newPricing,"row-disabled":function (item) { return _vm.communityId && item && item.is_global; },"editable-items":"","show-generate-csv":false},scopedSlots:_vm._u([{key:"head-buttons",fn:function(){return [_c('icon-button',{attrs:{"role":"add"},on:{"click":_vm.addPricing}},[_vm._v(" Ajouter ")])]},proxy:true},{key:"cell(pricing_loanable_types)",fn:function(ref){
var item = ref.item;
return [_c('pricing-loanable-types',{attrs:{"pricing":item}})]}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [(_vm.communityId && !item.community_id)?_c('b-badge',{attrs:{"variant":"secondary"}},[_vm._v("Global")]):_vm._e(),(!item.id)?_c('b-badge',{attrs:{"variant":"warning"}},[_vm._v("Nouveau")]):(item._changed)?_c('b-badge',{attrs:{"variant":"warning"}},[_vm._v("Non enregistré")]):_vm._e(),_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"row-details",fn:function(ref){
var item = ref.item;
var initialItem = ref.initialItem;
var updateItem = ref.updateItem;
return [_c('pricing-form',{attrs:{"pricing":item,"initial-pricing":initialItem,"on-pricing-saved":updateItem,"available-loanable-types":_vm.availableLoanableTypes,"exempt-from-contributions":_vm.exemptFromContributions,"is-private-community":_vm.isPrivateCommunity},on:{"change":_vm.pricingChanged,"created":_vm.pricingCreated,"deleted":_vm.$refs.pricingsTable.refresh,"cancel-create":function($event){_vm.newPricing = null}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }