<template>
  <paginated-table
    id="admin-loanables-table"
    ref="table"
    endpoint="loanables"
    :fetch-params="{
      for: 'admin',
    }"
    :label="$tc('loanable', 2)"
    :columns="columns"
    :extra-filters="extraFilters"
    sync-filters-with-url
    row-link
  >
    <template #head-buttons>
      <icon-button v-if="canCreateLoanable" to="/admin/loanables/new" role="add">
        {{ $t("create_loanable") | capitalize }}
      </icon-button>
    </template>

    <template #cell(actions)="{ item }">
      <admin-list-actions
        v-if="!item.owner.user.deleted_at"
        slug="loanables"
        :item-name="item.name"
        :item="item"
        @change="$refs.table.refresh"
        :restore-params="restoreParams"
      >
        <template #destroy-question>
          <div>
            <strong>Êtes-vous sûr-e de vouloir archiver {{ item.name }}?</strong>
            <p class="mt-2 mb-0">
              Assurez-vous qu'il n'y a pas
              <router-link
                target="_blank"
                :to="`/admin/loans?loanable.id=${item.id}&status=in_process`"
                >d'emprunts en cours pour ce véhicule.
              </router-link>
            </p>
          </div>
        </template>
        <template #restore-question>
          <div>
            <strong>Êtes-vous sûr-e de vouloir restaurer {{ item.name }}?</strong>
            <b-form-checkbox class="mt-3" v-model="restoreParams.restore_availability">
              Restaurer les disponibilités?
            </b-form-checkbox>
          </div>
        </template>
      </admin-list-actions>
      <div v-else>
        <!-- If user is deleted we do not want a dropdown to be shown. This empty div is to remove
          the default from paginated table. -->
      </div>
    </template>
  </paginated-table>
</template>

<script>
import AdminListActions from "@/components/Admin/ListActions.vue";
import IconButton from "@/components/shared/IconButton.vue";
import locales from "@/locales";
import PaginatedTable from "@/components/shared/PaginatedTable.vue";
import { canCreateLoanable } from "@/helpers/permissions/loanables";
import { capitalize } from "@/helpers/filters";
import { loanBorrowerLink, userLink } from "@/helpers/adminLinks";
import { Column, Filter } from "@/components/shared/PaginatedTableColumns";

export default {
  name: "AdminLoanables",
  methods: { capitalize },
  components: {
    IconButton,
    PaginatedTable,
    AdminListActions,
  },
  data() {
    return {
      extraFilters: [
        new Filter("accessibleInCommunity", "Communauté", "relation", {
          relation: "communities",
          label: "name",
          field: "id",
          params: { for: "admin" },
        }),
        new Filter("is_deleted", "Archivé", "boolean"),
      ],

      columns: [
        new Column("id", "ID", "id"),
        new Column("name", "Nom", "text"),
        Column.withSelect("type", "Type", [
          { value: null, label: "Tous" },
          { value: "car", label: "Auto" },
          { value: "trailer", label: "Remorque" },
          { value: "bike", label: "Vélo" },
        ]),
        new Column("owner.user.full_name", "Propriétaire", "text", {
          urlFct: (loanable) => userLink(loanable.owner.user),
        }),
        new Column("current_loan.id", "Emprunt en cours", "text", {
          urlFct: (loanable) => `/admin/loans/${loanable.current_loan?.id}`,
          sortable: false,
        }),
        new Column("current_loan.borrower.user.id", "ID de l'emprunteur\u2011se", "id", {
          showByDefault: false,
        }),
        new Column("current_loan.borrower.user.full_name", "Emprunteur\u2011se", "text", {
          urlFct: (loanable) => loanBorrowerLink(loanable.current_loan),
          sortable: false,
        }),
        Column.withSelect(
          "availability_status",
          "Statut d'affichage",
          [
            { value: null, label: "Tous" },
            {
              value: "no_approved_communities",
              label: "Propriétaire sans communauté",
              variant: "warning",
            },
            { value: "wrong_type", label: "Type non partagé", variant: "danger" },
            { value: "no_availabilities", label: "Aucune disponibilité", variant: "warning" },
            { value: "unpublished", label: "Non publié", variant: "secondary" },
            { value: "has_availabilities", label: "Affiché", variant: "success" },
          ],
          { sortable: false }
        ),
        new Column("deleted_at", "Date de supression", "date", {
          showByDefault: false,
        }),
        Column.withSelect(
          "sharing_mode",
          "Mode de partage",
          [
            { value: null, label: "Tous" },
            { value: "on_demand", label: capitalize(this.$t("sharing_modes.on_demand")) },
            { value: "self_service", label: capitalize(this.$t("sharing_modes.self_service")) },
            { value: "hybrid", label: capitalize(this.$t("sharing_modes.hybrid")) },
          ],
          { showByDefault: false }
        ),
      ],
      restoreParams: {
        restore_availability: false,
      },
    };
  },
  computed: {
    loggedInUser() {
      return this.$store.state.user;
    },
    canCreateLoanable() {
      return canCreateLoanable(this.loggedInUser);
    },
  },
  i18n: {
    messages: {
      en: {
        ...locales.en.loanables,
        ...locales.en.forms,
      },
      fr: {
        ...locales.fr.loanables,
        ...locales.fr.forms,
      },
    },
  },
};
</script>

<style lang="scss"></style>
