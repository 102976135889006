var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
var reset = ref.reset;
return [_c('b-form',{staticClass:"form",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();passes(function () { return _vm.sendInvitations(reset); })}}},[_c('h2',{staticClass:"mb-4"},[_vm._v("Inviter à rejoindre LocoMotion "+_vm._s(_vm.communityName))]),_c('forms-validated-input',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCommunityId),expression:"showCommunityId"}],attrs:{"value":_vm.communityId,"type":"relation","name":"community_id","label":"Communauté","rules":{
          required: true,
        },"query":{
          slug: 'communities',
          value: 'id',
          text: 'name',
          params: {
            for: 'admin',
          },
        }},on:{"relation":_vm.setCommunityId,"search-object-updated":_vm.setCommunityName}}),_c('forms-validated-input',{attrs:{"label":"courriels","type":"multiple-emails","placeholder":"la.voisine@example.com","name":"emails","rules":{
          required: true,
        }},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Faites découvrir les options de transport actif et partagé de votre communauté à plus de personnes! ")]},proxy:true}],null,true),model:{value:(_vm.emails),callback:function ($$v) {_vm.emails=$$v},expression:"emails"}}),_c('forms-validated-input',{attrs:{"label":"Message d'invitation personnalisé (optionnel)","placeholder":"Salut Mathieu!\n\nOn s’est vus à la distribution de fleurs samedi passé.\nFais-moi signe si tu as besoin d’aide pour ton inscription.\n\nNicole au (438) 555-0123","type":"textarea","rules":{
          required: false,
        },"name":"message","rows":3,"max-rows":10},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_c('forms-validated-input',{attrs:{"label":"","type":"checkbox","name":"auto_approve","label":_vm.invitationCount > 1
            ? 'J’ai vu les preuves d’adresse et/ou d’identité de ces personnes, je pré-approuve leurs profils.'
            : 'J’ai vu les preuves d’adresse et/ou d’identité de cette personne, je pré-approuve leur profil.'},model:{value:(_vm.autoApprove),callback:function ($$v) {_vm.autoApprove=$$v},expression:"autoApprove"}}),(_vm.autoApprove)?_c('forms-validated-input',{attrs:{"label":"Note pour le comité","placeholder":"ex.: preuve d’adresse vue au kiosque du 21 mai 2024","type":"textarea","rules":{
          required: true,
        },"name":"message","rows":2,"max-rows":4,"description":"Cette note sera utilisée comme raison d'approbation de ces personnes dans la communauté."},model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}}):_vm._e(),_c('icon-button',{attrs:{"role":"send","type":"submit","loading":_vm.saving}},[_vm._v(" Envoyer ")]),_c('div',[_c('small',{staticClass:"text-muted"},[_vm._v("À noter: les invitations ne sont valides que pour 30 jours.")])])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }