<template>
  <b-container class="profile-loanable" fluid v-if="user.owner && routeDataLoaded">
    <template v-if="loanable">
      <vue-headful :title="fullTitle" />

      <h1 v-if="loanable.name">{{ loanable.name }}</h1>
      <h1 v-else>
        <em>{{ $t(`new.${loanable.type}`) }}</em>
      </h1>
    </template>

    <loanable-form @submitted="submitted" current-user-is-owner :id="id" />
  </b-container>
  <layout-loading v-else />
</template>

<script>
import LoanableForm from "@/components/Loanable/LoanableForm.vue";

import Authenticated from "@/mixins/Authenticated";
import DataRouteGuards from "@/mixins/DataRouteGuards";

import locales from "@/locales";

import { capitalize } from "@/helpers/filters";

export default {
  name: "ProfileLoanable",
  mixins: [Authenticated, DataRouteGuards],
  components: {
    LoanableForm,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  mounted() {
    if (!this.user.owner) {
      this.createOwnerProfile();
    }
  },
  computed: {
    loanable() {
      return this.$store.state.loanables.item;
    },
    fullTitle() {
      const parts = [
        "LocoMotion",
        capitalize(this.$i18n.t("titles.profile")),
        capitalize(this.$i18n.tc("loanable", 2)),
      ];

      if (this.pageTitle) {
        parts.push(this.pageTitle);
      }

      return parts.reverse().join(" | ");
    },
    pageTitle() {
      return this.loanable?.name || capitalize(this.$i18n.tc("loanable", 1));
    },
  },
  methods: {
    async submitted() {
      this.$store.commit("loanable.search/invalidateCache");
      this.$store.dispatch("dashboard/loadLoanables");
    },
    async createOwnerProfile() {
      try {
        await this.$store.dispatch("users/update", {
          id: this.user.id,
          data: {
            id: this.user.id,
            owner: {},
          },
          params: {},
        });
        await this.$store.dispatch("loadUser");
      } catch (e) {
        if (e.request?.status !== 422) {
          throw e;
        }
      }
    },
  },
  i18n: {
    messages: {
      en: {
        ...locales.en.forms,
        ...locales.en.loanables,
        profile: { ...locales.en.profile },
        titles: { ...locales.en.titles },
      },
      fr: {
        ...locales.fr.forms,
        ...locales.fr.loanables,
        profile: { ...locales.fr.profile },
        titles: { ...locales.fr.titles },
      },
    },
  },
};
</script>

<style lang="scss"></style>
