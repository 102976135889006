import AdminFilters from "@/locales/components/Admin/Filters";
import DashboardBalance from "@/locales/components/Dashboard/Balance";
import LoginBox from "@/locales/components/Login/Box";
import RegisterForm from "@/locales/components/Register/RegisterForm";
import UserEmailForm from "@/locales/components/User/EmailForm";
import UserPasswordForm from "@/locales/components/User/PasswordForm";
import Dashboard from "@/locales/views/Dashboard";
import PasswordRequest from "@/locales/views/Password/Request";
import PasswordReset from "@/locales/views/Password/Reset";
import AccountProfile from "@/locales/views/Profile/Account";
import bikes from "@/locales/models/bikes";
import borrowers from "@/locales/models/borrowers";
import cars from "@/locales/models/cars";
import communities from "@/locales/models/communities";
import faq from "@/locales/faq";
import invoices from "@/locales/models/invoices";
import loans from "@/locales/models/loans";
import loanables from "@/locales/models/loanables";
import padlocks from "@/locales/models/padlocks";
import paymentMethods from "@/locales/models/paymentMethods";
import trailers from "@/locales/models/trailers";
import users from "@/locales/models/users";
import Loan from "@/locales/views/Loan";
import bill_items from "@/locales/models/bill_items";

export default {
  bikes: bikes.fr,
  bill_items: bill_items.fr,
  borrowers: borrowers.fr,
  cars: cars.fr,
  communities: communities.fr,
  components: {
    admin: {
      filters: AdminFilters.fr,
    },
    dashboard: {
      balance: DashboardBalance.fr,
    },
    login: {
      box: LoginBox.fr,
    },
    register: {
      register_form: RegisterForm.fr,
    },
    user: {
      email_form: UserEmailForm.fr,
      password_form: UserPasswordForm.fr,
    },
  },
  views: {
    dashboard: Dashboard.fr,
    profile: {
      account: AccountProfile.fr,
    },
    password: {
      request: PasswordRequest.fr,
      reset: PasswordReset.fr,
    },
    loan: Loan.fr,
  },
  faq: faq.fr,
  forms: {
    actions: "actions",
    view: "afficher",
    archive: "archiver",
    details: "détails",
    save: "enregistrer",
    optional: "facultatif",
    edit: "modifier",
    new: "nouveau",
    reset: "réinitialiser",
    restore: "restaurer",
    reinstate: "rétablir",
    remove: "retirer",
    delete: "supprimer",
  },
  invoices: invoices.fr,
  lists: {
    // Generic label for list ids.
    id: "ID",
  },
  loans: loans.fr,
  loanables: loanables.fr,
  locales: {
    en: "English",
    fr: "Français",
  },
  padlocks: padlocks.fr,
  paymentMethods: paymentMethods.fr,
  pricings: {
    types: {
      null: "n'importe quel type",
      contribution: "contribution",
      price: "coût",
      insurance: "assurance",
    },
    loanableTypes: {
      null: "n'importe quel type",
      car: "auto",
      car_small: "petite auto",
      car_large: "grosse auto",
      car_electric: "auto électrique",
      bike_regular: "vélo",
      bike_electric: "vélo électrique",
      bike_cargo_regular: "vélo-cargo",
      bike_cargo_electric: "vélo-cargo électrique",
      trailer: "remorque",
    },
    loanable_ownership_type: {
      all: "tous",
      fleet: "flotte collectives",
      non_fleet: "membres particuliers",
    },
  },
  profile: {
    titles: {
      account: "Mon compte",
      borrower: "Mon dossier de conduite",
      communities: "Mes voisinages",
      proofs: "Mes preuves",
      invoice: "Ma facture",
      invoices: "Mes factures",
      loans: "Historique d'emprunts",
      loanable: "Mon véhicule",
      loanables: "Mes véhicules",
      payment_method: "Mon mode de paiement",
      payment_methods: "Mes modes de paiement",
      profile: "Mon profil LocoMotion",
      subscriptions: "Mes contributions annuelles",
    },
  },
  titles: {
    account: "compte",
    admin: "administration",
    borrower: "dossier de conduite",
    communities: "communautés",
    communities_overview: "communautés",
    community: "communauté",
    dashboard: "tableau de bord",
    exports: "exports",
    faq: "foire aux questions",
    find_vehicle: "réserver un véhicule",
    insurance: "assurances Desjardins",
    invitations: "invitations",
    invoice: "facture",
    invoices: "factures",
    loan: "emprunt",
    loans: "emprunts",
    loanable: "véhicule",
    loanables: "véhicules",
    login: "se connecter",
    padlock: "Cadenas",
    padlocks: "Cadenas",
    password: "Mot de passe",
    password_request: "Réinitialisation de mot de passe",
    password_reset: "Réinitialisation de mot de passe",
    payment_method: "mode de paiement",
    payment_methods: "modes de paiement",
    pricings: "tarifications",
    privacy: "conditions générales d'utilisation",
    profile: "profil",
    proofs: "preuves",
    register: "s'inscrire",
    register_map: "rejoindre mes voisin-e-s",
    search: "recherche",
    subscriptions: "Contributions annuelles",
    user: "membre",
    users: "membres",
    gbfs: "Flux GBFS",
  },
  trailers: trailers.fr,
  users: users.fr,
  slugs: {
    communities: "communauté | communautés",
    loanables: "véhicule | véhicules",
    users: "participant.e | participant.e.s",
    invoices: "facture | factures",
    padlocks: "cadenas",
    loans: "emprunt | emprunts",
    paymentMethods: "Méthode de paiement | Méthodes de paiement",
    exports: "export | exports",
  },
  errorRessourceNames: {
    communities: "la communauté | les communautés",
    loanables: "le véhicule | les véhicules",
    users: "le ou la participant.e | les participant.e.s",
    invoices: "la facture | les factures",
    padlocks: "le cadenas | les cadenas",
    loans: "l'emprunt | les emprunts",
    paymentMethods: "la méthode de paiement | les méthodes de paiement",
    exports: "l'export | les exports",
    coowners: "les droits de gestion",
  },
  exports: {
    list: {
      selected: "{count} export sélectionné | {count} exports sélectionnés",
    },
  },
};
