<template>
  <b-form-group
    :class="`forms-image-uploader ${validationStateClass}`"
    :label="label"
    :label-for="field"
    :description="description"
  >
    <div v-if="loading">
      <img src="/loading.svg" />
    </div>
    <div class="mb-3" v-else-if="!value">
      <b-form-file
        :value="value"
        :state="validationState"
        :id="field"
        :ref="`${field}imageinput`"
        :placeholder="placeholder"
        :disabled="disabled"
        :name="field"
        :accept="accept.join(',')"
        browse-text="Sélectionner"
        drop-placeholder="Déposer l'image ici..."
        @change="handleChange"
      />
      <div class="invalid-feedback" v-if="error">
        {{ error }}
      </div>
    </div>

    <div v-show="value && !loading">
      <figure class="preview">
        <safe-image :image="value" :aspect-ratio="previewAspectRatio" :alt="alt || label" />
      </figure>
      <icon-button v-if="!disabled" size="sm" role="remove-item" @click="removeImage">
        {{ removeImageText }}
      </icon-button>
    </div>
  </b-form-group>
</template>

<script>
import IconButton from "@/components/shared/IconButton.vue";
import SafeImage from "@/components/shared/SafeImage.vue";
import { uploadImage } from "@/requests/fileRequests";

export default {
  name: "FormsImageUploader",
  components: { IconButton, SafeImage },
  props: {
    accept: {
      default: () => ["*.png", "*.jpg", "*.jpeg", "image/png", "image/jpg", "image/jpeg"],
      type: Array,
    },
    description: {
      type: String,
      required: false,
      default: "",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    field: {
      required: true,
      type: String,
    },
    label: {
      required: false,
      type: String,
      default: "",
    },
    placeholder: {
      default: "Téléverser...",
      type: String,
    },
    removeImageText: {
      default: "Retirer l'image",
      type: String,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    state: {
      type: Boolean,
      required: false,
      default: null,
    },
    value: {
      type: Object,
      require: false,
      default: null,
    },
    previewAspectRatio: {
      type: String,
      required: false,
      default: null,
    },
    alt: {
      type: String,
    },
  },
  data: function () {
    return {
      loading: false,
      error: null,
    };
  },
  computed: {
    validationState() {
      return !this.error && ((this.required && !!this.value) || this.state);
    },
    validationStateClass() {
      switch (this.validationState) {
        case true:
          return "is-valid";
        case false:
          return "is-invalid";
        default:
          return "";
      }
    },
  },
  methods: {
    handleChange(event) {
      switch (event.type) {
        case "drop":
          this.uploadImage(event.target.getAttribute("for"), event.dataTransfer.files);
          break;
        default:
          this.uploadImage(event.target.name, event.target.files);
          break;
      }
    },
    removeImage() {
      this.$emit("input", null);

      if (this.$refs[`${this.field}imageinput`]) {
        this.$refs[`${this.field}imageinput`].reset();
      }
    },
    async uploadImage(fieldName, fileList) {
      this.loading = true;
      const { file, error } = await uploadImage(fieldName, fileList, {
        order: this.value?.length ?? 0,
      });
      this.loading = false;
      if (file) {
        this.$emit("input", file);
      }
      this.error = error;
    },
  },
};
</script>

<style lang="scss">
.forms-image-uploader {
  .custom-file {
    min-height: 180px;
  }

  .custom-file-label {
    overflow: hidden;
    height: 180px;
    text-align: center;

    border: 1px dashed $light-grey;

    background-image: url("/cloud.svg");
    background-repeat: no-repeat;
    background-size: auto 50%;
    background-position: top 40px center;

    &.dragging {
      background-image: url("/cloud-active.svg");
      border: 1px dashed $primary;
    }

    &::after {
      border-left: 0;
      border-radius: 0.25rem;
      position: absolute;
      bottom: 0.5rem;
      left: 0;
      right: 0;
      top: auto;
      width: max(50%, 200px);
      max-width: 100%;
      margin: 0 auto;
    }
  }

  + .invalid-feedback {
    margin-top: -20px;
  }
}
</style>
