<template>
  <div class="register-intro">
    <b-pagination-nav
      v-bind:value="currentPage"
      :number-of-pages="3"
      pills
      align="center"
      use-router
      :hide-goto-end-buttons="true"
      :disabled="true"
    >
      <template v-slot:page="{ page, active }">
        <span>{{ page }}</span>
      </template>
    </b-pagination-nav>

    <register-form />
  </div>
</template>

<script>
import RegisterForm from "@/components/Register/RegisterForm.vue";

export default {
  name: "RegisterIntro",
  components: {
    RegisterForm,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.isLoggedIn) {
        // Not register, go to "complete your profile"
        if (!vm.isRegistered) {
          if (vm.$route.path !== "/register/2") {
            return vm.$router.replace("/register/2");
          }
          return null;
        }
      }

      if (vm.$route.path !== "/register/1") {
        return vm.$router.replace("/register/1");
      }

      return null;
    });
  },
  data() {
    return {
      currentPage: 1,
    };
  },
};
</script>

<style lang="scss"></style>
