<template>
  <div class="conditional-context" v-if="show">
    <span class="conditional-context-label" :class="background" v-if="label"
      >{{ label }}
      <b-icon
        :scale="0.75"
        v-if="additionalInfo"
        icon="info-circle"
        v-b-tooltip="additionalInfo"
      ></b-icon>
    </span>
    <slot />
  </div>
  <div v-else>
    <slot />
  </div>
</template>

<script>
export default {
  name: "ConditionalContext",
  props: {
    show: {
      type: Boolean,
    },
    label: {
      type: String,
    },
    additionalInfo: {
      type: String,
    },
    background: {
      type: String,
      default: "main",
    },
  },
};
</script>

<style scoped lang="scss">
.conditional-context {
  padding: 1rem 1rem 0;
  border-radius: 0.5rem;
  border: 1px solid $light-grey;
  margin: 0 -1rem 1rem;
  position: relative;
  .conditional-context-label {
    position: absolute;
    top: -0.5rem;
    font-size: 0.8rem;
    color: $grey;
    font-weight: 600;
    padding: 0rem 0.5rem;
    margin-left: -0.25rem;
    &.white {
      background-color: white;
    }
    &.main {
      background-color: $main-bg;
    }
  }
}
</style>
