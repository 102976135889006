<template>
  <div class="position-relative">
    <b-card :class="{ archived: user.deleted_at }" class="overflow-hidden">
      <div class="user-blurb-row">
        <div class="center">
          <user-avatar :user="user"></user-avatar>
        </div>
        <div class="center">
          <span>
            <template v-if="showAdminLink && userLink">
              <router-link class="font-weight-bold" :to="userLink">
                {{ user.full_name }} <slot name="nameicon"></slot>
              </router-link>
            </template>
            <span class="font-weight-bold" v-else>
              {{ user.full_name }} <slot name="nameicon"></slot>
            </span>

            <span v-if="showPhone"><br />{{ user.phone }}</span
            ><br v-if="showEmail" />
            <a
              class="text-nowrap"
              :href="`mailto:${user.email}`"
              @click.default.prevent="copyEmail"
              v-if="showEmail"
              >{{ user.email }}</a
            >
          </span>

          <slot name="description">
            <small v-if="description">{{ description }}</small>
          </slot>
        </div>
        <slot name="buttons"></slot>
      </div>
    </b-card>
    <b-dropdown
      v-if="$slots.dropdown"
      class="user-blurb-dropdown"
      size="sm"
      variant="white-primary"
      no-caret
      toggle-class="mr-0"
      right
    >
      <template #button-content>
        <b-icon icon="three-dots-vertical" /><span class="sr-only">actions</span>
      </template>
      <slot name="dropdown"></slot>
    </b-dropdown>
  </div>
</template>

<script>
import UserAvatar from "@/components/User/Avatar.vue";
import { userLink } from "@/helpers/adminLinks";

export default {
  name: "UserBlurb",
  computed: {
    userLink() {
      return userLink(this.user);
    },
  },
  components: { UserAvatar },
  props: {
    user: {
      type: Object,
      required: false,
      default: {},
    },
    showPhone: {
      type: Boolean,
      default: false,
    },
    showEmail: {
      type: Boolean,
      default: false,
    },
    showAdminLink: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: "",
    },
  },
  methods: {
    copyEmail() {
      if (!this.user.email) {
        return;
      }

      navigator.clipboard.writeText(this.user.email).then(() => {
        this.$store.commit("addNotification", {
          title: `Courriel copié: ${this.user.email}`,
          variant: "success",
        });
      });
    },
  },
};
</script>

<style scoped lang="scss">
.card {
  border: 1px solid $light-grey;
}
.card-body {
  padding: 0;
  overflow: auto;
}
.center {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.archived {
  background: $eggshell;
  opacity: 0.5;
  a,
  span {
    color: $content-neutral-secondary;
  }
}
.user-blurb-row {
  padding: 0.75rem;
  display: flex;
  flex-wrap: nowrap;
  gap: 0.75rem;
  min-width: min-content;
}
.user-blurb-dropdown {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
