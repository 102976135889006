<template>
  <div class="dashboard-release-info-box">
    <b-alert variant="success" show class="dashboard-release-info-box_changeset">
      <p>Nouveautés</p>
      <ul>
        <div>
          <li>Ouverture au quartier Ahuntsic (nouvelle disponibilité des véhicules collectifs)</li>
          <li>
            Si vous avez un véhicule, vous pouvez maintenant le partager à tout le quartier! Par
            défaut, on partage votre auto ou votre vélo juste à votre voisinage. Après, vous avez le
            choix.
          </li>
        </div>
      </ul>
    </b-alert>
  </div>
</template>

<script>
import UserMixin from "@/mixins/UserMixin";

export default {
  name: "DashboardReleaseInfoBox",
  mixins: [UserMixin],
};
</script>

<style lang="scss">
.dashboard-release-info-box {
  &__question {
    border: 1px solid $light-grey;
    padding: 1rem;
    background: $success;
    color: $white;

    .form-group {
      margin-bottom: 0;
    }
  }

  &__buttons {
    margin-top: 1rem;
    text-align: center;
  }
}
</style>
