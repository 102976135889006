export default {
  fr: {
    current_email: "Courriel actuel",
    email_change_modal: {
      title: "Courriel mis à jour",
      content: `
      <p>Vous pouvez maintenant vous connecter avec votre nouveau courriel.</p>
      <p><strong>Rappel important</strong></p>
      <p>
        Votre compte Noke a aussi été mis-à-jour. Si vous avez l'application Noke
        sur votre téléphone intelligent, vous devez vous reconnecter avec ce courriel.
      </p>
      `,
    },
    password_change_modal: {
      title: "Mot de passe mis à jour",
      content: `<p>Vous pouvez maintenant vous connecter avec votre nouveau mot de passe.</p>`,
    },
  },
};
