<template>
  <div class="loanable-details-box" :style="{ 'view-transition-name': `loanable-details` }">
    <b-card no-body>
      <image-carousel
        class="card-img-top"
        v-if="loanable && loanable.images && loanable.images.length > 0"
        :images="loanable.images"
        aspect-ratio="16 / 10"
        :preferred-sizes="['loan', 'thumbnail', 'original']"
      />
      <b-card-body>
        <b-card-title :class="{ archived: !!loanable.deleted_at, 'mb-2': !loanable.deleted_at }">
          <router-link v-if="loanableUrl" :to="loanableUrl">{{ loanable.name }}</router-link>
          <span v-else>{{ loanable.name }}</span>
        </b-card-title>
        <div
          v-if="loanable.details"
          class="details-toggle mb-3 text-muted"
          v-b-toggle.loanable-details
        >
          {{ $t("details_box.details") | capitalize }} {{ prettyType }}
          <b-icon icon="caret-right-fill"></b-icon>
        </div>
        <b-card-text>
          <b-collapse
            v-if="loanable.details"
            id="loanable-details"
            role="tabpanel"
            accordion="loanable-details"
          >
            <description-list :loanable="loanable" details-only />
          </b-collapse>
          <hr />
          <template v-if="(hasOwnerContacts && isBorrower) || canViewLoanAdminDetails">
            <dl>
              <dt>{{ $t("loanable.resource_people") | capitalize }}</dt>
              <dd>
                <user-blurb
                  v-if="loanable.show_owner_as_contact || canViewLoanAdminDetails"
                  :show-admin-link="canViewLoanAdminDetails"
                  description="Propriétaire"
                  :user="loanable.owner.user"
                  show-phone
                ></user-blurb>
                <user-blurb
                  v-if="coowner.show_as_contact"
                  :key="coowner.id"
                  class="mt-2"
                  :show-admin-link="canViewLoanAdminDetails"
                  v-for="coowner of loanable.coowners"
                  :user="coowner.user"
                  :description="coowner.title"
                  show-phone
                ></user-blurb>
              </dd>
            </dl>
            <hr class="mt-3" v-if="!canViewLoanAdminDetails" />
          </template>

          <template v-if="!isBorrower || canViewLoanAdminDetails">
            <dl>
              <dt>{{ $t("fields.borrower_id") | capitalize }}</dt>
              <dd>
                <user-blurb
                  :show-admin-link="canViewLoanAdminDetails"
                  :user="loan.borrower.user"
                  show-phone
                  show-email
                >
                  <template
                    #dropdown
                    v-if="
                      loanable.sharing_mode === 'hybrid' &&
                      !loan.is_self_service &&
                      !loan.is_borrower_trusted
                    "
                  >
                    <b-dropdown-item
                      v-if=""
                      variant="success"
                      @click="addBorrowerToTrustedBorrowers"
                    >
                      <b-icon icon="shield-fill-check" />
                      Ajouter au réseau de confiance
                    </b-dropdown-item>
                  </template>
                  <template #nameicon v-else-if="loan.is_borrower_trusted">
                    <b-icon
                      class="text-primary"
                      icon="shield-fill-check"
                      v-b-tooltip="'Dans le réseau de confiance de ce véhicule'"
                    />
                  </template>
                </user-blurb>
              </dd>
            </dl>
            <hr class="mt-3" />
          </template>

          <b-alert variant="secondary" show v-if="tzDifference" class="small text-muted mb-2">
            Heure dans le fuseau horaire du véhicule: {{ loanableTimezone }}
          </b-alert>
          <b-row>
            <b-col tag="dl" cols="6">
              <dt>{{ $t("fields.departure_at") | capitalize }}</dt>
              <dd>
                {{ zonedTimes.departureAt | shortDate | capitalize }}<br />{{
                  zonedTimes.departureAt | time
                }}
              </dd>
            </b-col>
            <b-col tag="dl" cols="6">
              <dt>{{ $t("fields.return_at") | capitalize }}</dt>
              <dd>
                {{ zonedTimes.returnAt | shortDate | capitalize }}<br />
                {{ zonedTimes.returnAt | time }}
              </dd>
            </b-col>
          </b-row>
          <b-row>
            <b-col tag="dl" cols="6">
              <dt>{{ $t("details_box.duration") | capitalize }}</dt>
              <dd v-if="duration > 0">
                {{
                  $tc("details_box.duration_over_days", loan.calendar_days, {
                    hours: durationInHours(duration),
                    days: loan.calendar_days,
                  })
                }}
              </dd>
            </b-col>
            <b-col tag="dl" cols="6" v-if="!loan.is_free && distance > 0">
              <dt>
                {{
                  hasFinalDistance
                    ? $t("details_box.distance")
                    : $t("details_box.estimated_distance") | capitalize
                }}
              </dt>
              <dd>{{ distance }} km</dd>
            </b-col>

            <b-col cols="12">
              <dt class="mb-2 details-toggle" v-b-toggle="isFree ? null : 'price-details'">
                <template v-if="isBorrower || canViewLoanAdminDetails">
                  {{
                    hasPaymentCompleted
                      ? $t("details_box.cost")
                      : $t("fields.estimated_price") | capitalize
                  }}
                  <template v-if="canViewLoanAdminDetails">
                    ({{ $t("fields.borrower_id") }})
                  </template>
                </template>
                <template v-else-if="isCoownerOrOwner">
                  {{
                    hasPaymentCompleted
                      ? $t("details_box.compensation")
                      : $t("details_box.estimated_compensation") | capitalize
                  }}
                </template>
                &nbsp;
                <b-icon icon="caret-right-fill" v-if="!isFree"></b-icon>
              </dt>
              <dd>
                <div v-if="summaryVisible || isFree">
                  <layout-loading with-text v-if="evaluatingPrice"></layout-loading>
                  <template v-else>
                    <div v-if="isFree">Gratuit!</div>
                    <div v-else-if="isLoanAdmin || isBorrower">
                      {{ -borrowerTotal | currency }}
                    </div>
                    <div v-else-if="isCoownerOrOwner">{{ ownerTotal | currency }}</div>
                  </template>
                </div>

                <b-collapse
                  @input="(visible) => (summaryVisible = !visible)"
                  :visible="!summaryVisible && !isFree"
                  id="price-details"
                  role="tabpanel"
                  accordion="price-details"
                >
                  <layout-loading
                    v-if="evaluatingPrice"
                    class="table-loading-indicator"
                  ></layout-loading>

                  <tiny-invoice
                    v-if="isLoanAdmin || isBorrower"
                    :invoice="borrowerInvoice"
                    class="p-2"
                  />
                  <tiny-invoice v-else-if="isCoownerOrOwner" :invoice="ownerInvoice" class="p-2" />

                  <div class="small" v-if="loanable.type === 'car'">
                    <a href="https://info.locomotion.app/tarifs-autopartage/" target="_blank">{{
                      $t("details_box.pricing_explanation") | capitalize
                    }}</a>
                  </div>
                </b-collapse>
              </dd>
            </b-col>
          </b-row>
          <template
            v-if="
              loanable.comments ||
              instructions ||
              loanable.location_description ||
              loanable.position
            "
          >
            <hr />

            <dl class="mb-0">
              <template v-if="loanable.comments">
                <dt>{{ $t("loanable.fields.comments") | capitalize }}</dt>
                <dd class="user_text">{{ loanable.comments }}</dd>
              </template>

              <template v-if="instructions">
                <dt>{{ $t("loanable.fields.instructions") | capitalize }}</dt>

                <dd class="user_text">{{ instructions }}</dd>
              </template>

              <dt v-if="loanable.location_description || loanable.position">
                {{ $t("loanable.fields.position") | capitalize }}
              </dt>
              <dd>
                <div class="mb-2">{{ loanable.location_description }}</div>
                <forms-map-input
                  v-if="loanable.position"
                  :value="loanable.position"
                  disabled
                  bounded
                  allow-fullscreen
                />
              </dd>
            </dl>
          </template>
        </b-card-text>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import FormsMapInput from "@/components/Forms/MapInput.vue";
import IconButton from "@/components/shared/IconButton.vue";
import { durationInHours } from "@/helpers/filters";
import { summarizeBorrowerInvoice, summarizeOwnerInvoice } from "@/helpers/invoices";

import locales from "@/locales";
import UserMixin from "@/mixins/UserMixin";
import { isCoownerOrOwner } from "@/helpers/permissions/loanables";
import {
  canViewLoanAdminDetails,
  canViewLoanInstructions,
  isBorrower,
  isLoanAdmin,
} from "@/helpers/permissions/loans";
import UserBlurb from "@/components/User/UserBlurb.vue";
import SafeImage from "@/components/shared/SafeImage.vue";
import ImageCarousel from "@/components/shared/ImageCarousel.vue";
import LoanableDetails from "@/components/Loanable/Details.vue";
import DescriptionList from "@/components/Loanable/DescriptionList.vue";
import TinyInvoice from "@/components/Invoice/TinyInvoice.vue";
import dayjs from "@/helpers/dayjs";
import { getZonedTimes } from "@/helpers/loanTimezones";
import { put } from "@/requests/server";
import { addTrustedBorrower } from "@/requests/trustedBorrowersRequests";

export default {
  name: "LoanDetailsBox",
  components: {
    IconButton,
    DescriptionList,
    LoanableDetails,
    ImageCarousel,
    SafeImage,
    UserBlurb,
    FormsMapInput,
    TinyInvoice,
  },
  mixins: [UserMixin],
  props: {
    loan: {
      type: Object,
      required: false,
    },
    loanable: {
      type: Object,
      required: true,
    },
    vertical: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    const user = this.$store.state.user;
    return {
      summaryVisible: !canViewLoanAdminDetails(user, this.loan),
      isCoownerOrOwner: isCoownerOrOwner(user, this.loanable),
      isBorrower: isBorrower(user, this.loan),
      isLoanAdmin: isLoanAdmin(user, this.loan),
      canViewLoanAdminDetails: canViewLoanAdminDetails(user, this.loan),
      canViewLoanInstructions: canViewLoanInstructions(user, this.loan),
    };
  },
  computed: {
    isFree() {
      return this.isBorrower && this.borrowerInvoice.items.length === 0;
    },
    instructions() {
      if (!this.canViewLoanInstructions) {
        return null;
      }
      return this.loanable.trusted_borrower_instructions ?? this.loanable.instructions;
    },
    zonedTimes() {
      return getZonedTimes(this.loan);
    },
    loanableTimezone() {
      return this.zonedTimes.departureAt.format("z (Z)");
    },
    tzDifference() {
      return dayjs().tz(this.loanable.timezone).format("z") !== dayjs().format("z");
    },
    duration() {
      if (this.loan.actual_duration_in_minutes) {
        return this.loan.actual_duration_in_minutes;
      }
      return this.loan.duration_in_minutes;
    },
    hasFinalDistance() {
      return this.loan.handover && this.loan.handover.mileage_end;
    },
    hasPaymentCompleted() {
      return this.loan?.payment?.status == "completed";
    },
    distance() {
      if (this.hasFinalDistance) {
        return this.loan.handover.mileage_end - this.loan.takeover.mileage_beginning;
      }
      return this.loan.estimated_distance;
    },
    loanableUrl() {
      if (this.canViewLoanAdminDetails) {
        if (!this.loanable.deleted_at) {
          return "/admin/loanables/" + this.loanable.id;
        } else {
          return "/admin/loanables?is_deleted=true&id=" + this.loanable.id;
        }
      }

      return "";
    },
    borrowerInvoice() {
      return summarizeBorrowerInvoice(this.loan.borrower_invoice, {
        ownerName: this.loan.loanable.owner.user.name,
        communityName: this.loan.community?.name,
        groupCompensations: true,
        groupContributions: true,
      });
    },
    borrowerTotal() {
      return this.loan.borrower_invoice?.user_balance_change ?? 0;
    },
    ownerInvoice() {
      return summarizeOwnerInvoice(this.loan);
    },
    ownerTotal() {
      return this.loan.owner_invoice?.user_balance_change;
    },
    prettyType() {
      switch (this.loanable.type) {
        case "car":
          return "de la voiture";
        case "bike":
          return "du vélo";
        case "trailer":
          return "de la remorque";
        default:
          return "de l'objet";
      }
    },
    hasOwnerContacts() {
      return (
        this.loanable.show_owner_as_contact ||
        this.loanable.coowners.reduce((acc, c) => acc || c.show_as_contact, false)
      );
    },
    evaluatingPrice() {
      return this.$store.state.loans.evaluatingPrice;
    },
  },
  methods: {
    durationInHours,
    async addBorrowerToTrustedBorrowers() {
      await addTrustedBorrower(this.loanable.id, this.loan.borrower_user.id);
      this.$store.commit("loans/patchItem", { is_borrower_trusted: true });
    },
  },
  i18n: {
    messages: {
      en: {
        loanable: locales.en.loanables,
        bill_items: locales.en.bill_items,
        ...locales.en.loans,
      },
      fr: {
        loanable: locales.fr.loanables,
        bill_items: locales.fr.bill_items,
        ...locales.fr.loans,
      },
    },
  },
};
</script>

<style lang="scss">
.loanable-details-box {
  .tiny-invoice {
    font-size: 0.8rem;
  }

  > .card {
    box-shadow: $small-shadow;
  }
  .card-img-top {
    overflow: hidden;
  }
  .archived {
    background: $eggshell;
    opacity: 0.5;
    a,
    span {
      color: $content-neutral-secondary;
    }
  }
  .user_text {
    // Show line feeds in comments, instructions and location_description
    white-space: pre-line;
  }
  .details-toggle {
    &:hover {
      text-decoration: underline;
    }
    .b-icon {
      transition: 0.3s;
    }
    &.not-collapsed .b-icon {
      transform: rotate(90deg);
    }
  }
  dt {
    font-weight: 400;
    font-size: 0.8rem;
    color: $content-neutral-secondary;
    margin-bottom: 0.5rem;
  }
  dd {
    margin-bottom: 1rem;
  }
  dl {
    margin-bottom: 0;
  }
  hr {
    margin-top: 0;

    margin-bottom: 1rem;
  }
  .table-loading-indicator {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 75%;
    opacity: 0.5;
  }

  .price-table {
    width: 100%;
    font-size: 0.8rem;

    &.loading {
      opacity: 0.5;
    }

    th {
      font-weight: 400;
    }
    td {
      vertical-align: top;
    }
    .spacer-row > td {
      padding-bottom: 0.5rem;
    }
    .total-row {
      & > td,
      th {
        font-weight: bold;
        padding-top: 0.5rem;
      }
    }
  }
}
</style>
