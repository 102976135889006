<template>
  <div class="community-map-wrapper">
    <div class="community-map">
      <div ref="map" class="w-100 h-100" />
      <template v-if="map && initialized">
        <map-marker
          v-for="loanable of loanables"
          :key="loanable.id"
          :map="map"
          :loanable="loanable"
          :outline="!loanable.tested"
          :ghost="loanable.tested && !loanable.available"
          :onclick="onLoanableClick"
        />
      </template>
    </div>
    <div class="d-none">
      <!--  :key is important here to ensure loanable details re-render fully when loanable changes -->
      <div ref="details" v-if="selectedLoanableId" :key="selectedLoanableId">
        <slot name="loanable-details" :loanable="selectedLoanable">
          <loanable-details
            fixed-width
            :loanable="selectedLoanable"
            @select="$emit('select', selectedLoanable)"
            @test="$emit('test', selectedLoanable)"
          />
        </slot>
      </div>
    </div>
    <div class="map-top-right-buttons">
      <div
        v-b-tooltip.hover.left="
          userPositionDisabled
            ? 'Pour centrer sur votre position, acceptez l\'accès à votre position dans votre navigateur.'
            : 'Centrer sur votre position approximative'
        "
      >
        <icon-button icon="geo" variant="white-secondary" pill @click="centerOnUser()" />
      </div>
      <slot name="top-right-button" />
    </div>
  </div>
</template>

<script>
import LoanableDetails from "@/components/Loanable/Details.vue";
import MapMarker from "@/components/Loanable/MapMarker.vue";
import IconButton from "@/components/shared/IconButton.vue";
import { extractMultipolygonPaths, extractMultipolygonPoints } from "@/helpers/geoJson";
import { loader, mapId } from "@/helpers/googleMaps";

export default {
  name: "LoanableMap",
  components: {
    MapMarker,
    IconButton,
    LoanableDetails,
  },
  mounted() {
    this.initMap();
  },
  props: {
    loanables: {
      type: Array,
      required: true,
    },
    communities: {
      type: Array,
      required: true,
    },
    heatmapData: {
      type: Array,
      required: false,
      default: () => [],
    },
    polygonsSelectable: {
      type: Boolean,
      default: false,
    },
    polygonOptions: {
      type: Object,
      default: () => ({
        fillColor: "#16a59e",
        fillOpacity: 0.25,
        strokeOpacity: 0,
        zIndex: 2,
      }),
    },
    selectedPolygonOptions: {
      type: Object,
      default: () => ({
        fillColor: "#16a59e",
        fillOpacity: 0.25,
        strokeOpacity: 0,
        zIndex: 2,
      }),
    },
    paddingClientWidthMin: {
      type: Number,
      default: 0,
    },
    paddingClientWidthMax: {
      type: Number,
      default: Number.MAX_VALUE,
    },
    selectedCommunityIds: {
      type: Array,
      default: () => [],
    },
    defaultCenter: {
      type: Object,
      default: () => ({ lat: 45.53748, lng: -73.60145 }),
    },
    noRecenter: {
      type: Boolean,
      default: false,
    },
    zoom: {
      type: Number,
      default: 15,
    },
    padding: {
      type: Object,
      default() {
        return {
          x: 0,
          y: 0,
        };
      },
    },
  },
  data() {
    return {
      map: null,
      initialized: false,
      infoWindow: null,
      mapOptions: {
        clickableIcons: false,
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        maxZoom: 19,
        zoom: this.zoom,
      },
      selectedLoanableId: null,
      userPositionDisabled: false,
      userPositionCircle: null,
      searchLocationMarker: null,
      polygonMap: new Map(),
      heatmap: null,
      coreApi: null,
      mapsApi: null,
      markerApi: null,
      geometryApi: null,
      visualizationApi: null,
    };
  },
  computed: {
    // We use an ID rather than saving the selectedLoanable in the data since the loanables array
    // may change through actions in the infoWindow and thes changes would not be reflected on the
    // local  copy of the selectedLoanable.
    selectedLoanable() {
      return this.selectedLoanableId
        ? this.loanables.find((l) => l.id === this.selectedLoanableId)
        : null;
    },
    communitiesWithArea() {
      return (this.communities || []).filter((c) => !!c.area);
    },
    communityPolygons() {
      return this.communitiesWithArea.map((c) => ({
        name: c.name,
        paths: extractMultipolygonPaths(c.area),
        id: c.id,
      }));
    },
    loanableBounds() {
      if (!this.initialized || this.loanables.length <= 0) {
        return null;
      }

      const bounds = new this.coreApi.LatLngBounds();
      this.loanables.forEach((c) => bounds.extend(c.position_google));
      return bounds;
    },
    communityBounds() {
      if (!this.initialized || this.communitiesWithArea.length === 0) {
        return null;
      }

      const bounds = new this.coreApi.LatLngBounds();
      this.communitiesWithArea.forEach((c) =>
        this.communityPoints(c).forEach((p) => bounds.extend(p))
      );
      return bounds;
    },
  },
  methods: {
    async importLibraries() {
      const [coreApi, mapsApi, markerApi, geometryApi, visualizationApi] = await Promise.all([
        loader.importLibrary("core"),
        loader.importLibrary("maps"),
        loader.importLibrary("marker"),
        loader.importLibrary("geometry"),
        loader.importLibrary("visualization"),
      ]);
      this.coreApi = coreApi;
      this.mapsApi = mapsApi;
      this.markerApi = markerApi;
      this.geometryApi = geometryApi;
      this.visualizationApi = visualizationApi;
    },
    async initMap() {
      await this.importLibraries();

      this.map = new this.mapsApi.Map(this.$refs.map, {
        ...this.mapOptions,
        center: this.defaultCenter,
        mapId,
        gestureHandling: "greedy",
        mapTypeId: "terrain",
      });

      this.infoWindow = new this.mapsApi.InfoWindow({
        headerDisabled: true,
      });

      this.infoWindow.addListener("close", this.clearSelected);

      this.initialized = true;

      this.map.addListener("click", () => {
        this.selectedLoanableId = null;
        this.selectCommunities([]);
      });

      // It seems like fitBounds works even if the map is not quite yet ready, but panBy doesn't.
      // By recentering once bounds have changed, we make sure any pan does happen.
      const initialPanListener = this.map.addListener("bounds_changed", () => {
        this.recenter();
        initialPanListener.remove();
      });
      this.recenter();

      this.syncPolygons();
      this.drawHeatmap();
      this.map.addListener("zoom_changed", this.updateHeatmapZoom);
    },
    onLoanableClick(loanable, marker) {
      if (this.selectedLoanableId === loanable.id) {
        this.infoWindow.close();
        return;
      }
      this.infoWindow.close();
      this.activateLoanable(loanable);
      this.$nextTick(() => {
        this.infoWindow.setContent(this.$refs.details);
        this.infoWindow.open(this.map, marker);
      });
    },
    getHeatMapRadius() {
      let radius = 13;

      if (this.map.getZoom() > 13) {
        radius = 26 * (this.map.getZoom() - 13);
      }
      return radius;
    },
    drawHeatmap() {
      if (!this.heatmapData || this.heatmapData.length === 0) {
        if (this.heatmap) {
          this.heatmap.setMap(null);
          this.heatmap = null;
        }
        return;
      }

      this.heatmap = new this.visualizationApi.HeatmapLayer({
        data: this.heatmapData.map((p) => new this.coreApi.LatLng(p[0], p[1])),
        radius: this.getHeatMapRadius(),
        maxIntensity: 10,
        opacity: 0.4,
        disspating: true,
      });
      this.heatmap.setMap(this.map);
    },
    updateHeatmapZoom() {
      if (!this.heatmap) {
        return;
      }

      let newRadius = this.getHeatMapRadius();
      if (this.heatmap.get("radius") !== newRadius) {
        this.heatmap.setOptions({ radius: this.getHeatMapRadius() });
      }
    },
    syncPolygons() {
      const { Polygon } = this.mapsApi;

      const validIds = new Set();

      for (const communityPolygon of this.communityPolygons) {
        validIds.add(communityPolygon.id);
        const currentlySelected = this.selectedCommunityIds.includes(communityPolygon.id);
        const options = currentlySelected ? this.selectedPolygonOptions : this.polygonOptions;
        options.clickable = this.polygonsSelectable;

        // Update existing polygons if necessary
        if (this.polygonMap.has(communityPolygon.id)) {
          const { selected, polygon } = this.polygonMap.get(communityPolygon.id);
          if (selected !== currentlySelected) {
            polygon.setOptions(options);
            this.polygonMap.set(communityPolygon.id, { selected: currentlySelected, polygon });
          }
          continue;
        }

        // Create new polygons
        const polygon = new Polygon({ ...options, paths: communityPolygon.paths, map: this.map });
        polygon.addListener("click", () => {
          this.selectedLoanableId = null;
          this.selectCommunities([communityPolygon.id]);
        });
        this.polygonMap.set(communityPolygon.id, {
          selected: currentlySelected,
          polygon,
        });
      }

      // Delete extra polygons
      for (const polygonCommunityId of this.polygonMap.keys()) {
        if (!validIds.has(polygonCommunityId)) {
          let { polygon } = this.polygonMap.get(polygonCommunityId);
          polygon.setMap(null);
          polygon = null;
          this.polygonMap.delete(polygonCommunityId);
        }
      }
    },
    setSearchLocation(location) {
      if (!location) {
        if (this.searchLocationMarker) {
          this.searchLocationMarker.setMap(null);
          this.searchLocationMarker = null;
        }
        return;
      }
      if (this.map) {
        const { AdvancedMarkerElement } = this.markerApi;
        const { Polygon } = this.mapsApi;
        const { poly } = this.geometryApi;

        if (this.searchLocationMarker) {
          this.searchLocationMarker.position = location;
        } else {
          this.searchLocationMarker = new AdvancedMarkerElement({
            map: this.map,
            position: location,
            zIndex: 15,
          });
        }

        this.map.panTo(location);

        // Select communities the location is in
        const communityIds = [];
        for (const communityPolygon of this.communityPolygons) {
          let polygon = new Polygon({ paths: communityPolygon.paths });
          if (poly.containsLocation(location, polygon)) {
            communityIds.push(communityPolygon.id);
          }
        }

        if (communityIds.length > 0) {
          this.selectCommunities(communityIds);
        }
      }
    },
    communityPoints(community) {
      if (!community.area?.coordinates) {
        return [];
      }
      return extractMultipolygonPoints(community.area);
    },
    activateLoanable(loanable) {
      if (this.selectedLoanableId === loanable.id) {
        this.selectedLoanableId = null;
      } else {
        this.selectedLoanableId = loanable.id;
      }
      this.$emit("select-loanable", this.selectedLoanable);
    },
    centerOnUser() {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          if (this.userPositionCircle) {
            this.userPositionCircle.setMap(null);
          }
          const { AdvancedMarkerElement } = this.markerApi;

          const userCircle = document.createElement("div");
          userCircle.className = "user-position-cicrle";
          userCircle.textContent = "";

          this.userPositionCircle = new AdvancedMarkerElement({
            map: this.map,
            position: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
            content: userCircle,
            zIndex: 5,
          });

          this.map.setZoom(14);

          this.map.panTo({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          // GeolocationPositionError.code 1: PERMISSION_DENIED
          if (error.code === 1) {
            this.userPositionDisabled = true;
          }
        },
        { enableHighAccuracy: true }
      );
    },
    setMapToBounds(bounds) {
      if (this.map) {
        const shouldPad =
          this.paddingClientWidthMin <= document.body.clientWidth &&
          document.body.clientWidth <= this.paddingClientWidthMax;

        this.map.fitBounds(
          bounds,
          shouldPad ? Math.max(this.padding.x ?? 0, this.padding.y ?? 0) / 2 : 0
        );
        if (shouldPad) {
          this.map.panBy(this.padding.x ?? 0, this.padding.y ?? 0);
        }
      }
    },
    panToCommunityIds(communityIds) {
      const communities = this.communities.filter((c) => communityIds.includes(c.id) && c.area);

      if (communities.length === 0) {
        return;
      }

      const { LatLngBounds } = this.coreApi;
      const bounds = new LatLngBounds();

      for (const community of communities) {
        this.communityPoints(community).forEach((p) => bounds.extend(p));
      }

      return this.setMapToBounds(bounds);
    },
    recenter() {
      if (this.noRecenter) {
        return;
      }

      if (this.communityBounds) {
        return this.setMapToBounds(this.communityBounds);
      }

      if (this.loanableBounds) {
        return this.setMapToBounds(this.loanableBounds);
      }
    },
    selectCommunities(communityIds) {
      this.$emit("select-communities", communityIds);
    },
    clearSelected() {
      this.selectedLoanableId = null;
    },
  },
  watch: {
    heatmapData() {
      if (this.initialized) {
        this.drawHeatmap();
      }
    },
    selectedLoanable(newValue, oldValue) {
      if (!this.initialized) {
        return;
      }

      if (!newValue) {
        this.infoWindow.close();
        return;
      }

      if (newValue.id !== oldValue?.id) {
        this.map.panTo(newValue.position_google);
      }
    },
    communityPolygons() {
      if (this.initialized) this.syncPolygons();
    },
    selectedCommunityIds(newValue) {
      if (!this.initialized) {
        return;
      }
      this.syncPolygons();
      if (newValue && newValue.length > 0) {
        this.panToCommunityIds(newValue);
      }
    },
    communityBounds(newValue, oldValue) {
      if (this.initialized && newValue && JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
        this.recenter();
      }
    },
    loanableBounds(newValue, oldValue) {
      if (
        this.initialized &&
        newValue &&
        !this.communityBounds &&
        JSON.stringify(newValue) !== JSON.stringify(oldValue)
      ) {
        this.recenter();
      }
    },
  },
};
</script>

<style lang="scss">
@import "~bootstrap/scss/mixins/breakpoints";

.user-position-cicrle {
  width: 1rem;
  height: 1rem;
  opacity: 0.5;
  background-color: #4285f4;
  border-radius: 1rem;
  border: 2px solid #ffffff;
}

.gm-style,
.gm-style .gm-style-iw {
  font-family: inherit;
  font-weight: inherit;
}
.community-map-wrapper {
  width: 100%;
  height: 100%;
  .map-top-right-buttons {
    position: absolute;
    top: 1.5rem;
    // matches bootstrap column gutters
    right: 15px;
    z-index: 20;
    display: flex;
    gap: 0.5rem;
  }

  @include media-breakpoint-down(md) {
    position: relative;

    .map-top-right-buttons {
      right: 1rem;
    }
  }

  // if smaller than lg, make it relative
  .community-map {
    // Adapt Google InfoWindow to the loanable details.
    .gm-style-iw-c {
      padding: 0 !important;
      overflow: hidden !important;
      max-width: 16rem !important;
      box-shadow: $medium-shadow;

      .gm-style-iw-d {
        // Main content of loanable details will scroll
        overflow: hidden !important;
        display: flex;
        > div {
          display: flex;
        }
      }

      .gm-style-iw-chr {
        .gm-style-iw-ch {
          padding: 0;
        }

        // close button
        > button {
          position: absolute !important;
          right: 0px !important;
          top: 0 !important;
          z-index: 1050;
          background-color: white !important;
        }
      }
    }
  }
}
</style>
