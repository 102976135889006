<template>
  <paginated-table
    id="admin-padlocks-table"
    endpoint="padlocks"
    :columns="columns"
    :label="$tc('padlock', 2)"
    :show-action-column="false"
    row-link
  />
</template>

<script>
import locales from "@/locales";
import PaginatedTable from "@/components/shared/PaginatedTable.vue";
import { loanableLink } from "@/helpers/adminLinks";
import { Column } from "@/components/shared/PaginatedTableColumns";

export default {
  name: "AdminPadlocks",
  components: {
    PaginatedTable,
  },
  data() {
    return {
      columns: [
        new Column("external_id", "ID", "id"),
        new Column("name", "Nom"),
        new Column("mac_address", "Adresse MAC", "text", { cellClass: "tabular-nums" }),
        new Column("loanable.name", "Objet", "text", {
          urlFct: (item) => loanableLink(item.loanable),
        }),
        new Column("loanable.id", "ID de l'objet", "text", { showByDefault: false }),
      ],
    };
  },
  i18n: {
    messages: {
      en: {
        ...locales.en.padlocks,
        ...locales.en.forms,
      },
      fr: {
        ...locales.fr.padlocks,
        ...locales.fr.forms,
      },
    },
  },
};
</script>

<style lang="scss"></style>
